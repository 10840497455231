// -------------------------------------------------------------------------------------------
// Module: utils
// Author: lpineda
// Date: 19/11/2018
// Desc: Global module that holds generic (not NT6 specific) utilities
//       (see NT6Func.js for specific NT6 functions)
// -------------------------------------------------------------------------------------------

import * as R from 'ramda'
import moment from 'moment'
import stream from 'mithril/stream'
import CT from './CT'
import AppStm from './components/CMain/AppStm'

const doLog = 0                    // Cant use CT.DO_LOG due cycle reference.
let lastEnabledPosition = stream() // Stores last enabled window position

// -------------------------------------------------------------------------------------------
//  -- STYLE utilities --
//   * getStyle => query for a specific style
//   * injectStyle => creates and injects a new style
// -------------------------------------------------------------------------------------------
//Gets, from main styleSheet, a class named 'className'

const getStyle = (className) => {
    if (document.styleSheets) {
        for (let s = 0; s < document.styleSheets.length; s++) {
            let classes = document.styleSheets[s].rules || document.styleSheets[s].cssRules;
            for (let x = 0; x < classes.length; x++) {
                if (classes[x].selectorText === className) {
                    return classes[x].style;
                }
            }
        }
    }
}
//Gets and caches a property of a styleName
let _hGetStyles = {}
const getStyleValue = (styleName, propName) => {
    if (_hGetStyles[styleName + propName]) {
        return _hGetStyles[styleName + propName]
    }
    let st = getStyle(styleName)
    if (st && st[propName]) {
        _hGetStyles[styleName + propName] = st[propName] //caches the value for next queries
        return st[propName]
    }
    //Default values (required for testing, when style files are not present)
    if (styleName === '.tree-dimensions' && propName === 'width') return '30px'
    if (styleName === '.win-bottom-bar-height' && propName === 'height') return '40px'
    if (styleName === '.row-selected' && propName === 'background-color') return '#ffeb72'
    console.log('ERROR: utils.getStyleValue() cannot get ' + styleName + ' ' + propName)
}


//Sets the value for the property "propName" for the style "styleName" only if last value
//set (annotated in _hSetSt) is different compared to "value"
let _hSetSt = {}
const setStyleValue = (styleName, propName, value) => {
    let key = styleName + "_" + propName
    if (!_hSetSt[key] || _hSetSt[key] !== value) {
        _hSetSt[key] = value
        let st = getStyle(styleName)
        if (st) st[propName] = value
    }
}


// If it doesn't exist, creates a new style named "styleName" containing "styleDefinition" and inserts it to the main style sheet
// Example: CT.injectStyle("redBackground", `color:#FFFFFF; background-color: #FF0000`)
// From: https://stackoverflow.com/questions/524696/how-to-create-a-style-tag-with-javascript
let _injectedStyles = {} //hash of styles that are injected via code to the document
const injectStyle = (styleName, styleDefinition) => {
    if (!_injectedStyles[styleName] && !window._global__TEST_MODE__ ) { //If it doesn't exist, created it!
        let css = `.${styleName} {${styleDefinition}}`,
            head = document.head || document.getElementsByTagName('head')[0],
            style = document.createElement('style')
        style.type = 'text/css';
        if (style.styleSheet) {
            // This is required for IE8 and below.
            style.styleSheet.cssText = css
        } else {
            style.appendChild(document.createTextNode(css))
        }
        head.appendChild(style)
        _injectedStyles[styleName] = true //mark as created!
    }
}

/*
         *   Checks if a color is light or dark
         */
const lightOrDark = (color) => {
    if (!color) return 'light'
    let r, g, b, hsp

    // HEX or RGB?
    if (color.match(/^rgb/)) {
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)
        r = color[1]
        g = color[2]
        b = color[3]
    } else {
        // Convert it to HEX
        color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'))
        r = color >> 16
        g = color >> 8 & 255
        b = color & 255
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
    )

    // Using the HSP value, determine whether the color is light or dark
    return (hsp > 127.5) ? 'light' : 'dark'
}


// Converts hash or object with arbitrary keys to an array
// From:  https://medium.com/chrisburgin/javascript-converting-an-object-to-an-array-94b030a1604c
const fromObjecToArray = (obj) => Object.keys(obj).map(i => obj[i])
// Extracts a field from an object, even if it does a reference into an array
const extractField = (data, f) => {
    if (f.endsWith(']')) {
        let i = f.indexOf('[')
        let pos = data[f.substring(0, i)]
        if (pos === undefined) return
        let s = parseInt(f.substring(i + 1, f.length - 1))
        return pos[s]
    } else return data[f]
}

const swapArrayElements = function (arr, indexA, indexB) {
    if (arr && indexA >= 0 && indexA < arr.length && indexB >= 0 && indexB < arr.length) {
        let temp = arr[indexA]
        arr[indexA] = arr[indexB]
        arr[indexB] = temp
    }
}
const arrayContains = (ar, value) => ar && R.find((item) => item === value)(ar) !== undefined

// Inserts a field into an object, even if it does a reference into an array
const insertField = (data, f, val) => {
    if (f.endsWith(']')) {
        let i = f.indexOf('[')
        if (!data[f.substring(0, i)]) data[f.substring(0, i)] = []
        data[f.substring(0, i)][parseInt(f.substring(i + 1, f.length - 1))] = val
    } else return data[f] = val
}

const getInnerData = (data, path) => {
    if (data === undefined || path === undefined) return
    if (path.indexOf('.') > 0) {
        path.split('.').forEach(x => {
            if (data !== undefined) data = extractField(data, x)
        })
    } else data = extractField(data, path)
    return data
}

const setInnerData = (data, path, value) => {
    if (data === undefined || path === undefined) return
    if (path.indexOf('.') > 0) {
        let ss = path.split('.')
        for (let i = 0; i < ss.length - 1; i++) {
            if (extractField(data, ss[i]) === undefined) insertField(data, ss[i], {})
            data = extractField(data, ss[i])
        }
        insertField(data, ss[ss.length - 1], value)
    } else insertField(data, path, value)
}


/*
 *  Given a field definition, returns if field has its data descriptor nested or not.
 *
 *  		<formItem>
 *			    <field id="ID">
 *				    <widget ... dataObject="nested.property"
 *
 */
const isStructuredField = (dataObjName, fieldDef) => {

    let isStructured, structuredDataObject, containsBrackets

    if (Array.isArray(fieldDef)) {
        for (let field of fieldDef) {
            if (field['@attributes'].id === dataObjName) {  // Search item
                if (field.widget && field.widget['@attributes']) {
                    structuredDataObject = extractBoolean(field.widget['@attributes']['structuredDataObject'])
                    containsBrackets = dataObjName ? dataObjName.indexOf('[') >= 0 || dataObjName.indexOf('[') >= 0 : false
                    isStructured = (structuredDataObject || containsBrackets)
                    break
                }
            }
        }
    } else {
        if (fieldDef['@attributes'].id === dataObjName) { // Search item
            if (fieldDef.widget && fieldDef.widget['@attributes']) {
                structuredDataObject = extractBoolean(fieldDef.widget['@attributes']['structuredDataObject'])
                containsBrackets = dataObjName ? dataObjName.indexOf('[') >= 0 || dataObjName.indexOf('[') >= 0 : false
                isStructured = (structuredDataObject || containsBrackets)
            }
        }
    }

    return isStructured

}

/* Removes <b> and <i> from text, and replaces <br/> or <br /> with \n, to
 * avoid pseudo-html textes
 */
const preventHTML = (s) => {
    if (!s) return s
    if (typeof s === 'function') s = s()
    if (!isNaN(s)) return s
    if (typeof(s) === 'string') return s.replace(/<\s*b\s*>/g, '')
        .replace(/<\s*i\s*>/g, '')  //(NOTE: \s* to allow n blanks)
        .replace(/<\s*\/b\s*>/g, '')
        .replace(/<\s*\/i\s*>/g, '')
        .replace(/<\s*br\s*>/g, '\n')
        .replace(/<\s*br\s*\/>/g, '\n')
    console.log(`ERROR: preventHTML : received ${s} instead of a string`)
}


/*
 *  Retrieves a random integer between a explicit range
 */
const randomInteger = (min = 0, max = Number.MAX_SAFE_INTEGER) => (Math.floor(Math.random() * (max - min)) + min)

const randomColor = () => '#' + Math.floor(Math.random() * 16777215).toString(16)

/*
 *  UUID generator. RFC4122 version 4 compliant solution.
 */
const uuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        let r = Math.random() * 16 | 0
        let v = c === 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
    })
}

/*
 *  Auto ID generator (consider using it instead of uuid)
 */
let _autoId = 0
const autoId = (prefix) => {
    let pref = prefix || ''
    if (window._global__TEST_MODE__) return prefix // '' //when testing, always return a fixed value
    return `${pref}_${++_autoId}`
}


// -------------------------------------------------------------------------------------------
// Color utilities
// -------------------------------------------------------------------------------------------
function componentToHex(c) {
    let hex = c.toString(16)
    return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
    return componentToHex(r) + componentToHex(g) + componentToHex(b);
}

// ==> hexToRgb("#0033ff").g  == > "51";
function hexToRgb(hex) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result ? parseInt(result[1], 16) << 16 | parseInt(result[2], 16) << 8 | parseInt(result[3], 16) : null
}

// Given an hex color, ex: #CCCCCC, returns a more obscure version of that color ex: xx: #999999 (if brite <0) or a brighter one (brite >0),
// depending on  "brite" value [-100,100] (coming from Flex - adjustBrightness2)
function adjustBrightness(hexColor, brite) {
    let rgb = hexToRgb(hexColor), r, g, b
    if (brite === 0) {
        return rgb;
    }
    if (brite < 0) {
        brite = (100 + brite) / 100;
        r = Math.floor(((rgb >> 16) & 0xFF) * brite);
        g = Math.floor(((rgb >> 8) & 0xFF) * brite);
        b = Math.floor((rgb & 0xFF) * brite);
    } else {// bright > 0
        brite /= 100;
        r = Math.floor(((rgb >> 16) & 0xFF));
        g = Math.floor(((rgb >> 8) & 0xFF));
        b = Math.floor((rgb & 0xFF));

        r += Math.floor((0xFF - r) * brite);
        g += Math.floor((0xFF - g) * brite);
        b += Math.floor((0xFF - b) * brite);

        r = Math.min(r, 255);
        g = Math.min(g, 255);
        b = Math.min(b, 255);
    }
    return rgbToHex(r, g, b);
}


/*
 *  It changes the opacity (percentage) of a given color (hex format)
 */
const shadeColor = (color, percent) => {
    let f = parseInt(color.slice(1), 16)
    let t = percent < 0 ? 0 : 255, p = percent < 0 ? percent * -1 : percent
    let R = f >> 16,
        G = f >> 8 & 0x00FF,
        B = f & 0x0000FF;
    return "#" + (0x1000000 +
        (Math.round((t - R) * p) + R) * 0x10000 +
        (Math.round((t - G) * p) + G) * 0x100 +
        (Math.round((t - B) * p) + B)).toString(16).slice(1)
}


// -------------------------------------------------------------------------------------------
// dom elements methods
// -------------------------------------------------------------------------------------------
const addClassName = (el, className) => {
    if (el) {
        if (el.classList) el.classList.add(className);
        else el.className += ' ' + className;
    }
}

const removeClassName = (el, className) => {
    if (el) {
        if (el.classList) el.classList.remove(className)
        else el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
    }
}

/*
 *   Returns the relative clicked position on a specific canvas.
 */
const getRelativeCoordinates = (event, element) => {
    // Absolute position of click event.
    const position = {
        x: event.pageX,
        y: event.pageY
    }

    let box = element.getBoundingClientRect()
    let body = document.body
    let docElem = document.documentElement

    let clientTop = docElem.clientTop || body.clientTop || 0
    let clientLeft = docElem.clientLeft || body.clientLeft || 0

    let scrollTop = window.pageYOffset || docElem.scrollTop || body.scrollTop
    let scrollLeft = window.pageXOffset || docElem.scrollLeft || body.scrollLeft

    let top = Math.round(box.top + scrollTop - clientTop)
    let left = Math.round(box.left + scrollLeft - clientLeft)

    // Relative inner coordinates (DOM canvas {x,y})
    return {
        x: position.x - left,
        y: position.y - top,
    }
}


//Cross-compatible: setAtt & prop must be set in this order
//https://stackoverflow.com/questions/10118172/setting-div-width-and-height-in-javascript
// WARNING: style property is modified => forced "display:inline-block"
const setDomWidthAndStyle = (domElement, width) => {
    domElement.setAttribute("style","width:" + width + "px")
    domElement.style.width =  width + "px"
}


//Given an array of numbers, where evey number is an offset from settings first date (ex 1/1/2004),
//returns an array of moment objects representing the date for each number
const fromDayOffsetToMoment = (daysArr) => {
    let firstYear = 2004  //TODO: use real first number from settings!
    return R.map((offsetDay) => moment().year(firstYear).month(0).date(1).add(offsetDay, 'days'))(daysArr)
}

//Given an array of moments, returns an array of number representing an offset from settings first date (ex 1/1/2004),
const fromMomentToDayOffset = (momentArr) => {
    let firstYear = 2004  //TODO: use real first number from settings!
    let mRef = moment().year(firstYear).month(0).date(1).hour(0).minute(0).seconds(0).millisecond(0) //Important: set milliseconds to properly use "moment.diff()" later!
    return R.map((mDay) => mDay.diff(mRef, 'days'))(momentArr)
}

const intToHourString = (data) => {
    let sign = ''
    if (data < 0) {
        sign = '-'
        data = -data
    }
    let hours = Math.floor(data / 60)
    hours = hours.toString().padStart(2, '0')
    let minutes = data % 60;
    minutes = minutes.toString().padStart(2, '0')
    return sign + hours + ':' + minutes
}

const binaryToBase64 = (b) => {
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    let encoded = []
    let c = 0
    while (c < b.length) {
        let b0 = b[c++].Byte
        let b1 = c >= b.length ? undefined : b[c++].Byte
        let b2 = c >= b.length ? undefined : b[c++].Byte
        let i0 = b0 >> 2;
        let i1 = ((b0 & 3) << 4) | (b1 >> 4);
        let i2 = ((b1 & 15) << 2) | (b2 >> 6);
        let i3 = b2 & 63;

        if (isNaN(i2)) {
            i2 = i3 = 64;
        } else if (isNaN(i3)) {
            i3 = 64;
        }
        encoded.push(chars.charAt(i0))
        encoded.push(chars.charAt(i1))
        encoded.push(chars.charAt(i2))
        encoded.push(chars.charAt(i3))
    }
    return encoded.join('')
}


// ---------------------------------------------------------------------------------------------------------------------
//
//  NT6 Working shift & Intervals methods
//
// ---------------------------------------------------------------------------------------------------------------------


/*
 *  NT's working shift hours translation to "±24hh:mi" date mask.
 *  Helper for shift ruler creation.
 */
const humanReadableHour = (integerValue) => {
    let offset = integerValue - 24
    if (offset < 0) {
        return `-${Math.abs(offset + 24)}:00`
    } else if (offset === 0) {
        return `00:00`
    } else {
        if (offset < 24) {
            return `${offset}:00`
        } else if (offset === 24) {
            return `24:00`
        } else {
            return `+${offset - 24}:00`
        }
    }
}


/*
 *   Given a string date, return another string date with YYYY-MM-DD format mask
 */
const reportDateFormat = (strDate) => {
    let date = ''
    if (strDate) date = moment(strDate).format('YYYY-MM-DD')
    return date
}

//Returns true if mTheDate is 1 day before mRef. Ex: (2019-01-02, 2019-01-03) => true
const isThePreviousDate = (mTheDate, mRef) => {
    let prev = moment(mRef).subtract(1, 'days')
    return prev.isSame(mTheDate, 'days')
}

//Returns true if mTheDate is 1 day after mRef. Ex: (2019-01-12, 2019-01-11) => true
const isTheNextDate = (mTheDate, mRef) => {
    let next = moment(mRef).add(1, 'days')
    return next.isSame(mTheDate, 'days')
}

const ascending = (a, b) => (a.start - b.start)

/*
 *  Checks equality of two sets of intervals
 */
const areEqual = (a, b) => {

    // if (doLog) console.log('>> areEqual source:', JSON.stringify(a), ' <> ', JSON.stringify(b))

    if (a == null || b == null) return false
    if (a.length !== b.length) return false

    // The arrays checked here, are supposed previously ordered. So no need to clone && sort.
    for (let i = 0; i < a.length; ++i) {
        if (a[i].start !== b[i].start || a[i].end !== b[i].end) {
            if (doLog) console.log('>> areEqual? NOT.', i, a[i], b[i])
            return false
        }
    }
    return true
}

// -------------------------------------------------------------------------------------------
// Intervals utilities
// -------------------------------------------------------------------------------------------

/*
 *   Given a array of ranges, return the same data without overlapping stripes by merging stripes.
 */
const merge = (array) => {
    let stack = []
    let top = null
    let copy = array.slice()
    if (copy.length <= 1) return copy

    if (doLog) console.log('>> Merge source: ', array)

    // Sort the intervals based on their start values
    let intervals = copy.sort(ascending)

    // Push the 1st interval into the stack
    stack.push(intervals[0])

    // Start from the next interval and merge if needed
    for (let i = 1; i < intervals.length; i++) {
        top = stack[stack.length - 1]

        // If the current interval doesn't overlap with the stack top element, push it to the stack
        // Otherwise update the end value of the top element if end of current interval is higher
        if (top.end < intervals[i].start) {
            stack.push(Object.assign({}, intervals[i]))
        } else if (top.end < intervals[i].end) {
            let freshTop = {
                start: top.start,
                end: intervals[i].end
            }
            stack.pop()
            stack.push(freshTop)
        }
    }

    if (doLog) console.log('>> Merge result: ', JSON.stringify(stack))

    return stack
}


/*
 *   Returns a new array of ranges common to both input arrays.
 */
const intersect = (a, b) => {
    let intersection = []

    if (doLog) console.log('>> intersection source:', JSON.stringify(a), ' <--> ', JSON.stringify(b))

    a.forEach((rangeA) => {
        b.forEach((rangeB) => {
            // If there is intersection
            if (!(rangeB.start > rangeA.end || rangeA.start > rangeB.end)) {
                let intervalStart = R.max(rangeA.start, rangeB.start)
                let intervalEnd = R.min(rangeA.end, rangeB.end)

                // Avoid one minute stripes...
                if (intervalStart !== intervalEnd) intersection.push({start: intervalStart, end: intervalEnd})
            }
        })
    })

    if (doLog) console.log('>> intersection', JSON.stringify(intersection))

    return intersection
}


/*
 *   Returns a new array which ranges are equal to the first input array without the overlapping ranges of second array.
 *   In other words, the difference of ranges.
 *
 *   Mental debug:
 *
 *         --------------------------       TARGET (A)
 *       __|____                    |        CASE0
 *         |_____                   |        CASE1
 *       __|________________________|__      CASE2
 *         |________________________|__      CASE3
 *         |        ________        |        CASE4
 *         |                 _______|        CASE5
 *         |                 _______|__      CASE6
 *
 */
const difference = (a, b) => {

    if (doLog) console.log('>> difference source: ', JSON.stringify(a), JSON.stringify(b))

    // Clone arrays
    let targetArray = a.slice(0)
    let subtractArray = b.slice(0)

    // Nothing to subtract
    if (subtractArray.length < 1) return targetArray

    let stack = []
    let top = null

    // Initial sort, for security
    targetArray = targetArray.sort(ascending)
    subtractArray = subtractArray.sort(ascending)

    for (let i = 0; i < targetArray.length; i++) {
        stack.push(targetArray[i])
        top = stack[stack.length - 1]


        for (let j = 0; j < subtractArray.length; j++) {
            let moddedTop = {}

            if (subtractArray[j].start > top.end || top.start > subtractArray[j].end) {
                // Not overlapping. Nothing to do...
                if (doLog) console.log('>> difference CASE: notOverlapping.')
            } else {
                if (top.start <= subtractArray[j].start) {
                    if (subtractArray[j].start === top.start) {
                        if (subtractArray[j].end < top.end) {
                            if (doLog) console.log('>> difference CASE: I.')
                            moddedTop.start = subtractArray[j].end
                            moddedTop.end = top.end
                            stack.pop()
                            stack.push(moddedTop)
                            top = stack[stack.length - 1]
                        } else {
                            if (doLog) console.log('>> difference CASE: III')
                            stack.pop()
                            top = stack.length > 0 ? stack[stack.length - 1] : {start: 0, end: 0}
                        }
                    } else {

                        if (subtractArray[j].end <= top.end) {
                            if (doLog) console.log('>> difference CASE: IV.')
                            // Need to push two stripes, both edges.
                            moddedTop.start = top.start
                            moddedTop.end = subtractArray[j].start
                            stack.pop()
                            stack.push(moddedTop)

                            // Final one
                            if (subtractArray[j].end < top.end) {
                                // Avoid one minute stripe....
                                stack.push({start: subtractArray[j].end, end: top.end})
                            }
                            top = stack[stack.length - 1]
                        } else {
                            if (doLog) console.log('>> difference CASE: V & VI.')
                            moddedTop.start = top.start
                            moddedTop.end = subtractArray[j].start
                            stack.pop()
                            stack.push(moddedTop)
                            top = stack[stack.length - 1]
                        }

                    }

                } else {
                    if (subtractArray[j].end < top.end) {
                        if (doLog) console.log('>> difference CASE: ZERO.')
                        moddedTop.start = subtractArray[j].end
                        moddedTop.end = top.end
                        stack.pop()
                        stack.push(moddedTop)
                        top = stack[stack.length - 1]

                    } else {
                        if (doLog) console.log('>> difference CASE: II')
                        if (subtractArray[j].end >= top.end) {
                            // DELETE IT ALL
                            stack.pop()
                            top = stack.length > 0 ? stack[stack.length - 1] : {start: 0, end: 0}
                        } else {
                            moddedTop.start = subtractArray[j].end
                            moddedTop.end = top.end
                            stack.pop()
                            stack.push(moddedTop)
                            top = stack[stack.length - 1]
                        }
                    }
                }
            }
        }
    }

    if (doLog) console.log('>>> difference result: ', JSON.stringify(stack))

    return stack
}

const createKey = (validity) => {
    let key = ''
    if (Array.isArray(validity) && validity.length > 0) {
        let hash = validity.reduce((accum, stripe) => {
            if (stripe) accum += `${stripe.start}-${stripe.end}-`
            return accum
        }, '')
        key += validity.length + '-' + hash
    } else {
        key += '0-0'
    }
    return key
}

// -------------------------------------------------------------------------------------------
// Regular expressions
// -------------------------------------------------------------------------------------------


const isInteger = (n) => {
    let result = true
    if (n) result = /^\d*$/.test(n)
    console.log('isInteger', n, result)
    return result
}

const isEmail = (foo) => {  // https://emailregex.com/
    let result = true
    if (foo) result =  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(foo)
    return result
}

//Returns true if s contains at least 1 char [a-z] or [A-Z].
// "Name1" => true
// "<="   => false
// "12"   => false
const containsAlphaChar = (s) => /(?=.*[a-z])(?=.*[A-Z])/.test(s)

// -------------------------------------------------------------------------------------------
// Cursor
// -------------------------------------------------------------------------------------------

const setCursor = (cursorStyle) => {
    document.body.style.cursor = cursorStyle
}

const restoreCursor = () => {
    document.body.style.cursor = 'auto'
}

// Verifies that 2 objects of any kind (can be arrays) are of same type
// and contains the same keys and same values for every key.
// If some of the params or values for some keys are arrays, iterate them
// and checks for deepCompare() on every item of both arrays
// If some value is a date, ex: "2004-01-01T00:00:00+01:00" it converts both
// values to moment and compares for same day (without comparing hh/mm/ss)
// From: https://stackoverflow.com/questions/38400594/javascript-deep-comparison
const deepCompare = (a, b) => {

    if ((CT.isArray(a) && a.length === 0 && !b) || (CT.isArray(b) && b.length === 0 && !a)) {
        return true //special case: empty array against null or undefined value
    } else if( (CT.isArray(a) && a != null) && (CT.isArray(b) && b != null) ) {
        if (a.length !== b.length) {
            return false
        }
        for (let i = 0; i < a.length; i++) {
            if (!deepCompare(a[i], b[i])) {
                return false
            }
        }
        return true
    } else if( (typeof a == 'object' && a != null) && (typeof b == 'object' && b != null) ) {
        let count = [0,0]
        for( let key in a) count[0]++
        for( let key in b) count[1]++
        if( count[0] - count[1] !== 0) {
            return false
        }
        for( let key in a) {
            if(!(key in b) || !deepCompare(a[key], b[key])) {return false;}
        }
        for( let key in b)
        {
            if(!(key in a) || !deepCompare(b[key],a[key])) {return false;}
        }
        return true;
    }


    //Avoid creating moments for strings that are not candidates (short or larger ones)
    const strCanBeMoment = (str) => str && str.length > 20 && str.length < 50

    //Sometimes the same date (ex inside a validity period) comes in slightly different strings:
    // Ex:      {start: "2004-01-01T00:00:00+01:00",
    //          {start: "2004-01-01T00:00:00.0000000+01:00",
    //if (typeof a === "string" && typeof b === "string") {
    if (typeof a === "string" && typeof b === "string" && strCanBeMoment(a) && strCanBeMoment(b) && Date.parse(a) && Date.parse(b)) {
        let m1 = moment(a)
        let m2 = moment(b)
        if (m1.isValid() && m2.isValid()) {
            return m1.diff(m2, 'days') === 0  //comparing granularity of days ( HH:mm:ss are truncated!!!!)
        }
    }
    return a === b

}

//Given a container name, ex "Persona", "Ciclo", returns the corresponding container 'id'
// (the same id used in container definition)
const getContainerId = (contName) => {
    if (contName) return CT.CONTAINER_ID[contName.toUpperCase()]
    return undefined
}


// "candidate" can be an object or a number. If it is an object tries to get the 'id'
//If it is a number or a string, returns the original value
const extractId = (candidate) => {
    if (!isNaN(candidate) || typeof candidate === 'string') return candidate
    if (typeof candidate === 'object' && candidate['id'] !== undefined) return candidate['id']
    CT.error("CWgtGrid: cannot extract an id from ", candidate)
    return undefined
}

//Returns an array of numbers. Each one consist of 'id' property for every object in "arr"
const extractIds = (arr) => R.map(extractId)(arr)

//Merges "origFilterExpStr" with the ids contained in arIds returning an expression
const extractIdsAsExpression = (origFilterExpStr, arrIds) => {
    if (arrIds.length === 0) return origFilterExpStr
    let prefix = ''
    if (origFilterExpStr && origFilterExpStr.length > 0) {
        prefix = origFilterExpStr + " && "
    }
    if (!isNaN(arrIds) || typeof arrIds === 'string') return prefix + "this.id = " + arrIds
    else {
        let sOut = prefix + " ("
        if (CT.isArray(arrIds)) {
            for (let i = 0; i < arrIds.length; i++) {
                let id = arrIds[i]
                if (i > 0) sOut += ' || '
                sOut += " this.id = " + extractId(id)
            }
        }
        sOut += ")"
        //CT.error("Returning : " + sOut)
        return sOut
    }
}

// Extracts from the field 'baValidElemsField' of dataObj an array of id's and converts it to an object
// compatible with combo 'extraParams' property that will be used to filter these id's
const extractIdsFromDataObject = (dataObj, baValidElemsField) => {
    if (baValidElemsField && dataObj[baValidElemsField]) {
        // LLP: 10/2/2021 Alternative to using filterExp()
        let arIds = dataObj[baValidElemsField]

        if (arIds && Array.isArray(arIds) && arIds.length > 0) {
            return  {ids: arIds.join(',')}
        }
        // LLP: OLD (using 'filterExp') it works but with problems, as ends with a very big string (this.id=1 || this.id = 2 ....)
        //filterExp = utils.extractIdsAsExpression('', c.cfContext.cformData.dataObj[baValidElemsField])
    }
    return undefined
}

//Removes duplicates elements in array
const removeDuplicates = (arr) => {
    let seen = {};
    let ret_arr = [];
    for (let i = 0; i < arr.length; i++) {
        if (!(arr[i] in seen)) {
            ret_arr.push(arr[i]);
            seen[arr[i]] = true;
        }
    }
    return ret_arr;
}

// Given arIds=[11,22] returns a filter expression compatible with "filterExp" => "this.id = 11 || this.id = 22"
const fromIdsToFilterString = (arIds) => {
    let sExpr = ''
    for (let i = 0; i < arIds.length; i++) {
        if (i > 0) {
            sExpr += " || "
        }
        sExpr += " this.id=" + arIds[i]
    }
    return sExpr
}


const extractBoolean = (candidate) => {
    if (typeof candidate === "boolean") return candidate
    if (typeof candidate === 'string') return candidate.toLowerCase() === 'true' || candidate === '1'
    return false
}


// EXEC_ACTION originated in a menu option has a case (a report bound to main menu)
// where the 'reportId' is set inside "Default" property of "params"
// This function searches for the "Default" property and returns the value inside
// an array that will become the "elements" property of "exec" query
const extractItemsFromParams = (params) => {
    if (params) {
        if (CT.isArray(params)) {
            if (params.length === 1) {
                let firstParam = params[0]
                if (firstParam.Default) {
                    if (typeof firstParam.Default === "string") {
                        return [parseInt(firstParam.Default, 10)]
                    }
                    if (typeof firstParam.Default === "number") { //case found opening a plani from notifications list
                        return [firstParam.Default]
                    }
                }
            }
        }
    }
    return [] //If no items are selected, an empty array signals "new element"
}


//Ensures supplied 's' will have "%" suffix (if not yet present)
const ensurePercent = (s) => {
    if (s && s.length > 0) {
        let s1 = s.trim()
        return s1.charAt(s1.length - 1) === "%" ? s1 : s1 + "%"
    }
    return s
}

//Given a string that can contain some var names between braces, returns an array
//of var names, ex: "aaaa{com} nnn  m {ent} 2 un {alt} xxx" => ["com", "ent", "alt"]
const extractVarJNamesInBraces = (testStr) => {
    let re = RegExp(/{.*?}/g)
    let out = [], result
    while ((result = re.exec(testStr)) !== null) {
        out.push(result[0].substr(1, result[0].length - 2).trim())
    }
    return out
}

//Giver an array of variable names (ex: the one obtained in "extractVarJNamesInBraces",
//a dataObj that can contain some properties and a string, returns a new string
//with every occurrence of {varName} replaced by "dataObj[varName]" (if defined)
const replaceVarsInExpression = (arVarNames, dataObj, str) => {
    let sOut = str
    R.forEach((vn) => {
        let value = dataObj[vn]
        if (value !== undefined) {
            let vNameToReplace = '{' + vn + '}'
            let re = RegExp(vNameToReplace, 'g')
            sOut = sOut.replace(re, value)
        }
    })(arVarNames)
    return sOut
}

//From: https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
const getHashFromString = (str) => {
    let hash = 0, i, chr
    if (!str || str.length === 0) return hash
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i)
        hash = ((hash << 5) - hash) + chr
        hash |= 0 // Convert to 32bit integer
    }
    return hash
}


// -------------------------------------------------------------------------------------------
// Window related functions
// -------------------------------------------------------------------------------------------

/*  Absolute measurement of win component parts without its content.
 *
 *  Caution: if the DOM render is changed by new elements, classes or style modifications,
 *  this measures may become wrong and they will need a revision.
 */
const WIN = {
    BORDER_TOP: 3,
    WIN_TITTLE: 25,
    FORM_HEADER: 40,
    CONTENT_TOP: 10,
    BORDER_BOTTOM: 3
}


/*
 *  Returns window absolute positioning coordinates.
 *
 *  Window positioning policy:
 *     - Last enabled window leads to new one! Applies offset at last window {x, y} if fits into screen.
 *     - If window does not fit, will be positioned centered
 *
 *  @param {Object} win - {top, left}
 *  @returns {Object} - {top, left}
 */
const getPosition = (win) => {
    const minX = 15                   // first window settings
    const minY = CT.H_MAIN_MENU + 5
    const horizontalOffset = 25
    const verticalOffset = 25         // next windows offset


    let appSize = AppStm.getAppArea()
    let position
    let lastPosition = lastEnabledPosition()
    if (lastPosition) {
        position = {
            top: lastPosition.top + verticalOffset,
            left: lastPosition.left + horizontalOffset
        }
    } else {
        // Default first window
        position = {
            top: minY,
            left: minX
        }
    }

    if (doLog || CT.IS_DEBUG()) {
        console.log('WinMgr.getPosition..........................................')
        console.log('App viewport  : ', appSize)
        if (lastPosition) console.log('LastEnabled :', {top: lastPosition.top, left: lastPosition.left})
        console.log('Final {top, left}:', {top: position.top, left: position.left})
    }


    // Check if windows dimensions fits into viewport on the new positioning.

    let overflowsX = ((appSize.width - (win.width + position.left)) <= 0)
    let overflowsY = ((appSize.height - CT.H_MAIN_MENU - (win.height + position.top)) <= 0)

    if (overflowsX) position.left = Math.floor((appSize.width - win.width) / 10)
    if (overflowsY) position.top = Math.floor((appSize.height - win.height) / 10) + CT.H_MAIN_MENU

    // Prevent unreachable window
    if (position.top < CT.H_MAIN_MENU) position.top = CT.H_MAIN_MENU


    if (doLog || CT.IS_DEBUG()) {
        console.log('Overflows {top, left}:', {top: overflowsY, left: overflowsX})
        console.log('Final Overflow {top, left}:', {top: position.top, left: position.left})
        console.log('.......................................................')
    }

    lastEnabledPosition(position)

    return position
}


const centerPosition = (win) => {
    let appArea = AppStm.getAppArea()
    win.top =  (win.height < appArea.height) ? Math.floor((appArea.height - win.height) / 2) : 0
    win.left = (win.width < appArea.width) ? Math.floor((appArea.width - win.width) / 2) : 0
}


/*
 *   To ensure window definition has the properties required. Must check overflow of the main view port.
 */


/*
 *  Returns window dimension.
 *  Checks app viewport to find out best fit option, but if exists custom size properties on window definition,
 *  it will respect it if fits into viewport.
 *
 *  @param {Object} win - {width, height, minWidth, minHeight}
 *  @returns {Object} - {width, height}
 */
const getSize = (win) => {
    let appSize = AppStm.getAppArea()
    let width, height, mediaWidth, mediaHeight, defaultFits, minimalFits

    // Width and height responsive to app viewport:

    if (appSize.width <= 512) {
        mediaWidth = Math.floor(appSize.width - (.025 * appSize.width))
    } else if (appSize.width > 512 && appSize.width <= 768) {
        mediaWidth = 512
    } else if (appSize.width > 768 && appSize.width <= 1024) {
        mediaWidth = 650
    } else if (appSize.width > 1024 && appSize.width <= 1280) {
        mediaWidth = 820
    } else if (appSize.width > 1280 && appSize.width <= 1600) {
        mediaWidth = 1000
    } else {
        mediaWidth = Math.floor(appSize.width - (.35 * appSize.width))
    }

    if (appSize.height <= 480) {
        mediaHeight = Math.floor(appSize.height - CT.H_MAIN_MENU) // 350
    } else if (appSize.height > 480 && appSize.height <= 768) {
        mediaHeight = 512
    } else if (appSize.height > 768 && appSize.height <= 1024) {
        mediaHeight = 650
    } else {
        mediaHeight = 768
    }

    // When window definition has its own size parameters, respect them if its possible.

    width = mediaWidth
    defaultFits = (win.width && (appSize.width >= win.width))
    minimalFits = (win.minWidth > mediaWidth) && (appSize.width >= win.minWidth)

    if (defaultFits) {
        width = win.width
    } else {
        if (minimalFits) width = win.minWidth
    }

    height = mediaHeight
    defaultFits = (win.height && ((appSize.height - CT.H_MAIN_MENU) >= win.height))
    minimalFits = ((win.minHeight > mediaHeight) && ((appSize.height - CT.H_MAIN_MENU) >= win.minHeight))

    if (defaultFits) {
        height = win.height
    } else {
        if (minimalFits) height = win.minHeight
    }

    if (doLog || CT.IS_DEBUG()) {
        //      console.log(`CWin.width : ${width}px - QMedia: ${mediaWidth} Default: ${win.width} Min: ${win.minWidth}`)
        //      console.log(`CWin.height: ${height}px - QMedia: ${mediaHeight} Default: ${win.height} Min: ${win.minHeight}`)
    }

    return {width, height}
}


/*
 *  Given size data about window and its own content, returns the proper height measure for the window.
 *
 *  @param {Object} - {top, left, height, width, contentFull, contentShown}
 *  @returns {Number} - Height
 */
const fitContent = (win) => {
    let properHeight
    let appSize = AppStm.getAppArea()
    let bottomSpace = Math.floor(appSize.height - (win.top + win.height))
    let maxHeight = Math.floor(win.height + bottomSpace)

    if (win.contentFull > win.height) {
        properHeight = (win.contentFull >= maxHeight) ? maxHeight : win.contentFull
    } else if (win.contentFull < win.height) {
        properHeight = win.contentFull
    } else {
        properHeight = win.contentShown
    }

    return properHeight
}

/*
 *  Given a content height, returns the total height as if was shown in a window component.
 */
const wrappedHeight = (h) => (WIN.BORDER_TOP + WIN.WIN_TITTLE + WIN.FORM_HEADER + WIN.CONTENT_TOP + h + WIN.BORDER_BOTTOM)

const getWrapperOffset = (el) => {
    let yPos = 0
    while (el) {
        if (el.className.indexOf('win-slot-cform') >= 0) break
        yPos += el.offsetTop
        el = el.offsetParent
    }
    return  yPos
}

const scrollTo = (wrapper, element) => {
    if (wrapper && element) {
        setTimeout(() => {
            let offset = getWrapperOffset(element)
            wrapper.scrollTo({top: offset, left: 0, behavior: 'smooth'})
        }, 150)
    }
}

//////////////// Types

const isFunction = (f) => (typeof(f) === 'function') // !!(f && f.constructor && f.call && f.apply) //
const isArray    = (a) => Array.isArray(a)
const isObject   = (o) => (o === Object(o))
const isBoolean  = (b) => (typeof b === 'boolean')
const isNumber   = (n) => (!isNaN(parseFloat(n)) && !isNaN(n - 0))// (typeof n === 'number') // New method also checks numbers as strings...
const isString   = (s) => (typeof s === 'string')

const whichTypeIs = (foo) => {
    return isFunction(foo) ? 'function' :
        isArray(foo) ? 'array' :
            isObject(foo) ? 'object' :
                isBoolean(foo) ? 'boolean' :
                    isNumber(foo) ? 'number' :
                        isString(foo) ? 'string' : 'undefined'
}

const isEmpty = (foo) => {
    switch (whichTypeIs(foo)) {
        case 'array':                                 // iterables types
        case 'string':
            return !(foo && foo.length > 0)
        case 'object':
            // return !(Object.keys(foo).length > 0)

            let existsAtLeastOneFullFilledProperty = false
            for (const key in foo) {
                if (!isEmpty(foo[key])) {
                    existsAtLeastOneFullFilledProperty = true
                    break
                }
            }
            return !existsAtLeastOneFullFilledProperty
        case 'function':                              // detects type due it is not empty
        case 'number':
        case 'boolean':
            return false
        default:
            return true
    }
}

const serializeArray = (array, property, extraData, undefinedAsNull = false) => {
    return array.reduce((accum, item, index, vector) => {
        let separator = ((vector.length - 1) === index) ? '' : ','
        // accum += (property) ? item[property] : item

        let value = (property) ? item[property] : item
        if (value === undefined && undefinedAsNull) {
            value = '' // Avoid undefined word as value
        }

        accum += value

        // Add @YYYY-MM-DD to required fields
        if (extraData && extraData.historicData && extraData.historicData.existColumns) {
            if (extraData.historicData.fields && extraData.historicData.fields.includes(value)) {
                accum += `@${extraData.historicData.effectiveDate}`
            }
        }

        accum += separator
        return accum
    }, '')
}

/*
     *  Used on report cForm to extract data form
     */
const reportFormData = (finalDataObj, extraData) => {

    let formData = new FormData()

    let skipProperty
    //
    // "tipoListado","caption","groupby0","groupby1","groupby2","subtotal","legend","startDate","endDate","startTime",
    // "endTime","time1","time2","centesimal","formatAsMinutes","noDetails","PageBreakAtEnd0","PageBreakAtEnd1",
    // "PageBreakAtEnd2","cumulative","orderby","advFilter","onlySuccessClockings","size","orientacion","format",
    // "hideHeader","ticketIdZone","ticketNameZone","filterfields.type","filter","baZonas.selected",
    // "elements.selected", "baReader.selected",
    // "fields.coldata",
    // "fields.colname",
    // "fields.percentwidth",
    // "fields.level",
    // "fields.percent",
    // "fields.result1",
    // "fields.row","containerName","elements","elements","baZonas","baZonas","baReader",
    // "baReader","Departments","nivel","id","ids"

    let skippedProperties = [
        "_c_", "name", "modified", "rev", "created", "ownerName", "reportTypeDesc", "global", "portal",
        "useTemplate", "filePath", "menu", "defaultParams", "cont", "contId",
        "singleElement", "multiElement", "formatSource", "sizeSource", "fieldSource",
        "nodesSource", "multiName", "groupbySource", "orientacionSource", "orderbySource", "newFilter",
        "paramDateIni", "paramDateEnd", "subtotalSource", "periodicidad", "baDestUsers",
        "menuParent", "tipoListadoSource", "baContainers", "preview", "newField"
    ]

    // "Departments" "nivel"

    // "id", "containerName",

    Object.keys(finalDataObj).forEach((key) => {

        skipProperty = false  // if a property has custom treatment, this flag will avoid default type serialization

        if (!skippedProperties.includes(key)) { // avoid send unnecessary data

            let value = finalDataObj[key]
            let type = typeof value

            // if (key === 'id') {
            //     formData.append('reportId', value)
            // }

            if (type !== 'undefined' && value !== null) { // null and undefined values won't send

                if (['startDate', 'endDate'].includes(key)) {
                    formData.append(key, reportDateFormat(finalDataObj[key]))
                    skipProperty = true
                }

                if (['boolean', 'string', 'number'].includes(type)) {
                    value = value.toString()
                    if (!value) skipProperty = true
                }

                if (type === 'object') {

                    ////////////////////////////////////////////////////////////////////////////////////////////////
                    // CUSTOM PROPERTIES
                    ////////////////////////////////////////////////////////////////////////////////////////////////

                    if (key === 'fields') {
                        skipProperty = true
                        formData.append('fields.coldata', serializeArray(finalDataObj.fields, 'coldata', extraData))
                        let a = finalDataObj.fields.map(x => ( {colname: /*encodeURIComponent(x.colname)*/  x.colname.replace(/,/g, '')} ))

                        formData.append('fields.colname', serializeArray(a, 'colname'))
                        formData.append('fields.percentwidth', serializeArray(finalDataObj.fields, 'percentwidth'))
                        formData.append('fields.level', serializeArray(finalDataObj.fields, 'level'))
                        formData.append('fields.row', serializeArray(finalDataObj.fields, 'row'))
                        formData.append('fields.percent', serializeArray(finalDataObj.fields, 'percent'))
                        formData.append('fields.result1', serializeArray(finalDataObj.fields, 'result1', undefined, true))

                        skipProperty = true
                    }

                    if (key === 'filterfields') {
                        skipProperty = true
                        formData.append('filterfields.type', serializeArray(finalDataObj.filterfields, 'type'))

                        let filtersData = {
                            sistema: [],
                            actividad: [],
                            incidencia: [],
                            ordendetrabajo: []
                        }

                        finalDataObj.filterfields.reduce((accum, item) => {
                            if (item.sistema && Array.isArray(item.sistema) && item.sistema.length > 0) {
                                filtersData.sistema = filtersData.sistema.concat(item.sistema)
                            }

                            // TODO: JDS 19/06/2020 - these properties may need another treatment but still undefined what to do...
                            if (item.actividad && Array.isArray(item.actividad) && item.actividad.length > 0) {
                                filtersData.actividad = filtersData.actividad.concat(item.actividad)
                            }

                            if (item.incidencia && Array.isArray(item.incidencia) && item.incidencia.length > 0) {
                                filtersData.incidencia = filtersData.incidencia.concat(item.incidencia)
                            }

                            if (item.ordendetrabajo && Array.isArray(item.ordendetrabajo) && item.ordendetrabajo.length > 0) {
                                filtersData.ordendetrabajo = filtersData.ordendetrabajo.concat(item.ordendetrabajo)
                            }

                            // JDS 03-02-2021 New filters
                            if (item && item.type && !['sistema', 'actividad', 'incidencia', 'ordendetrabajo'].includes(item.type)) {
                                if (item[item.type] && Array.isArray(item[item.type]) && item[item.type].length > 0) {
                                    if (!filtersData[item.type])  filtersData[item.type] = []
                                    filtersData[item.type] = filtersData[item.type].concat(item[item.type])
                                }
                            }


                        }, filtersData)

                        // console.log('filtersData', filtersData)

                        formData.append('filterfields.sistema', serializeArray(filtersData.sistema))
                        formData.append('filterfields.actividad', serializeArray(filtersData.actividad))
                        formData.append('filterfields.incidencia', serializeArray(filtersData.incidencia))
                        formData.append('filterfields.ordendetrabajo', serializeArray(filtersData.ordendetrabajo))

                        // JDS 03-02-2021 New filters
                        for (let prop in filtersData) {
                            if (filtersData.hasOwnProperty(prop)) {
                                if (!['sistema', 'actividad', 'incidencia', 'ordendetrabajo'].includes(prop)) {
                                    formData.append(`filterfields.${prop}`, serializeArray(filtersData[prop]))
                                }
                            }
                        }

                        skipProperty = true
                    }


                    ////////////////////////////////////////////////////////////////////////////////////////////////
                    //  DEFAULT TYPE METHODS
                    ////////////////////////////////////////////////////////////////////////////////////////////////

                    if (!skipProperty) {
                        if (Array.isArray(value)) {
                            if (value.length > 0) {
                                let serialized = serializeArray(value)
                                formData.append(key, serialized)
                            }
                            skipProperty = true
                        }
                    }
                }

                if (!skipProperty) formData.append(key, value)
            }
        } else {
            if (CT.IS_DEBUG()) console.warn(`Printing report skipped property ${key}`)
        }
    })

    formData.append('FormData', 'true')

    return formData
}



// Given an array of promises, executes them one after the other (when one resolves, executes the next one)
// It is similar to "Promise.all([arrProm])" but while "Promise.all()" executes all promises at same time,
// this method executes next promise only when previous one has been resolved
const runPromisesSequentially = (arrProm) => {
    let count = 0
    return new Promise((resolve, reject) => {
        const processProm = () => {
            let curProm = arrProm[count]
            curProm
                .then(() => {
                    count++
                    if (count >= arrProm.length) resolve()
                    else processProm() //once current is resolve, execute next promise
                })
                .catch(reject)
        }

        processProm()
    })
}



export default {

    // Style utilities
    getStyleValue,
    setStyleValue,
    injectStyle,
    lightOrDark,

    // Other
    fromObjecToArray,
    swapArrayElements,
    arrayContains,
    preventHTML,
    randomInteger,
    randomColor,
    uuid,
    autoId,
    deepCompare,
    getContainerId,
    extractId,
    extractIds,
    extractIdsAsExpression,
    extractIdsFromDataObject,
    extractBoolean,
    removeDuplicates,
    fromIdsToFilterString,
    extractItemsFromParams,
    ensurePercent,
    extractVarJNamesInBraces,
    replaceVarsInExpression,
    getHashFromString,
    reportFormData,
    serializeArray,

    // Paths utilities
    getInnerData,
    setInnerData,
    isStructuredField,

    // Colors
    adjustBrightness,
    shadeColor,

    // DOM elements
    addClassName,
    removeClassName,
    getRelativeCoordinates,
    setDomWidthAndStyle,

    // Dates & moments
    fromDayOffsetToMoment,
    fromMomentToDayOffset,
    intToHourString,
    binaryToBase64,
    humanReadableHour,
    reportDateFormat,
    isThePreviousDate,
    isTheNextDate,

    // RegExpr
    regExp: {
        isInteger,
        isEmail,
        containsAlphaChar
    },

    //cursor
    setCursor,
    restoreCursor,

    // Shift Intervals
    intervals: {
        createKey,
        merge,
        intersect,
        difference,
        areEqual
    },

    // Windows system auxiliary methods
    win: {
        lastEnabledPosition,
        getSize,
        getPosition,
        centerPosition,
        fitContent,
        wrappedHeight,
        scrollTo
    },

    types: {
        isFunction,
        isArray,
        isObject,
        isBoolean,
        isNumber,
        isString,
        whichTypeIs,
        isEmpty
    },

    //promises
    runPromisesSequentially


}
