import CT from "./CT"
import * as R from 'ramda'
import utils from "./utils"

const CACHE_NAMES = {
    CONTAINER_DEFINITION: "CONTAINER_DEFINITION",
    COMBO_WGT: "COMBO_WGT"
}

//When a query for a number of items is issued, this is the value before and after the elements that configure the window
//For example, if  => data.pageStartIndex = 100, data.pageSize = 20 => the window will be => [80, 140] for  CACHE_OFFSET = 20
const CACHE_OFFSET = 20
const MAX_BLOCKS = 50 //For every key, a max number of blocks for different 'windows' or blocks of elements
//let cache = {} //The main app cached objects
let store = {} //The main app cached objects
let currentContainerVersions //Copy of last 'ids' received from server informing each version of every container

//Searches inside all blocks stored for key 'k' a store that contains the items requested in 'p'
const getMatchingStore = (p, k) => {
    let matchingStore
    let start = p.data.pageStartIndex
    let end = start + p.data.pageSize
    let allBlocks = store[k]
    for (let i = 0; i < allBlocks.length; i++) {
        let block = allBlocks[i]
        let adjEnd = Math.min(end, block.total) //Sometimes there is a total number of items (the whole collection) very small, end must be limited to it!
        if (start >= block.ini && adjEnd <= block.end) {
            matchingStore = block
            break
        }
    }
    return matchingStore
}


//Returns if the interval defined in 'p' for key 'k' is between the stored interval
// (Note that stored intervals are arrays of consecutive items corresponding to positions specified
// in props 'ini' and 'end')
const isValid = (p, k) => {
    if (store[k] === undefined) {
        // CT.log("NOT in cache: " + k) //CACHE MISSING : blue message
        return false
    }
    let valid = getMatchingStore(p, k) !== undefined
    //if (!valid) CT.log("NOT in cache: " + k) //CACHE MISSING : blue message
    //else CT.log4("From cache:" + k) //VALID CACHE: green message
    return valid
}

//Assuming that the interval described in 'p' for key 'k' has been previously verified by isValid(), returns
//the corresponding items
const getFromCache = (p, k) => {
    return new Promise((resolve, reject) => {
        let store = getMatchingStore(p, k)
        if (store) {
            const start = p.data.pageStartIndex
            let offset = start - store.ini
            let end = offset + p.data.pageSize
            let block = store.cacheItems.slice(offset, end)
            resolve({
                total: store.total,
                items: block
            })
        } else {
            reject(new Error('Not matching store'))
        }
    })
}

//Returns a value representing the current registered version of a container (on ContainerUpdated events received, this version value is updated)
const getCurrentContainerVersion = (contName) => {
    let id = utils.getContainerId(contName)
    if (currentContainerVersions && id !== undefined) return currentContainerVersions[id]
    return 0
}

//Invoked by AppEvents when a new event of type "ContainersUpdated" is received
// "currentContainerVersions" is updated to new container versions
//If the updated container is someone explicitly cached, the cache is removed
const directNotifyAppEventContainersUpdated = (evt) => {
    let ids = evt.data ? evt.data.ids : undefined
    if (ids) {
        let curVersions = evt.data.ids
        //1) DEBUG => to log when a container updated is detected
        /*if (currentContainerVersions && currentContainerVersions.length === curVersions.length) {
            for (let i = 0; i < curVersions.length; i++) {if (curVersions[i] > currentContainerVersions[i])CT.log("REQ: Detected container updated id: " + i)}}*/

        // Updated container versions from the values sent by the server
        currentContainerVersions = curVersions
    }
}

//IMPORTANT: the key used to define a set of parameters 'p' is very important because it defines the "valid" or "not valid" use of a stored chunk
// For paginated queries (a window inside a big collection is required) the current container version is important and must appear inside the key name (_v1_xx)
// Some queries must invalidate cache if another container specified in 'extraContIdForCache' changed its version name
// (for example, for 'Persona' container's list of views, the container that is really updated when adding or removing views is 'FIELD_VIEW')  (_v2_yy)
const getKeyForPaginationCache = (p, d) => {
    let otherContVer = p.extraContIdForCache && currentContainerVersions ? currentContainerVersions[p.extraContIdForCache] : ''
    //Key describing the cache block (if some param changes, block will be invalid)

    const extractSubKeyFromData = () => {
        let keysValues = R.filter((k) => {
            //Exclude as a candidates for the key: function values and some specific params ("pageStartIndex" for obvious reasons, this is a 'window' cache )
            return k!== 'pageStartIndex' && typeof d[k] !== "function" && k !== 'lastQuery' && k !== 'selectableNodes'
        })((R.keys(d)))
        //for every field of 'd' that is a candidate, concatenate its value with '_'
        return R.map((k) => d[k])(keysValues).join("_")
    }

    let orgKey = `${p.url}_${p.method}_${d.id || d.indexOf || 0}_` +
        `v1_${getCurrentContainerVersion(d.container)}_v2_${otherContVer}_` +
        extractSubKeyFromData()
    return utils.getHashFromString(orgKey)
}

const getKeyForExplicitCache = (p, d) => {
    // let hash = p.data.query ? p.data.query : JSON.stringify(p).length
    //LLP: query params (hash) must NOT be included in explicit cache

    let orgKey = `${p.url}_${p.method}_${d.container}_${d.id || d.indexOf || 0}_v:${getCurrentContainerVersion(d.container)}` //LLP: hash  NOT included in explicit cache!
    return utils.getHashFromString(orgKey)
}

/*
 *  Removes all hash entries in cache object (first level entries), related with a given container name
 */
const clearContainerCache = (containerName) => {
    return new Promise((resolve, reject) => {
        let keysToClean = []
        let blockContainer
        if (containerName) {
            for (const key in store) {
                if (!['CONTAINER_DEFINITION', 'COMBO_WGT'].includes(key)) {
                    let blocks = store[key]
                    if (blocks.length) {
                        // Only need check first element, is an array of data pages of same container.
                        blockContainer = blocks[0] && blocks[0].cont
                        if (blockContainer === containerName) keysToClean.push(key)
                    }
                }
            }

            // NOTE - JDS: cache object should be changed to a stream to avoid deleting properties in this way
            console.log(`cache.clearContainerCache - ${containerName}, removing keys:`, keysToClean)
            keysToClean.forEach((key) => { delete store[key] })
            resolve()
        }
    })
}

//Use for debug only
const clearCache = () => {
    CT.log3("--------- deleting cache {} ---------")
    store = {}
}

export default {
    CACHE_OFFSET,
    MAX_BLOCKS,
    store,
    CACHE_NAMES,
    clearCache,
    clearContainerCache,
    isValid,
    getFromCache,
    getKeyForExplicitCache,
    getKeyForPaginationCache,
    directNotifyAppEventContainersUpdated
}
