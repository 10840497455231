import procurer_lib from "./lib/procurer_lib";
import pool_common from "./pools/_pool_common"
import pool from "./pools/_pool_login"

//Returns a closure component of type "cfg.type" configured as cfg
const getComponent = (cfg) => {
    let comp = pool.getComponentByName(cfg.type) || pool_common.getComponentByName(cfg.type)
    return comp(cfg)
}

//Given an existent component's name and a component 'c', returns a 'customized' view
const customizedView = (viewName, c) => procurer_lib.customizedView(pool.getView, viewName, c)

const mergeProperties = procurer_lib.mergeProperties

const addSlot = procurer_lib.addSlot

export default {
    getComponent, //Admits a configuration object
    customizedView,
    mergeProperties,
    addSlot
}
