import m from 'mithril'
import stream from 'mithril/stream'
import * as R from 'ramda'
import i18n from 'i18next'
import IconMgr from '../../src/managers/IconMgr'
//////////////////////////////////////////////////////////////////////////////////////////
// WARNING: THIS FILE IS AUTOMATICALLY GENERATED BY PROCURER, NEVER EDIT IT!!
//////////////////////////////////////////////////////////////////////////////////////////
import CLogin from '../../src_login/components/CLogin.comp'
import CRecovery from '../../src_login/components/CRecovery.comp'


const getComponentByName = (compName) => {
    switch (compName) {
        case 'CLogin': return CLogin
        case 'CRecovery': return CRecovery

    }
    console.log("WARN: pool: component " + compName + " not found")
}

const getView = (vName, c, M, mRoot) => {
    let MRoot = mRoot || M
    const hViews = {

    }
    let view = hViews[vName]
    if (view) return view
    console.log("WARN: pool: getView(): view " + vName + " not found")
}

//////////////////////////////////////////////////////////////////////////////////////////
// WARNING: THIS FILE IS AUTOMATICALLY GENERATED BY PROCURER, NEVER EDIT IT!!
//////////////////////////////////////////////////////////////////////////////////////////
export default {
    getView,
    getComponentByName
}
