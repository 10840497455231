import m from 'mithril'
import P from '../../procurer/procurer_common'
import stream from "mithril/stream";

export default (cfg) => {
    const defaults = {
        visibleOvl: stream(false),
        className: "generic-list-item",
        selectedItemStm: stream(),
        disabled: stream(false),
        notifyStm: stream(), //stream to emit actions
        drawExtension: undefined //  () => '' //Override if anything else is required to be shown
    }

    let c = P.mergeProperties(defaults, cfg)

    const notifyItemSelected = (e) => {
        c.notifyStm({name: "act_layer_item_selected", data: {id: c.id}})
    }

    const sendDoubleClickedItem = () => {
        c.notifyStm({name: "act_layer_item_double_clicked", data: {id: c.id}})
    }

    c.selectedItemStm.map((selItemId) => {
        m.redraw()
    })

    const getStyle = () => {
        let style = {}
        if (c.selectedItemStm() === c.id) {
            style = {
                backgroundColor: '#ffeb72',
                display: 'flex'
            }

        } else {
            style = {
                display: 'flex'
            }
        }
        return style
    }

    const drawIt = () => {
        return m('div', {
            id: c.id,
            className: 'activation-device-picker',
            ondblclick: sendDoubleClickedItem,
            onclick: notifyItemSelected,
            style: getStyle()
        }, [
            m('label.login_label.dev-act-list.centered', {
                style: {flex: 1}
            }, c.id),
            (c.drawExtension) ? c.drawExtension() : null
        ])

    }

    c.view = () => drawIt()
    return c
}


