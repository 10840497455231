import m from 'mithril'

const PROCURER_VERSION = "0.0.8" //remove 'visible' property after it has been applied


const isLifecycleMethod = (attr) => attr === "oninit" || attr === "oncreate" || attr === "onupdate" || attr === "onremove" || attr === "onbeforeremove" || attr === "onbeforeupdate"

//If o has a 'visible' property or method, returns the evaluation
const isVisible = (o) => o && o.visible !== undefined ? typeof o.visible === 'function' ? o.visible() : o.visible : true


// The point where components establish their set of properties int the component's root 'c'.
// The resultant set of properties is a merge where between the default values for
// every property in the component (defaults)  and component properties assigned externally (cfg)
//
// Component's root vnode attributes can be altered by copying "cfg" attributes,
// but lifeCycle methods must be excluded
const mergeProperties = (defaults, cfg) => {
    //Component props defined in the template are located in "cfg.attrs"
    if (cfg && cfg.attrs) {
        let att = cfg.attrs
        for (let propName in att) {
            if (att.hasOwnProperty(propName) && att[propName] !== undefined && !isLifecycleMethod(propName))
                defaults[propName] = att[propName]
        }
    }
    //Props defined at first level must be transmitted (excluding 'attrs', previously transmitted)
    for (let propName in cfg) {
        if (cfg.hasOwnProperty(propName)) {
            if (cfg[propName] !== undefined && propName !== "attrs")
                defaults[propName] = cfg[propName]
        }
    }
    return defaults
}


const isAttributeProperty = (propName) => {
    return (propName &&
        (propName.indexOf('on') === 0 || propName === "class" || propName === "className"  || propName === "style"))
}

const mergeNodeAttrs = (rootVnode, cfg) => {
    const att = cfg ? cfg.attrs || cfg || {} : {}
    const def = rootVnode || {}
    for (let propName in att) {
        if (att.hasOwnProperty(propName)
            && isAttributeProperty(propName)
            && !isLifecycleMethod(propName))
            def[propName] = att[propName]
    }
    return def
}


const getVnodeTransformer = (c) => (...par) => {
    if (!isVisible(c) || !isVisible(par[1])) return ''
    let attrs = par[1]
    let nodeId = attrs ? attrs.group || attrs.id : null
    if (c && nodeId && c[nodeId]) {
        let att = c[nodeId]
        if (typeof c[nodeId] === 'function') att = c[nodeId]() //attr can be a function
        if (!isVisible(att)) return ''
        let tag = att['type']
        if (tag) par[0] = tag //vnode tag can be mutated, i.e. a 'button' can become a 'div' or a component
        Object.assign(attrs, att)
    }
    //Remove 'visible' property to avoid polluting html
    let atts = par[1]
    if (atts && atts.visible) delete atts.visible
    return m(...par)
}


const customizedView = (viewFn, viewName, c) => {
    let M = getVnodeTransformer(c)
    let MRoot = (...pars) => { // mithril 'm' function to transfer properties
        pars[1] = pars[1] || {} //A component's root requires at least an empty object to receive attributes
        mergeNodeAttrs(pars[1] , c)
        return M(...pars) //Returns the component vNde customized properties (visible, slot,...) to the component root
    }
    return viewFn(viewName, c, M, MRoot)
}



// If a component is "slot enabled" via P.addSlot(c)), and component's 'slot' property is
// a function that returns a view, or an object containing a 'view' property,
// then {c.renderSlot()) can be invoked from the component's template
//
// - Enabling slot:
//      P.addSlot(c)
//
// - Slot as a component, configured by some props:
//      c.slot = P.getComponent({type: "CNAME", ...props})
//
// -  or Slot as a component without config props
//      c.slot = P.getComponentByName("CNAME")() (Equivalent to P.getComponent({type:"CNAME"}}
//
// - Inside cmponent7s template:
//      < ... >
//      ...   {c.drawSlot()} ...
//     < ... >

//component as a string (type of component to instance) or as a function (method to invoke)
const addSlot = (c) => {
    c.drawSlot = () => {
        if (c.slot) {
            //Renders a component thas has a  " slot: getComponent(cfg) " property
            if (typeof c.slot === 'object' && c.slot.view) return m(c.slot)
            else if (typeof c.slot === 'function') return c.slot()
            //NOTE: a closure component, like the one obtained from => getComponentByName() is not supported
            //(typeof is  'function', but requires => return m(c.slot)
        }
    }
    return c
}

export default {
    customizedView,
    mergeProperties,
    addSlot
}
