import SDCT from "./SDCT";
import stream from 'mithril/stream'

// -------------------------------------------------------------------------------------------
// -----------  Communication with SD http server (http-client (TBeleta code))  --------------
// -------------------------------------------------------------------------------------------

let url
let rid
let receiveRequest = false
let sendRequest = false
let _sdMgrCallback
let _status

/*
 *  In order to have SDLib not dependent on "settings", host & port may be set via window.'property'(),
 *  otherwise default values are used.
 */
const getSDUrl = () => {
    let settings = SDCT.getSettings()
    let custom = {
        host: window['__specDriverHost__'],
        port: window['__specDriverPort__']
    }

    let hostOverrides = custom.host && typeof custom.host === "function" ? custom.host() : ''
    let portOverrides = custom.port && typeof custom.port === "function" ? custom.port() : ''

    let sdHost = hostOverrides ? hostOverrides : settings.host
    let sdPort = portOverrides ? portOverrides : settings.port

    // Its mandatory to keep identifier through client session. Otherwise you will face 503 answer from server.
    rid = rid || Math.floor(Math.random() * 100000000)
    url = `http://${sdHost}:${sdPort}/${rid}`

    return url
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// API
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/*
 *  Opens a channel via GET request to start receive SpecDriver's messages.
 *  Sets callback treatment of messages and status object.
 */
const init = (sdMgrCallback, status) => {
    _sdMgrCallback = sdMgrCallback
    _status = status
    getSDUrl()
    return startReceive()
}

const resetState = () => {
    _status.busy = _status.initiated = _status.online = false
    // _status.devices = {}
    // _status.hasFingerReader = _status.hasFingerIEVO = false
    // _status.hasCardReader = _status.hasCardReaderWriter = _status.hasDocumentReader = false
    _status.changed(true)
}

const startReceive = () => {
    try {
        if (receiveRequest) return

        _status.busy = true

        // Send first request
        receiveRequest = new XMLHttpRequest()
        receiveRequest.onreadystatechange = receiveReceive
        if (receiveRequest.timeout) receiveRequest.timeout = 0
        receiveRequest.onerror = () => {
            resetState()
        }
        receiveRequest.open('get', url)
        receiveRequest.send()

        // Update status
        _status.initiated = _status.online = true
        _status.busy = false
        _status.changed(true)
    } catch (e) {
        resetState()
        SDCT.error("SDStartReceive error! " + e.message)
    }
}


const receiveReceive = () => {
    if (receiveRequest.readyState === 4) {
        if (receiveRequest.status === 200) {
            _sdMgrCallback(receiveRequest)
            // Only if all gone well the request must be renewed
            receiveRequest.open('get', url)
            receiveRequest.send()
        } else {
            // Service not avaliable, set new connection
            // Little fixes, empty request pool.
            sendRequest = receiveRequest = false
            rid = Math.floor(Math.random() * 100000000) // new ID
            getSDUrl()
        }
    }
}

const sendCmd = (strCmd) => sendToLocalServer(strCmd)

const sendToLocalServer = (data) => {
    try {
        if (!sendRequest) sendRequest = new XMLHttpRequest()

        sendRequest.onerror = () => {
            resetState()
        }
        // Send the request to sentToLocalServer-ajax-data
        sendRequest.open('post', url, true) // MUST BE TRUE!!! (async)  https://xhr.spec.whatwg.org/
        sendRequest.setRequestHeader('Content-Type', 'text/plain')
        SDCT.log("SDSendReceive: sending cmd: " + data)
        sendRequest.send(data)
    } catch (e) {
        resetState()
        SDCT.error("SDSendToLocalServer error! " + e.message)
    }
}

export default {
    init,
    getSDUrl,
    sendCmd
}
