import CT from "../CT"
/*-----------------------------------------------------------------------------------------------
// SM.js: simple finite state machine
// Inspired by: http://krasimirtsonev.com/blog/article/managing-state-in-javascript-with-state-machines-stent
/-----------------------------------------------------------------------------------------------*/


const STATE_NONE = 'st_none'
const SM_DEFAULT_NAME = 'default'


const getInstance = (cfg) => {

    let _curStateName = STATE_NONE  //current state name

    //Utils
    let _logCount = 0
    let _name = cfg.name || SM_DEFAULT_NAME
    const log = (msg) => CT.IS_DEBUG() ? console.log(`%c${_logCount++} > ${_name} ${msg}`, 'background: #ccccff; color: #000') : undefined
    const warn = (msg) => CT.IS_DEBUG() ? console.log(`%c${_logCount++} > ${_name} ${msg}`, 'background: #ffcccc; color: #000') : undefined

    const start = () => {
        if (cfg.onStart && typeof cfg.onStart === 'function') {
            log(`${cfg.name} onStart()`)
            let firstState = cfg.onStart()
            if (firstState) {
                _curStateName = firstState
                _onNewState()
            }
        }
    }

    //Given a 'newStateName' and an optional 'param', sets newStateName as the current SM state. If it is not defined, warns it
    const _changeState = (newStateName, param) => {
        _curStateName = newStateName
        //let newState = cfg.states[newStateName]
        //if (!newState) log(`*SM WARNING* : state ${newStateName} is not defined, but is assumed`)
        _onNewState(param)
    }


    //Forces the SM to be at 'newState' state
    const setState = (newStateName, param) => {
        _changeState(newStateName, param)
        log(`state forced to: ${ newStateName }`)
    }


    //receives an "actioonName", checks if SM config for current state has this action defined and changes the state
    const action = (actionName, param) => {
        // If current state has actionName defined, execute it!
        let curStateName = _curStateName
        if (cfg.states && cfg.states[curStateName]) {
            let infoState = cfg.states[curStateName]
            if (infoState && infoState.ignore && infoState.ignore.some((ignoreAct) => ignoreAct === actionName)) {
                //console.log("ignoring action " + actionName + " because it is on ingnore list")
                return
            } else {
                let destStateName = infoState[actionName]
                if (destStateName !== undefined) {
                    if (!limitLoging(actionName)) log(`${ curStateName } + ${ actionName } --> ${ destStateName }`)
                    _changeState(destStateName, param)
                    return
                }
            }
        }
        warn(`*SM WARNING* : "${actionName}" not defined for state "${curStateName}". Action "${actionName}" will be ignored..."`)
    }

    //resolves onNewState & onStateChange hook
    const _onNewState = (param) => {
        if (cfg.onNewState && cfg.onNewState[_curStateName] && typeof cfg.onNewState[_curStateName] === 'function') {
            let hookRetVal = cfg.onNewState[_curStateName](param) //Invokes hook and receives value
            if (typeof hookRetVal === "string") {
                if (!limitLoging(hookRetVal)) log(`From hook, newState name: ${hookRetVal}`)
                _changeState(hookRetVal)
            } else {
                if (typeof hookRetVal === "object") {
                    if (!limitLoging(hookRetVal.name)) log(`From hook, newState name: ${hookRetVal.name}`)
                    _changeState(hookRetVal.name, hookRetVal.param)
                }
            }
        }
    }


    // "cfg" can exist an contain a map of names with a value defining the number of logs for a certain name after thtat, no longer SM logs are shown
    const limitLoging = (name) => {
        if (cfg && cfg.limitLog){
            let preventObj = cfg.limitLog[name] || 1 //originally "preventObj" is a number
            if (preventObj) {
                if (preventObj._count === undefined) cfg.limitLog[name] = {_count: 0, limit: preventObj} //First time, an object with countr and limit
                else preventObj._count++
                if (preventObj._count >= preventObj.limit) return true //log will be limited
            }
        }
        return false
    }


    return {
        name: _name, //state machine invformative name,
        action,
        start,
        setState,
        currentState: () => _curStateName
    }

}

export default {
    getInstance,
    STATE_NONE,
    SM_DEFAULT_NAME
}
