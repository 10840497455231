// -------------------------------------------------------------------------------------------
// Manager to high level communication with SpecDriver devices
// -------------------------------------------------------------------------------------------
import XmlToJson from './XmlToJson'
import * as R from 'ramda'
import stream from 'mithril/stream'
import SDCT from './SDCT'
import SDCmds from './SDCmds'
import SDSendReceive from './SDSendReceive'

let specDrvVer = ''              // SpecDriver version
let discDevices = {}             // Discovered devices

let discoReceivedStm = stream()  // Disco command stream
let discoReceivedStmMap          // Disco command stream
let discoResolve
let discoTimeout
let eventStm = stream()          // External notifications streams. SDMgr via SDCmds sends some events outside
let genericResponseStm           // Response obtained from SD (stream). Will be created and ended on every operation
let eventResponseStm
let externalCmdStm               // SDMgr receives some commands from outside and sends commands to SDCmds
let cmdStm                    // Enroll have been done properly (stream).

// SpecDriver status data, updated since last call.
let status = {

    changed: stream(),           // Just for trigger actions on status changes, if needed
    initiated: false,            // Still unknown devices around but with communication channel to server
    online: false,               // Server ready and listening
    busy: false,                 // Flag to avoid concurrent requests

    lastUpdate: null,            // Retry, refresh and this kind of stuff
    attempts: 1,                 // Maximum attempts to establish a connection (after first one)
    pollInterval: 15000,
    refreshInterval: undefined,
    refreshTimeout: undefined,

    devices: {},                 // Devices map and types connected
    hasFingerReader: false,
    hasFingerIEVO: false,
    hasFingerZK: false,
    hasCardReader: false,
    hasCardReaderWriter: false,
    hasDocumentReader: false

}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Initialize and refresh status methods
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/*
 *  Establish communication channel with SpecDriver. (GET without timeout).
 */
const init = () => {

    if (!window._global__TEST_MODE__) {

        if (!discoReceivedStmMap) {
            // Listening disco command's answer
            discoReceivedStmMap = discoReceivedStm.map(() => {
                clearTimeout(discoTimeout)
                updateStatus()
                discoResolve()
            })
        }

        return SDSendReceive.init(responseFromSD, status)
    }
}

/*
 *   Sends devices discovery command to SpecDriver and updates their inner map.
 */
const scanDevices = () => {
    return new Promise((resolve, reject) => {
        if (!window._global__TEST_MODE__ && !status.busy) {
            status.busy = true
            discoResolve = resolve
            discoTimeout = setTimeout(() => {
                SDCT.error('>> SDMgr: Error: Devices discovery command failed. Timeout exceeded..')
                status.busy = false
                reject({
                    code: SDCT.ERR_CODE.NO_CONNECTION,
                    message: 'Devices discovery command failed. Timeout exceeded.'
                })
            }, SDCT.TIMEOUT_DISCO_COMMAND)

            setTimeout(() => {
                SDSendReceive.sendCmd('<Disco/>')
            }, SDCT.DELAYCMD)

        } else {
            reject()
        }
    })
}

/*
 *   Initiates channel with SpecDriver or refresh discovery devices data.
 */
const refreshStatus = () => {
    return new Promise((resolve, reject) => {
        resolve()

        let isOutDated = (!status.lastUpdate) || (Date.now() > (status.lastUpdate + status.pollInterval))

        if (!status.busy && isOutDated) {
            if (!status.initiated) {
                init()
            } else {
                scanDevices()
                    .catch((err) => {
                        status.busy = false
                        SDCT.error(err)
                    })
            }
        } else {
            if (!status.refreshTimeout) status.refreshTimeout = setTimeout(() => {
                refreshStatus()
            }, status.pollInterval)
        }

    })
}

/*
 *   Updates SpecDriver main status object.
 */
const updateStatus = () => {
    status.busy = false
    status.lastUpdate = Date.now()
    status.devices = discDevices
    status.hasFingerReader = existsFingerDevice('FingerReader', 'FingerReader')
    status.hasFingerIEVO = existsFingerDevice('FingerReader','FingerIEVO')
    status.hasFingerZK = existsFingerDevice('FingerReader', 'DIT9USB')
    status.hasCardReader = existsCardReaderDevice('CardReader')
    status.hasCardReaderWriter = existsCardReaderDevice('CardReaderWriter')
    status.hasDocumentReader = existsCardReaderDevice('DocumentReader')
    status.changed(true)  // trigger notification

}

/*
 *   Starts SpecDriver status polling
 */
const monitorServer = () => {
    return new Promise((resolve) => {
        resolve() // No blocking promise

        if (!status.initiated)  {
            init()

            // Some delay to the first discovery command to do not block.
            setTimeout(() => { if (status.online) refreshStatus() }, 2500)
        }

        if ((status.attempts > 0) && !status.refreshInterval) {

            status.refreshInterval = setInterval(() => {
                if (!status.online) {
                    // Try available attempts and go sleep...
                    if (status.attempts > 0) {
                        status.attempts--
                        refreshStatus()
                    } else {
                        clearInterval(status.refreshInterval)
                    }
                } else {
                    // If server is online... non stop!
                    refreshStatus()
                }
            }, status.pollInterval)


        }

    })
}

/*
 *  Cleans devices map stored the last discovery devices command response.
 */
const clearDiscoveredDevices = () => {
    R.forEach((deviceTypeArr) => deviceTypeArr.splice(0))
    discDevices = {}
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  SpecDriver response parsers
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/*
 *   Main callback function executed on SpecDriver on answers or notifications.
 */
const responseFromSD = (xhr) => {
    let parser = new DOMParser()
    let xmlDoc = parser.parseFromString(xhr.responseText, "text/xml")
    let respObj = XmlToJson.xmlToJson(xmlDoc)

    //Log received response (if log is enabled). If it contains a template (#text) break it
    let sResp = JSON.stringify(respObj)
    let posTemplate = sResp.indexOf('#text')
    if (posTemplate >= 0) SDCT.log(">> Received from SD (JSON): " + sResp.substr(0, posTemplate + 20) + " ... binary ...")
    else SDCT.log(">> Received from SD (JSON): " + sResp)

    // From response object, depending on which response to which command, a specific parsing is required
    if (respObj['Disco'] && respObj['Disco']['Devices']) {
        let deviceObj = respObj['Disco']['Devices']['Device']
        if (deviceObj) {
            parseResponseDisco(deviceObj)
        } else {
            clearDiscoveredDevices() // new
            discoReceivedStm('')
            SDCT.error("SDMgr: responseFromSD: Device not found in \<Disco\>\<Devices\> response")
        }
    } else if (respObj['GenericResponse']) {
        let genericObj = respObj['GenericResponse']
        if (genericObj['Response'] && genericObj['Response']['@attributes'] && genericObj['Response']['@attributes']['Action'] === 'ReadFinger') {
            parseResponseReadFinger(genericObj['Response']['Params'])
        } else if (genericObj['Response'] && genericObj['Response']['@attributes'] && genericObj['Response']['@attributes']['Action'] === 'ReadCard') {
            parseResponseReadCard(genericObj)
        } else if (genericObj['Response'] && genericObj['Response']['@attributes'] && genericObj['Response']['@attributes']['Action'] === 'WriteCard') {
            parseResponseWriteCard(genericObj)
        } else if (genericObj['Response'] && genericObj['Response']['@attributes'] && genericObj['Response']['@attributes']['Action'] === 'ReadDocument') {
            parseResponseReadDocument(genericObj)
        } else if (genericObj['@attributes']) {
            if (genericResponseStm) genericResponseStm(genericObj["@attributes"])
        }
    } else if (respObj['Event']) { //{ {"Event":{"@attributes":{"DeviceId":"102","Event":"MATCHOK","Value":"0"}}}
        let evtAtt = respObj['Event']["@attributes"]

        //Enabling the possibility of enrolling cards for devices working by "Event" instead of by "Command"
        if (evtAtt && evtAtt.Event === "Tag" && evtAtt.Value && typeof evtAtt.Value === "string") {
            SDCT.log(">> SDMgr: Read Card Tag :" + evtAtt.Value)
            cmdStm({ok: true, CardSN: evtAtt.Value, CardSize: evtAtt.Value.length})
        } else {
            if (respObj['Event']['Response']) evtAtt.response = respObj['Event']['Response']['@attributes']
            if (evtAtt && eventResponseStm)  eventResponseStm(evtAtt)
            else SDCT.error("** WARNING ** : responseFromSD: event does not have attributes")
        }
    } else {
        SDCT.log('WARNING: Unknown responseFromSD -----------------------------------------------------------------')
        SDCT.log(respObj)
        SDCT.log('-------------------------------------------------------------------------------------------------')
    }
}


/*
 *  Parser device response:
 *  @param: {json} dv
 *    {
 *      "@attributes": {
 *        "Version": "1.0.0.0",
 *        "Type": "DocumentReader",
 *        "Name": "Access",
 *        "DeviceId": "1"
 *      }
 *    }
 */
const parseDevice = (dv) => {
    let att = dv["@attributes"]
    if (att) {
        specDrvVer = att.Version
        let type = att["Type"]
        if (!discDevices[type]) discDevices[type] = []
        discDevices[type].push(att)
    }
}

/*
 *  Parser of command '<Disco>' response:
 *  @param: {array} devices
 *     {
 *       "@attributes": {
 *         "Version": "1.0.0",
 *         "Type": "DocumentReader",
 *         "Tech": "...",
 *         "Maker": "...",
 *         "Operation": "Event",
 *         "Name": "Access",
 *         "DeviceId": "1"
 *       }
 *     }
 */
const parseResponseDisco = (devices) => {
    clearDiscoveredDevices()

    if (SDCT.isArray(devices)) {
        R.map((dv) => parseDevice(dv))(devices)
    } else {
        parseDevice(devices)
    }

    discoReceivedStm(devices)
}

/*
 *  Parses a part of a "GenericResponse", the finger templates.
 *  @param: {json} response
 *    {
 *      "@attributes": {
 *        "BinaryEncoding": "MIME"
 *      },
 *      "Param": {
 *        "@attributes": {
 *          "Name": "Template",
 *          "Type": "BINARY"
 *        },
 *        "#text": "...TemplateData..."
 *      }
 *    }
 */
const parseResponseReadFinger = (resp) => {
    if (resp && resp['Param']) {
        let att = resp['Param']['@attributes']
        if (att['Name'] === "Template") {
            let template = resp['Param']['#text']
            cmdStm({ok: true, template})
        }
    }
}

/*
 *  Parser card message.
 *
 *  First event received:
 *
 *   {
 *     "Event": {
 *       "@attributes": {
 *         "DeviceId": "101",
 *         "Event": "Tag",
 *         "Value": "673302506"
 *       }
 *     }
 *   }
 *
 *  Second response received:
 *
 *   {
 *     "GenericResponse": {
 *       "@attributes": {
 *         "Valid": "true",
 *         "ErrorCode": "0"
 *       },
 *       "Response": {
 *         "@attributes": {
 *           "DeviceId": "101",
 *           "Action": "ReadCard",
 *           "CardSN": "673302506",
 *           "CardSize": "9",
 *           "Tarjeta": "",
 *           "Sistema": "",
 *           "Version": ""
 *         }
 *       }
 *     }
 *   }
 */
const parseResponseReadCard = (resp) => {
    let result = resp['@attributes']
    let response = resp['Response']['@attributes']

    if (result['Valid'] === "true") {
        let cardSN = response['Tarjeta'] && response['Tarjeta'].length > 0 ? response['Tarjeta'] : response['CardSN']
        cmdStm({ok: true, CardSN: cardSN, CardSize: response['CardSize']})
    } else {
        let msg = resp['Message']['#text']
        cmdStm({ok: false, msg})
    }
}

const parseResponseReadDocument = (resp) => {
    let result = resp['@attributes']
    let response = resp['Response']['@attributes']

    if (result['Valid'] === "true" && response) {
        eventResponseStm({ok: true, response})
    } else {
        cmdStm({ok: false})
    }
}

const parseResponseWriteCard = (resp) => {
    let result = resp['@attributes']
    if (result['Valid'] === "true") {
        cmdStm({ok: true})
    } else {
        let msg = resp['Message']['#text']
        cmdStm({ok: false, msg})
    }
}



////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Auxiliary methods
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


const getSpecDriverVersion = () => specDrvVer

const getDeviceByTpe = (deviceType, deviceName) => {
    if (!deviceName) {
        if (existDeviceByTpe(deviceType)) return discDevices[deviceType][0]
    }    else {
        if (existDeviceByTpe(deviceType, deviceName)) return discDevices[deviceType].find((dev) => dev.Name === deviceName)
    }
}

const getDeviceById = (deviceType, id) => {
    if (existDeviceByTpe(deviceType)) {
        return discDevices[deviceType].find((device) => (device.DeviceId === id))
    }
}

const existDeviceByTpe = (deviceType, name) => {
    let existsType = discDevices.hasOwnProperty(deviceType)
    if (name) {
        return (existsType && discDevices[deviceType].length > 0 ) ?
            R.findIndex((device) => device.Name === name)(discDevices[deviceType]) >= 0 : false
    } else {
        return discDevices[deviceType] && SDCT.isArray(discDevices[deviceType]) && discDevices[deviceType].length > 0
    }
}

const existsFingerDevice = (type, name) => existDeviceByTpe(type, name)

const existsCardReaderDevice = (type = 'CardReader') => existDeviceByTpe(type)

const getFingerDeviceSN = (deviceName) => {
    return new Promise((resolve, reject) => {
        status.busy = true
        let frDevice = getDeviceByTpe('FingerReader', deviceName)
        if (frDevice) {
            resolve(frDevice.DeviceSN)
        } else {
            reject({
                code: SDCT.ERR_CODE.NO_DEVICES_FOUND,
                message: '>> SDMgr: Error: No fingerprint reader device found'
            })
        }
        status.busy = false
    })
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  SpecDriver main Actions
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


/*
 *  Creates new action streams.
 */
const startStreams = () => {
    status.busy = true
    genericResponseStm = stream()
    eventResponseStm = stream()
    externalCmdStm = stream()
    cmdStm = stream()
}

/*
 *  Destroys actions streams used.
 */
const stopStreams = () => {
    genericResponseStm.end(true)
    eventResponseStm.end(true)
    externalCmdStm.end(true)
    cmdStm.end(true)
    status.busy = false
}

/*
 *  Sends a enroll card command to SpecDriver.
 */
const readCard = (type, id, byEvent = false) => {
    return new Promise((resolve, reject) => {
        let frDevice = getDeviceById(type, id)
        if (frDevice && frDevice.DeviceId) {
            startStreams()
            SDCmds.readCard(frDevice.DeviceId, genericResponseStm, eventResponseStm, eventStm, externalCmdStm, cmdStm, byEvent)
                .then((enrollTpl) => {
                    stopStreams()
                    resolve(enrollTpl)
                })
                .catch((err) => {
                    stopStreams()
                    reject(err)
                })
        } else {
            reject()
        }
    })
}


const writeCard = (type, id, cardNumber) => {
    return new Promise((resolve, reject) => {
        let frDevice = getDeviceById(type, id)
        if (frDevice && frDevice.DeviceId) {
            startStreams()
            SDCmds.writeCard(frDevice.DeviceId, cardNumber,
                genericResponseStm, eventResponseStm, eventStm, externalCmdStm, cmdStm)
                .then((enrollTpl) => {
                    stopStreams()
                    resolve(enrollTpl)
                })
                .catch((err) => {
                    stopStreams()
                    reject(err)
                })
        } else {
            reject()
        }
    })
}


/*
*  Sends a readDocument command to SpecDriver.
*/
const readDocument = (type, id, byEvent = false) => {
    return new Promise((resolve, reject) => {
        let frDevice = getDeviceById(type, id)
        if (frDevice && frDevice.DeviceId) {
            startStreams()
            SDCmds.readDocument(frDevice.DeviceId, genericResponseStm, eventResponseStm, eventStm, externalCmdStm)
                .then((readDoc) => {
                    stopStreams()
                    resolve(readDoc)
                })
                .catch((err) => {
                    stopStreams()
                    reject(err)
                })
        } else {
            reject()
        }
    })
}



/*
 *  Sends a verify command against fingerprints provided.
 *  param {array} userFingerprints
 */
const identifyFinger = (userFingerprints) => {
    return new Promise((resolve, reject) => {
        let frDevice = getDeviceByTpe('FingerReader')
        if (frDevice && frDevice.DeviceId) {
            startStreams()
            SDCmds.identifyFinger(frDevice.DeviceId, userFingerprints, genericResponseStm, eventResponseStm, eventStm, externalCmdStm)
                .then(() => {
                    SDCT.log(">> SDMgr : identification OK !")
                    stopStreams()
                    resolve()
                })
                .catch((err) => {
                    stopStreams()
                    reject(err)
                })
        } else reject({
            code: SDCT.ERR_CODE.NO_DEVICES_FOUND,
            message: ">> SDMgr : A device of type 'FingerReader' is not found"
        })
    })
}

/*
 *  Sends a enroll fingerprint command to SpecDriver.
 */
const enrollFinger = (deviceName) => {
    return new Promise((resolve, reject) => {
        if (!status.busy) {
            status.busy = true
            let frDevice = getDeviceByTpe('FingerReader', deviceName)
            if (frDevice && frDevice.DeviceId) {
                startStreams()
                SDCmds.enrollFinger(frDevice.DeviceId, genericResponseStm, eventResponseStm, eventStm, externalCmdStm, cmdStm)
                    .then((enrollTpl) => {
                        stopStreams()
                        resolve(enrollTpl)
                    })
                    .catch((err) => {
                        stopStreams()
                        reject(err)
                    })
            } else {
                status.busy = false
                reject({
                    code: SDCT.ERR_CODE.NO_DEVICES_FOUND,
                    message: ">> SDMgr : A device of type 'FingerReader' is not found"
                })
            }
        } else {
            reject({
                code: SDCT.ERR_CODE.BUSY,
                message: ">> SDMgr : currently busy, try again later."
            })
        }
    })
}

/*
 *  Sends a verify fingerprint command against the enrolled one on device.
 *  Second step after "enrollFinger" action call.
 *  param {array} userFingerprints
 */
const verifyEnrollFinger = () => {
    SDCmds.verifyEnroll()
}

/*
 *  Unbinds all devices attached to the channel established with SpecDriver
 */
const forceFingerRelease = () => {
    SDCT.log(">>SDMgr : forcing finger release via FORCE_RELEASE command")
    try {
        if (externalCmdStm) externalCmdStm(SDCT.CMD_TYPE.FORCE_RELEASE)
        status.busy = false
    } catch (e) {
        SDCT.error(e)
    }
}


/*
 *  Updates the settings sent on commands to SpecDriver
 */
const setSettings = (data) => {
    return new Promise((resolve, reject) => {
        let settings = {}

        // Defaults
        settings.protocol = 'http:'
        settings.host = 'localhost'
        settings.port = 8101
        settings.rotateTemplate = 1
        settings.cardReadTimeout = 1000

        if (data['SpecDriver.Url']) {
            try {
                let parser = document.createElement('a')
                let origValue = data['SpecDriver.Url'].value
                //Ensure protocol is defined to make a proper url
                if (origValue && origValue.length > 0 && origValue.indexOf("http") < 0) {
                    origValue = "http://" + origValue
                }
                parser.href = origValue || data['SpecDriver.Url'].defaultValue

                settings.protocol = parser.protocol || 'http:'
                settings.host = parser.hostname || 'localhost'
                settings.port = parser.port || 8101
                resolve()
            } catch (err) {
                SDCT.log('>>SDMgr : setSettings error:', err)
            }
        }

        if (data['SpecDriver.RotateTemplateFR']) {
            let value = data['SpecDriver.RotateTemplateFR'].value || data['SpecDriver.RotateTemplateFR'].defaultValue
            if (value === "True") {
                settings.rotateTemplate = 1
            } else {
                settings.rotateTemplate = 0
            }
        }

        if (data['SpecDriver.CardReadTimeout']) {
            settings.cardReadTimeout = parseInt(data['SpecDriver.CardReadTimeout'].value || data['SpecDriver.CardReadTimeout'].defaultValue)
        }

        SDCT.setSettings(settings)
        resolve()
    })
}

export default {

    // Status and notification objects
    status,
    eventStm,

    // Main
    init,
    scanDevices,
    refreshStatus,
    monitorServer,

    // Auxiliary
    getFingerDeviceSN,
    getSpecDriverVersion,

    // SpecDriver Commands
    identifyFinger,
    readCard,
    writeCard,
    readDocument,
    enrollFinger,
    verifyEnrollFinger,
    forceFingerRelease,
    setSettings

}
