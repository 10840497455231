import m from 'mithril'
import stream from 'mithril/stream'
import P from '../../procurer/procurer_login'
import CT from '../../src/CT'
import REQ from '../../src/REQ'
import I18N from '../../src/I18N/I18N'
import IconMgr from '../../src/managers/IconMgr'
import i18n from 'i18next'

export default (cfg) => {

    let domInputUser, domPswdUser

    const foo = {action: () => null}       // Fake state machine

    const defaults = {
        sm: foo,
        sso: {userUrl: '', employeeUrl: ''},
        srcLogo: IconMgr.getLogo('netTimeLogoLogIn'),
        userVal: stream(),
        pwdVal: stream(),
        supervisorVal: stream(false),
        showSupervisorOnLogin: stream(),    // shows Supervisor check option
        canChangePassword: stream(true),
        hasSSO: stream(false),              // SSO view enabled
        hideLogin: stream(false),           // SSO hide normal login view
        showSSO: stream(false),             // SSO displayed
        iDialog: undefined,
        dialog: {
            message: stream(''),
            visible: stream(false)
        }
    }

    let c = P.mergeProperties(defaults, cfg)

    // Init sso values
    c.hasSSO(!!(c.sso && c.sso.userUrl && c.sso.employeeUrl))   // Enabled
    c.showSSO(c.hasSSO())                                       // Displayed (you can still be able to normal login)

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    //  Methods
    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const getLoginData = () => {
        let loginData = {username: c.userVal(), pwd: c.pwdVal()}
        if (c.showSupervisorOnLogin()) loginData.asUser = c.supervisorVal() === true ? 'true' : 'false'
        return loginData
    }

    const doSSO = (isUser) => {
        let url = isUser ? c.sso.userUrl : c.sso.employeeUrl

        if (url) {
            location.href = url
        } else {
            c.iDialog.mode('error')
            c.iDialog.message(i18n.t('No hay url de acceso mediante SSO.'))
            c.iDialog.visible(true)
        }
    }

    const doLogin = () => {
        REQ.request({method: 'POST', url: '/api/login/', data: getLoginData()})
            .then((response) => {
                if (response.ok === true) {

                    // console.log('LOGIN', JSON.stringify(response, null, "\t"))

                    // JDS Ene 2020: When is required a password change, there is no session yet to ask about /api/settings.
                    I18N.init(response.language)
                        .then(() => {

                            let taskError = false
                            // Reset previous title
                            if (c.parentSm && c.parentSm.components && c.parentSm.components.iDialog) {
                                c.parentSm.components.iDialog.title = undefined
                            }

                            let passwordCommand
                            let redirectionCommand
                            let commands = {
                                tasks: [],
                                redirection: false,
                                forcePasswordChange: false,
                                redirectionParams: {},
                                forcePasswordChangeParams: {}
                            }

                            if (response.tasks && response.tasks.length > 0) {
                                commands.tasks = response.tasks
                                passwordCommand = response.tasks.find((task) => (task.type === 'changePassword'))
                                redirectionCommand = response.tasks.find((task) => (task.hasOwnProperty('action')))
                            }

                            // Setting task flags
                            commands.redirection = !!(redirectionCommand)
                            commands.forcePasswordChange = !!(passwordCommand)

                            // Redirect to app and open module
                            if (commands.redirection) {
                                commands.redirectionParams = {
                                    module: redirectionCommand.action,
                                    date: redirectionCommand.date,
                                    id: redirectionCommand.id,
                                    isUsr: c.supervisorVal()
                                }
                            }

                            // Force passwordChange
                            if (commands.forcePasswordChange) {
                                commands.forcePasswordChangeParams = {
                                    name: 'changePassword',
                                    access_token: response.access_token,
                                    redirectTo: response.redirectTo,
                                    portal: response.portal,
                                    usr: c.userVal()
                                }

                                if (response.hasOwnProperty('redirectTo')) {
                                    let pos = response.redirectTo.indexOf('?q=') + 3
                                    if (pos >= 0) {
                                        try {
                                            let encoded = response.redirectTo.substring(pos)
                                            let bin = atob(encoded)
                                            let decoded = JSON.parse(bin)
                                            if (decoded.hasOwnProperty('hash')) commands.forcePasswordChangeParams.hash = decoded.hash                 // hash
                                            if (decoded.hasOwnProperty('date')) commands.forcePasswordChangeParams.date = decoded.date                 // due date
                                            if (decoded.hasOwnProperty('usr')) commands.forcePasswordChangeParams.usr = decoded.usr                    // user name
                                            if (decoded.hasOwnProperty('oldPwd')) commands.forcePasswordChangeParams.oldPwd = decoded.oldPwd           // boolean
                                            if (decoded.hasOwnProperty('delegated')) commands.forcePasswordChangeParams.delegated = decoded.delegated  // boolean
                                        } catch (err) {
                                            commands.forcePasswordChange = false
                                            commands.forcePasswordChangeParams = {}
                                            console.error('Login changePasswordParams error', err)

                                            c.iDialog.mode('error')
                                            c.iDialog.message(err.message)
                                            c.iDialog.visible(true)
                                            taskError = true
                                        }
                                    } else {
                                        // Custom redirection (currently not used)
                                        if (response.redirectTo) commands.redirectionParams.customUrl = response.redirectTo
                                    }
                                }
                            }

                            /* NT5 code
                                if(data.redirectTo)
                                    document.location.href= data.redirectTo;
                                else if(data.portal)
                                    document.location.href= '/portal';
                                else
                                    document.location.href= '/index.htm';
                            */

                            // JDS 16/09/2020: Fixed wrong check about redirect to portal. SSO do not matters on this
                            //                 redirection.
                            // Response says user must be redirect to /portal
                            // if (response.portal && (c.sso.userUrl || c.sso.employeeUrl)) {
                            if (response.portal) {
                                commands.redirection = true
                                commands.redirectionParams.portal = true
                            }

                            if (!taskError) {
                                if (commands.redirection || commands.forcePasswordChange || commands.tasks.length > 0) {
                                    c.sm.action('processCommands', commands)
                                } else {
                                    c.sm.action('redirect')
                                }
                            } else {
                                c.sm.action('taskError')
                            }
                        })
                } else {
                    c.iDialog.mode('error')
                    c.iDialog.message(response.message || 'Usuario o contraseña incorrectos')
                    c.iDialog.visible(true)
                }
            })
            .catch((err) => {
                c.sm.action('error')
                console.error('CLogin - doLogin:', err)
            })
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    //  HyperScript functions
    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const inputUserSetFocus = () => {
        // Timeout required to set focus properly on every browser!
        setTimeout(() => {
            if (domInputUser) domInputUser.focus()
        }, CT.MS_DELAY_SET_FOCUS)
    }

    const inputPswdSetFocus = () => {
        // Timeout required to set focus properly on every browser!
        setTimeout(() => {
            if (domPswdUser) domPswdUser.focus()
        }, CT.MS_DELAY_SET_FOCUS)
    }

    const onEnter = (evt) => {
        if (evt.keyCode === 13) doLogin()
    }

    const resetInputs = () => {
        c.userVal('')
        c.pwdVal('')
        c.supervisorVal(false)
        c.dialog.message('')
    }

    c.oninit = () => {
        if (!c.iDialog) c.iDialog = P.getComponent({
            type: 'CGenericDlg',
            message: c.dialog.message,
            visible: c.dialog.visible,
            onCloseDlgHook: (opt) => {
                // Clear password
                if (c.sm && c.sm.currentState() === 'onLogin') {
                    c.pwdVal('')
                    inputPswdSetFocus()
                }
            }
        })

        resetInputs()
    }

    c.drawDialog = () => m(c.iDialog)

    c.showSpinner = () => CT.isLoading() ? m('.loader-holder', m('.loader')) : ''

    const getLoginTitle = () => {
        let sQAMsg = CT.isQALicense() ? ' - ' + CT.getQAMessage() : ''
        return i18n.t('Inicio de sesión') + sQAMsg
    }

    const getLoginTitleCN = () => CT.isQALicense() ? '.qa' : ''

    c.drawLogin = () => {

        if (c.showSSO()) {
            return m('.login-form', {id: 'form-login'}, [

                m('.login-form-header', {}, [
                    m('.login-logo', m('img', {className: 'brand-image', src: c.srcLogo})),
                    m('.login_label.login_title' + getLoginTitleCN(), {}, getLoginTitle() + ' - SSO'),
                ]),

                m('.login-form-body', {style: {padding: 0}}, [
                    m('.login-group.flex-row', {style: {justifyContent: 'space-around'}}, [
                        m('.sso-wrap', [
                            m('button.sso-btn', {
                                id: 'btn-sso-usr',
                                onclick: () => doSSO(true)
                            }, m('img', {src: IconMgr.getIcon('ssoUser')})),
                            m('.sso-txt', i18n.t('Usuario'))
                        ]),
                        m('.sso-wrap', [
                            m('button.sso-btn', {
                                id: 'btn-sso-emp',
                                onclick: () => doSSO(false)
                            }, m('img', {src: IconMgr.getIcon('ssoEmpl')})),
                            m('.sso-txt', i18n.t('Empleado'))
                        ])
                    ])
                ]),

                !c.hideLogin() ? m('.login-form-footer', [
                    m('label.login_label.link', {
                        id: 'normal-login',
                        style: {lineHeight: '24px'},
                        onclick: (e) => {
                            e.preventDefault()
                            e.stopImmediatePropagation()
                            c.showSSO(false)
                            return false
                        }
                    }, [
                        m('img', {src: IconMgr.getMenuIcon('documents')}),
                        i18n.t('Formulario de autenticación')
                    ])
                ]) : ''

            ])
        } else {
            return m('.login-form', {id: 'form-login'}, [

                m('.login-form-header', {}, [
                    m('.login-logo', m('img', {className: 'brand-image', src: c.srcLogo})),
                    m('.login_label.login_title' + getLoginTitleCN(), {}, getLoginTitle())
                ]),

                m('.login-form-body', {}, [
                    m('.login-group', [
                        m('label.login_label', i18n.t('Usuario')),
                        m(`input`, {
                            id: 'input-user',
                            className: 'input-login',
                            type: 'text',
                            oncreate: (vnode) => {
                                domInputUser = vnode.dom
                                inputUserSetFocus()
                            },
                            onkeydown: onEnter,
                            oninput: m.withAttr('value', c.userVal),
                            value: c.userVal,
                            autocomplete: 'off'
                        })
                    ]),

                    m('.login-group', [
                        m('label.login_label', i18n.t('Contraseña')),
                        m(`input`, {
                            id: 'input-password',
                            className: 'input-login',
                            type: 'password',
                            onkeydown: onEnter,
                            oncreate: (vnode) => {
                                domPswdUser = vnode.dom
                            },
                            oninput: m.withAttr('value', c.pwdVal),
                            value: c.pwdVal,
                            autocomplete: 'new-password'
                        })
                    ]),

                    m('.login-group.with-child.flex-row', [

                        c.canChangePassword() ? m('.login-group', {style: {flexBasis: '70%'}}, [
                            m('label.login_label.link', {
                                id: 'recovery-link',
                                onclick: (e) => {
                                    e.preventDefault()
                                    e.stopImmediatePropagation()
                                    c.sm.action('setViewRecovery')
                                    return false
                                }
                            }, i18n.t('He olvidado mi contraseña'))
                        ]) : '',

                        c.showSupervisorOnLogin() ? m('.login-group.flex-row', [
                            m('label.login_label', {style: {flexBasis: '70%'}}, i18n.t('Supervisor')),
                            m('.checkbox', {}, [
                                m('input', {
                                    id: 'chk-supervisor',
                                    type: 'checkbox',
                                    onchange: m.withAttr('checked', c.supervisorVal),
                                    oncreate: (vnode) => vnode.dom.checked = c.supervisorVal()
                                }),
                                m('label', {for: 'chk-supervisor'})
                            ])
                        ]) : ''
                    ])
                ]),

                m('.login-form-footer', [
                    m('button.btn-medium.btn-brand', {id: 'btn-submit', onclick: () => doLogin()}, i18n.t('Login')),
                    c.hasSSO() ? m('button.btn-medium.btn-grey', {
                        id: 'btn-submit',
                        onclick: () => { c.showSSO(true) }
                    }, 'SSO') : ''
                ])
            ])

        }
    }

    c.tinyForm = () => !(c.showSupervisorOnLogin() || c.canChangePassword())

    c.view = () => {
        return [
            c.drawDialog(),
            c.showSpinner(),
            m('.login-wrapper', [
                m('.login-header'),
                m('.login-body', {style: {maxHeight: c.tinyForm() || c.showSSO() ? '400px' : '450px'}}, [
                    c.drawLogin()
                ]),
                m('.login-footer', {style: {height: '25%'}}, m('.copyright-footer', CT.copyright()))
            ])
        ]
    }

    return c

}
