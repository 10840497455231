import m from 'mithril'
import stream from 'mithril/stream'
import P from '../../procurer/procurer_login'
import CT from '../../src/CT'
import REQ from '../../src/REQ'
import i18n from 'i18next'
import IconMgr from '../../src/managers/IconMgr'

export default (cfg) => {

    let domInputUser

    const defaults = {
        srcLogo: IconMgr.getLogo('netTimeLogoLogIn'),
        showSupervisorOnLogin: stream(),

        userVal: stream(''),
        supervisorVal: stream(false),

        // Dialog related stuff
        iDialog: undefined,
        dialogMessage: stream(''),
        dialogVisible: stream(false)
    }

    let c = P.mergeProperties(defaults, cfg)


    /*
     *  It does a recovery password request to server
     */
    const recoverPassword = () => {
        let data = {username: c.userVal()}

        if (c.showSupervisorOnLogin()) data.asUser = c.supervisorVal() === true ? 'true' : 'false'

        REQ.request({method: 'POST', url: '/api/recoverPassword/', data})
            .then((res) => {
                if (res.message !== '') {
                    c.iDialog.mode((res.ok === true) ? 'info' : 'error')
                    c.iDialog.message(res.message)
                    c.iDialog.visible(true)
                }
            })
            .catch((err) => {
                console.error('Error CRecovery.recoverPassword:', err)
                c.sm.action('error')
                // c.iDialog.mode('error')
                // c.iDialog.message(err.message)
                // c.iDialog.visible(true)
            })

    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    //  HyperScript functions
    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const inputFocus = () => {
        setTimeout(() => {
            if (domInputUser) domInputUser.focus()
        }, CT.MS_DELAY_SET_FOCUS)
    }

    const onEnter = (evt) => {
        if (evt.keyCode === 13) recoverPassword()
    }

    const resetInputs = () => {
        c.userVal('')
    }

    c.oninit = () => {

        // Cleans inputs stuff if component has been instantiated before
        resetInputs()

        if (!c.iDialog) {
            c.iDialog = P.getComponent({
                type: 'CGenericDlg',
                message: c.dialogMessage,
                visible: c.dialogVisible,
                onCloseDlgHook: () => {
                    if (c.iDialog.mode() === 'info') {
                        c.sm.action('backToLogin')
                    } else {
                        resetInputs()
                        inputFocus()
                    }
                }
            })
        }

    }

    c.showSpinner = () => CT.isLoading() ? m('.loader-holder', m('.loader')) : ''

    c.drawDialog = () => m(c.iDialog)

    c.drawRecovery = () => {
        return m('.login-form', {id: 'form-recovery'}, [

            m('.login-form-header', {}, [
                m('.login-logo', m('img', {className: 'brand-image', src: c.srcLogo})),
                m('.login_label.login_title', {}, i18n.t('Recuperar la contraseña'))
            ]),

            m('.login-form-body', [

                m('.login-group', [
                    m('label.login_label', i18n.t('Usuario')),
                    m(`input.input-login`, {
                        id: 'user-name',
                        type: 'text',
                        oncreate: (vnode) => {
                            domInputUser = vnode.dom
                            inputFocus()
                        },
                        onkeydown: onEnter,
                        oninput: m.withAttr('value', c.userVal),
                        value: c.userVal
                    })
                ]),

                c.showSupervisorOnLogin() ? m('.login-group.with-child.flex-row', [

                    // Padding group
                    m('.login-group', {style: {flexBasis: '70%'}}),

                    m('.login-group.flex-row', [
                        m('label.login_label', {style: {flexBasis: '70%'}}, i18n.t('Supervisor')),
                        m('.checkbox', {}, [
                            m('input', {
                                id: 'chk-supervisor',
                                type: 'checkbox',
                                onchange: m.withAttr('checked', c.supervisorVal),
                                oncreate: (vnode) => vnode.dom.checked = c.supervisorVal()
                            }),
                            m('label', {for: 'chk-supervisor'})
                        ])
                    ])
                ]) : ''


            ]),

            m('.login-form-footer', {}, [
                m('button.btn-medium.btn-brand', {
                    id: 'btn-submit',
                    onclick: () => {
                        recoverPassword()
                        return false
                    }}, i18n.t('Enviar')),

                m('button.btn-medium.btn-brand', {
                    id: 'btn-cancel',
                    onclick: () => {
                        c.sm.action('backToLogin')
                        return false
                    }}, i18n.t('Cancelar'))

            ])
        ])

    }

    c.tinyForm = () => !(c.showSupervisorOnLogin())

    c.view = () => {
        return [
            c.drawDialog(),
            c.showSpinner(),
            m('.login-wrapper', [
                m('.login-header'),
                m('.login-body', {style: {maxHeight: c.tinyForm() ? '300px' : '355px'}}, [
                    c.drawRecovery()
                ]),
                m('.login-footer', {style: {height: '25%'}}, [
                    m('.copyright-footer', CT.copyright())
                ])
            ])
        ]
    }

    return c
}
