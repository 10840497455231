/*global i18n */
import m from 'mithril'
import i18n from 'i18next'
import moment from 'moment'
import * as R from 'ramda'
import CT from "../CT";

let _langs, //available languages
    _appLang = "es-ES", //app language (as informed by /index/
    _queryIndexResult  //result data obtainer from /query/index (contains some flags required by login app)

const queryLanguages = () => {
    return new Promise((resolve, reject) => {
        if (window._global__TEST_MODE__) {

            import("../test/FakeData").then((FakeData) => {
                FakeData.doRequest({method: 'GET', url: '/api/languages/'})
                    .then((result) => {
                        _langs = result.languages
                        resolve()
                    }).catch(reject)
            })

        } else {
            m.request({method: 'GET', url: '/api/languages/'}).then((result) => {
                _langs = result.languages
                resolve()
            }).catch(reject)
        }
    })
}

// Language determination from response header of /api/index/ request
// This value will be used only if no "langCode" has been provided to init() call
const extractHeaderParams = (xhr) => {
    try {
        _appLang = xhr.getResponseHeader("content-language")
        console.log("I18N: language from header response: " + _appLang)
    } catch (err) {
        console.log("I18N: WARNING: Cannot get language code from server")
    }
    return JSON.parse(xhr.responseText)
}


const queryIndex = () => {
    return new Promise((resolve, reject) => {
        //IMPORTANT: DO NOT USE "REQ.request", because 'extract' is required
        if (window._global__TEST_MODE__) {

            import("../test/FakeData").then((FakeData) => {
                FakeData.doRequest({method: 'GET', url: '/api/index/'})
                    .then((result) => {
                        _queryIndexResult = result
                        resolve()
                    }).catch(reject)
            })
        } else {
            m.request({method: 'GET', url: '/api/index/', extract: extractHeaderParams}).then((result) => {
                _queryIndexResult = result
                resolve()
            }).catch(reject)
        }

    })
}


const getSimpleLangCodeFromExtendedCode = function (lngCode) {
    if (lngCode && lngCode.length > 2) {
        return lngCode.substring(0, 2)
    }
    return lngCode
}

const missingKeyOpenChar  = () => CT.isProductionBuild() ? "" : "["
const missingKeyCloseChar = () => CT.isProductionBuild() ? "" : "]"

const initi18next = () => {
    return new Promise(function (resolve, reject) {

        if (window._global__TEST_MODE__) {
            import("../test/FakeData").then((FakeData) => {
                FakeData.doRequest({method: 'GET', url: 'api/languages/?lang=es-ES'})
                    .then((defaultResource) => {
                        let lng = getSimpleLangCodeFromExtendedCode(_appLang)
                        console.log("i18next: lng = " + lng)
                        let resources = {}
                        resources[lng] = {translation: defaultResource}
                        i18n.init({
                            fallbackLng: false,
                            lng,
                            resources,

                            // allow keys to be phrases having `:`, `.`
                            nsSeparator: false,
                            keySeparator: false,

                            parseMissingKeyHandler: (key) => {
                                return missingKeyOpenChar() + key + missingKeyCloseChar()
                            }
                        }, function (t) {
                            resolve()
                        })
                    }).catch(function (error) {
                    console.log("ERROR: i18N cannot load lang: " + _appLang)
                    reject()
                })
            })
        } else {
            m.request({method: "GET", url: '/api/languages/', data: {lang: _appLang}}).then((defaultResource) => {
                let lng = getSimpleLangCodeFromExtendedCode(_appLang)
                console.log("i18next: lng = " + lng)
                let resources = {}
                resources[lng] = {translation: defaultResource}
                i18n.init({
                    fallbackLng: false, //Amb 'false' evitem que intenti carregar el llenguatge 'dev'
                    lng,
                    resources,

                    // allow keys to be phrases having `:`, `.`
                    nsSeparator: false,
                    keySeparator: false,

                    parseMissingKeyHandler: (key) => {
                        //console.log("NO TRANSLATION FOR : " + key)
                        return missingKeyOpenChar() + key + missingKeyCloseChar() //The key surrounded by [...] will be shown (only in 'dev' mode), to signal a missing translation
                    }
                }, function (t) {
                    console.log("I18N: i18next has been initialized to: " + _appLang)
                    console.log("*** initi18next : defaultResource:", defaultResource["Horario"])
                    console.log("*** initi18next : " + i18n.t("Horario"))
                    resolve()
                })
            }).catch(function (error) {
                console.log("ERROR: i18N cannot load lang: " + _appLang)
                reject()
            })

        }

    })
}


/*
 *  Add extra locales labels. Mainly SpecDriver related.
 */
const extraLocalesi18next = () => {
    return new Promise((resolve, reject) => {
        const defaultFallbackLang = 'es'
        let deep = true, overwrite = true
        let lng = getSimpleLangCodeFromExtendedCode(_appLang)

        m.request({method: 'GET', url: `assets/locales/${lng}/locale.json`, background: true})
            .then((extraLocales) => {
                if (extraLocales) i18n.addResourceBundle(lng, 'translation', extraLocales, deep, overwrite)
                // console.log('bundle', i18n.getResourceBundle(lng, null))
                resolve()
            })
            .catch(() => {
                console.log(`%cThere is no locales file for current lang "${lng}". Loading fallback lang "${defaultFallbackLang}".`, 'color: orangered;')
                return m.request({method: 'GET', url: `assets/locales/${defaultFallbackLang}/locale.json`, background: true})
            })
            .then((customLocales) => {
                if (customLocales && Object.keys(customLocales).length > 0) i18n.addResourceBundle(lng, 'translation', customLocales, deep, overwrite)
                resolve()
            })
            .catch((err) => {
                console.error('Error default fallback EXTRA locales files.', err.code)
                resolve()
            })
    })
}

/*
 *  Optional locale replacer for custom options.
 */
const customLabelsi18next = () => {
    return new Promise((resolve, reject) => {
        let deep = true, overwrite = true
        let lng = getSimpleLangCodeFromExtendedCode(_appLang)

        m.request({method: 'GET', url: `assets/locales/custom/${lng}/locale.json`, background: true})
            .then((customLocales) => {
                if (customLocales && Object.keys(customLocales).length > 0) i18n.addResourceBundle(lng, 'translation', customLocales, deep, overwrite)
                // console.log('bundle', i18n.getResourceBundle(lng, null))
                resolve()
            })
            .catch(() => {
                // On this case, there is no fallback lang request due this data OVERWRITES, NOT APPENDS data .
                console.log(`%cThere is no custom file for current lang "${lng}".`, 'color: orangered;')
                resolve()
            })
    })
}

const initMomentjs = () => {
    return new Promise((resolve, reject) => {
        moment().format();
        console.log(_appLang)
        moment.locale(_appLang);
        console.log("moment.locale = " + moment.locale())
        resolve()
    })
}


//Before calling 'initi18next',  _appLang needs to be checked against '_langs' table (must be a key inside that table)
const setAppLang = (langCode) => {
    return new Promise((resolve, reject) => {

        if (langCode) _appLang = langCode //If a 'langCode' is provided, _appLang must set to this value

        if (_langs && _langs[_appLang]) {
            resolve()
        } else {
            _appLang = 'en-EN'
            if (_langs && _langs[_appLang]) {
                console.log("WARNING: language: " + _appLang + " not found. Switching language to " + _appLang)
                resolve()
            } else {
                _appLang = 'es-ES'
                console.log("WARNING: language: " + _appLang + " not found. Switching language to " + _appLang)
                resolve()
            }
        }
    })
}

const getAppLang = () => _appLang

const getAppLangSingle = () => getSimpleLangCodeFromExtendedCode(_appLang)

// Initialization of i18n. If a "langCode" is provided, this will be the language used. Otherwise, language will be determined
// from api/index/ headers
const init = (langCode) => {
    return new Promise((resolve, reject) => {
        queryLanguages()
            .then(queryIndex)
            .then(() => setAppLang(langCode))
            .then(initi18next)
            .then(extraLocalesi18next)
            .then(customLabelsi18next)
            .then(initMomentjs)
            .then(() => resolve(_queryIndexResult))
            .catch((err) => {
                console.log('err', err)
            })
    })
}

export default {
    init,
    getAppLang, // => "es_ES"
    getAppLangSingle, // => "es"
    definedLanguagesArray: () => { //Returns an array of objects {id:'en-GB', name:'English'} , each one corresponding to every property in '_langs'
        return R.map((key) => ({
            id: key,
            name: _langs[key]
        }))(R.keys(_langs))
    }
}
