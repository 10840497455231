import m from 'mithril'
import stream from 'mithril/stream'
import P from '../../procurer/procurer_common'
import CT from '../../src/CT'
import REQ from '../../src/REQ'
import * as R from 'ramda'
import i18n from 'i18next'
import IconMgr from '../../src/managers/IconMgr'
import moment from "moment"

export default (cfg) => {

    let domInput

    const foo = {action: () => null}       // Fake state machine

    const defaults = {
        sm: foo,
        srcLogo: IconMgr.getLogo('netTimeLogoLogIn'),

        oldPwd: stream(''),
        pwd: stream(''),
        pwd2: stream(''),

        showSupervisorOnLogin: stream(),
        supervisorVal: stream(false),

        settings: {},        // Global settings
        modeParams: {},      // Component display mode

        // Dialog related stuff
        iDialog: undefined,
        dialogMessage: stream(''),
        dialogVisible: stream(false),

        embedded: false,           // when embedded, it just notify actions but not change routes.
        notifyChannel: stream()
    }

    let c = P.mergeProperties(defaults, cfg)


    /*
     *   Validations before submit, based on mode and displayed inputs.
     */
    const isValidForm = () => {

        const VALIDITY_INTERVAL = 60  // Minutes added to request date param to check validity.

        let oldPwd = c.oldPwd()
        let pwd = c.pwd()
        let pwd2 = c.pwd2()
        let errMessage = ''

        // Check equality of retry password
        if (pwd !== pwd2) errMessage = i18n.t('La nueva contraseña no coincide.')

        // Check form inputs are filled
        if ((!c.displayUser() && R.isEmpty(oldPwd)) || R.isEmpty(pwd) || R.isEmpty(pwd2)) {
            errMessage = i18n.t('Faltan por rellenar campos en el formulario.')
        }

        // JDS - 11/01/2021 UTC check expiration
        if (c.modeParams.hasOwnProperty('date')) {
            let auxRequestDate = new Date(c.modeParams.date)
            let requestDate = moment.utc([auxRequestDate.getFullYear(), auxRequestDate.getMonth(), auxRequestDate.getDate(), auxRequestDate.getHours(), auxRequestDate.getMinutes(), 0])
            let dueDate = requestDate.clone().add(VALIDITY_INTERVAL, 'minutes')
            let now = moment.utc()
            if (dueDate.isBefore(now, 'minute')) errMessage = i18n.t('La petición de cambio de contraseña ha caducado')
        }

        if (!R.isEmpty(errMessage)) {
            c.iDialog.mode('error')
            c.iDialog.message(errMessage)
            c.iDialog.visible(true)
            return false
        }

        return true
    }


    /*
     *  Does recovery password request to server
     */
    const changePassword = () => {
        if (isValidForm()) {

            let formData = {oldPwd: c.oldPwd(), pwd: c.pwd(), pwd2: c.pwd2()}

            let data = R.mergeRight(c.modeParams, formData)

            if (c.showSupervisorOnLogin()) data.asUser = c.supervisorVal() === true ? 'true' : 'false'

            REQ.request({method: 'POST', url: '/api/changePassword/', data})
                .then((res) => {
                    if (res.message !== '') {
                        c.iDialog.mode((res.ok === true) ? 'info' : 'error')
                        c.iDialog.message(res.message)
                        c.iDialog.visible(true)
                    }
                })
                .catch((err) => {
                    console.error("ERROR ChangePassword: ", err)

                    if (!c.embedded) {
                        c.sm.action('error')
                    } else  {
                        c.iDialog.mode('error')
                        c.iDialog.message(err.message)
                        c.iDialog.visible(true)
                    }

                    // c.iDialog.mode('error')
                    // c.iDialog.message(err.message)
                    // c.iDialog.visible(true)
                })
        }
    }

    c.displayUser = () => (!R.isEmpty(c.modeParams) && c.modeParams.usr) ? c.modeParams.usr : ''

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    //  HyperScript functions
    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    const inputFocus = () => {
        setTimeout(() => {
            if (domInput) domInput.focus()
        }, CT.MS_DELAY_SET_FOCUS)
    }

    const onEnter = (e) => {
        if (e.keyCode === 13) changePassword()
    }

    c.oninit = () => {
        //  This will show or hide current password input
        if (R.isEmpty(c.modeParams)) c.modeParams.oldPwd = true

        // console.log('ChangePassword modeParams ', c.modeParams)

        if (!c.iDialog) c.iDialog = P.getComponent({
            type: 'CGenericDlg',
            message: c.dialogMessage,
            visible: c.dialogVisible,
            onCloseDlgHook: () => {
                if (c.iDialog.mode() === 'info') {   // Changed password successfully

                    if (c.embedded) {
                        c.notifyChannel({result: 'ok', action: 'close'})
                    } else {

                        let params = {
                            forcePasswordChange: false,
                            forcePasswordChangeParams: {}
                        }

                        c.sm.action('backToLogin', params)

                    }
                } else {                             // Retry again, dumb...
                    c.pwd('')
                    c.pwd2('')
                    inputFocus()
                }
            }
        })

        inputFocus()
    }

    c.drawDialog = () => m(c.iDialog)

    c.showSpinner = () => CT.isLoading() && !c.embedded ? m('.loader-holder', m('.loader')) : ''

    c.drawChangePassword = () => {
        return m('.login-form', {id: 'form-change'}, [

            m('.login-form-header', {}, [
                m('.login-logo', m('img', {className: 'brand-image', src: c.srcLogo})),
                m('.login_label.login_title', {}, i18n.t('Cambiar la contraseña'))
            ]),

            m('.login-form-body', {id: 'company-data'}, [

                m('.login-group.flex-row', {style: {justifyContent: 'flex-end'}}, [
                    m('label.login_label', {id: 'usr-input' , style: {flex: '1'}}, i18n.t('Usuario') + ':'),
                    m('b', {id: 'usr-name-b', style: {padding: '.4em', textAlign: 'end'}}, c.displayUser() || 'NO_USER')
                ]),

                c.modeParams.oldPwd ? m('.login-group', [
                    m('label.login_label', i18n.t('Contraseña actual')),
                    m(`input.input-login`, {
                        id: 'pwd-old',
                        type: 'password',
                        oncreate: (vnode) => {
                            if (c.modeParams.oldPwd) {
                                domInput = vnode.dom
                                inputFocus()
                            }
                        },
                        onkeydown: onEnter,
                        oninput: m.withAttr('value', c.oldPwd),
                        value: c.oldPwd,
                        autocomplete: 'new-password'
                    })
                ]) : '',

                m('.login-group', [
                    m('label.login_label', i18n.t('Nueva contraseña')),
                    m(`input.input-login`, {
                        id: 'pwd-nu',
                        type: 'password',
                        oncreate: (vnode) => {
                            if (!c.modeParams.oldPwd) {
                                domInput = vnode.dom
                                inputFocus()
                            }
                        },
                        onkeydown: onEnter,
                        oninput: m.withAttr('value', c.pwd),
                        value: c.pwd,
                        autocomplete: 'new-password'
                    })
                ]),

                m('.login-group', [
                    m('label.login_label', i18n.t('Repita la contraseña')),
                    m(`input.input-login`, {
                        id: 'pwd-nu2',
                        type: 'password',
                        onkeydown: onEnter,
                        oninput: m.withAttr('value', c.pwd2),
                        value: c.pwd2,
                        autocomplete: 'new-password'
                    })
                ]),


                c.showSupervisorOnLogin() ? m('.login-group.with-child.flex-row', [

                    // Padding group
                    m('.login-group', {style: {flexBasis: '70%'}}),

                    m('.login-group.flex-row', {}, [
                        m('label.login_label', {style: {flexBasis: '70%'}}, i18n.t('Supervisor')),
                        m('.checkbox', {}, [
                            m('input', {
                                id: 'chk-supervisor',
                                type: 'checkbox',
                                onchange: m.withAttr('checked', c.supervisorVal),
                                oncreate: (vnode) => vnode.dom.checked = c.supervisorVal()
                            }),
                            m('label', {for: 'chk-supervisor'})
                        ])
                    ])
                ]) : ''


            ]),

            m('.login-form-footer', {}, [
                m('button.btn-medium.btn-brand', {
                    id: 'btn-submit',
                    onclick: () => {
                        changePassword()
                        return false
                    }
                }, i18n.t('Enviar')),

                m('button.btn-medium.btn-brand', {
                    id: 'btn-cancel',
                    onclick: (e) => {
                        if (c.embedded) {
                            c.notifyChannel({result: 'ok', action: 'close'})
                        } else {
                            c.sm.action('backToLogin')
                        }
                        return false
                    }
                }, i18n.t('Cancelar'))
            ])
        ])
    }

    c.formHeight = () => {
        let height = 410
        if (c.modeParams.oldPwd) height += 60
        if (c.showSupervisorOnLogin()) height += 60
        return `${height}px`
    }


    c.view = () => {
        return [
            c.drawDialog(),
            c.showSpinner(),
            m('.login-wrapper', [
                m('.login-header'),
                m('.login-body', {style: {maxHeight: c.formHeight()}}, [
                    c.drawChangePassword()
                ]),
                m('.login-footer', {style: {height: '25%'}}, [
                    m('.copyright-footer', CT.copyright())
                ])
            ])
        ]
    }

    return c
}
