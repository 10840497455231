import m from 'mithril'
import stream from 'mithril/stream'
import P from '../../../procurer/procurer_common'
import license from '../licenseLib'
import i18n from 'i18next'

export default (cfg) => {

    const defaults = {
        debug: false,
        polling: stream(false),
        numRetries: stream(1000)
    }

    let c = P.mergeProperties(defaults, cfg)

    const waitForServer = () => {
        if (!c.debug) {

            console.log('Waiting for server response...')

            c.polling(true)
            c.updateClass()

            license.waitUntilUp(250, c.numRetries())
                .then(() => {
                    // CT.redirectToLogin()
                    location.href = '/'
                })
                .catch((err) => {
                    c.polling(false)
                    c.updateClass()
                    console.error('CError - waitUntilReboot', err)
                })
        }
    }

    c.noticeError = () => {
        return i18n.t('Información de netTime')
    }

    c.messageError = () => {
        return i18n.t('netTime ha perdido la conexión con el servidor.')
    }

    c.infoRetrying = () => {
        return c.polling() ? i18n.t('Conectando') + "...": i18n.t('Máximo número de intentos alcanzado')
    }

    c['info-msg'] = () => {
        return {
            className: c.polling() ? 'on-polling' : 'connecting-error'
        }
    }

    // ¬¬
    c.updateClass = () => {
        c['info-msg'].className = c.polling() ? 'on-polling' : 'connecting-error'
        m.redraw()
    }

    c.drawRetryButton = () => {
        return !c.polling() ? m('button.btn-error-retry.btn-large', {
            onclick: (e) => {
                e.preventDefault()
                e.stopImmediatePropagation()
                waitForServer()
            }
        }, i18n.t('Reintentar')) : ''
    }

    // Start polling on render component
    c.oncreate = () => {
        waitForServer()
    }

    c.view = P.customizedView('CError', c)

    return c
}
