import m       from 'mithril'
import stream  from 'mithril/stream'
import * as R  from 'ramda'
import i18n    from 'i18next'

import P       from '../../procurer/procurer_common'
import REQ     from '../../src/REQ'
import license from './licenseLib'
import IconMgr from '../../src/managers/IconMgr'
import CT      from '../../src/CT'

export default (cfg) => {

    const MODE = {OFFLINE: 0, ONLINE: 1}

    const STEPS = {
        DEACTIVATION: -1,
        COMPANY_INFO: 1,
        ENABLE_DEVICES: 2, // optional
        OFFLINE_CODE: 3,   // only if no reachable server
        DONE: 4,           // license updated
        REBOOT: 5          // waiting until service boot up
    }

    const defaults = {

        srcLogo: IconMgr.getLogo('netTimeLogoLogIn'),

        embedded: false,

        settings: {},
        mode: stream(),
        step: stream(),

        // CList related

        inactiveDevices: stream(),
        selectedDevices: stream(),

        notifyStmSource: stream(),
        notifyStmTarget: stream(),
        selectedItemStmSource: stream(),
        selectedItemStmTarget: stream(),

        activationHost: stream(''),

        forceReboot: false,

        rebooting: stream(false),

        // Form related

        inputValues: {
            company: stream(),
            companyKey: stream(),
            email: stream(),
            license: stream(),
            info: stream(),
            hardwareId: stream(),

            device: stream(),
            remoteKey: stream(),
            summaryData: stream(),
            version: stream(),

            deactivactionKey: stream()
        },

        // Modal message

        iDialog: undefined,
        message: {
            mode: stream('error'),
            content: stream(),
            visible: stream(false)
        }

    }

    let c = P.mergeProperties(defaults, cfg)

    /*
     *  Centralized input attribute provider.
     */
    const inputAttrs = (type) => {

        let attrs = {className: 'input-login', type: 'text', autocomplete: 'off'}

        switch (type) {
            case 'comp':
                attrs.id = 'company'
                attrs.key =  'company'
                attrs.oninput = m.withAttr('value', c.inputValues.company)
                attrs.value = c.inputValues.company()
                break
            case 'compKey':
                attrs.id = 'company-key'
                attrs.key = 'company-key'
                attrs.oninput = m.withAttr('value', c.inputValues.companyKey)
                attrs.value = c.inputValues.companyKey()
                break
            case 'email':
                attrs.id = 'email'
                attrs.key = 'email'
                attrs.oninput = m.withAttr('value', c.inputValues.email)
                attrs.value = c.inputValues.email()
                break
            case 'license':
                attrs.id = 'license'
                attrs.key = 'license'
                attrs.oninput = m.withAttr('value', c.inputValues.license)
                attrs.value = c.inputValues.license()
                break
            case 'devices':
                attrs.id = 'device'
                attrs.key = 'device'
                attrs.oninput = m.withAttr('value', c.inputValues.device)
                attrs.value = c.inputValues.device()
                break
            case 'remoteKey':
                attrs = {}
                attrs.id = 'remote-key'
                attrs.key = 'remote-key'
                attrs.oninput = m.withAttr('value', c.inputValues.remoteKey)
                attrs.value = c.inputValues.remoteKey
                attrs.readonly = false
                break
            case 'summaryData':
                attrs = {}
                attrs.id = 'summary-data'
                attrs.key = 'summary-data'
                attrs.oninput = m.withAttr('value', c.inputValues.summaryData)
                attrs.value = c.inputValues.summaryData
                attrs.readonly = true
                attrs.rows = 7
                break
        }

        return attrs
    }


    /// DEACTIVATION ///////////////////////////////////////////////////////////////////////////////////////////////////

    const formDeactivation = () => {
        return m('.login-form', {id: 'form-deactivation'}, [
            m('.login-form-header', {}, [
                m('.login-logo', m('img.brand-image', {src: c.srcLogo})),
                m('.login_label.login_title', i18n.t('Desactivar Licencia'))
            ]),
            m('.login-form-body', [
                m('.login-group', [
                    m('label.login_label', i18n.t('Esta acción deshabilitará completamente el servicio de NetTime. ¿Está usted seguro de continuar?'))
                ])
            ]),
            m('.login-form-footer', [
                m('button.btn-medium.btn-brand', {onclick: deactivateLicense}, i18n.t('Confirmar'))
            ])
        ])
    }

    /*
     *  Deactivate server license
     */
    const deactivateLicense = (evt) => {
        evt.preventDefault()
        evt.stopImmediatePropagation()

        updateParamsLicenseLib()

        license.deactivation()
            .then((result) => {

                if (result.ok) {
                    c.message.mode('info')
                    c.iDialog.onCloseDlgHook = () => {
                        if (result.reboot) reboot()
                        m.redraw()
                    }
                } else {
                    c.message.mode('error')
                }

                if (result.data) {
                    c.message.content(result.data)
                    c.message.visible(true)
                    m.redraw()
                }
            })
            .catch((err) => {
                console.log('MAIN deactivateLicense err', err)
                c.message.mode('error')
                c.message.content(err.data)
                c.message.visible(true)
            })
    }


    /// ACTIVATION /////////////////////////////////////////////////////////////////////////////////////////////////////

    const updateParamsLicenseLib = () => {
        license.updateData({
            company: c.inputValues.company(),
            companyKey: c.inputValues.companyKey(),
            email: c.inputValues.email(),
            license: c.inputValues.license(),
            info: c.inputValues.info(),
            hardwareId: c.inputValues.hardwareId(),
            version: c.inputValues.version(),
            activationHost: c.activationHost(),
            devices: c.selectedDevices(),

            reboot: c.forceReboot
        })
    }

    const isValidForm = () => {
        if (!c.inputValues.company()) return false
        if (!c.inputValues.companyKey()) return false
        if (!c.inputValues.email()) return false
        if (!c.inputValues.license()) return false
        return true
    }

    const btnActivation = (evt) => {
        evt.preventDefault()
        evt.stopImmediatePropagation()
        if (isValidForm()) {
            stepForward()
        } else {
            c.message.mode('error')
            c.message.content(i18n.t('Debe rellenar correctamente todos los campos del formulario.'))
            c.message.visible(true)
        }
    }

    const formActivation = () => {
        return m('.login-form', {id: 'form-activation'}, [
            m('.login-form-header', [
                m('.login-logo', m('img.brand-image', {src: c.srcLogo})),
                m('.login_label.login_title', i18n.t('Licencia'))
            ]),
            m('.login-form-body', [
                m('.login-group', [
                    m('label.login_label', i18n.t('Empresa')),
                    m('input', inputAttrs('comp'))
                ]),
                m('.login-group', [
                    m('label.login_label', i18n.t('CIF')),
                    m('input', inputAttrs('compKey'))
                ]),
                m('.login-group', [
                    m('label.login_label', i18n.t('E-mail de contacto')),
                    m('input', inputAttrs('email'))
                ]),
                m('.login-group', [
                    m('label.login_label', i18n.t('Nº de licencia')),
                    m('input', inputAttrs('license'))
                ])
            ]),
            m('.login-form-footer', {}, [
                m('button.btn-medium.btn-brand', {onclick: btnActivation}, i18n.t('Continuar'))
            ])
        ])

    }

    /// DEVICES ////////////////////////////////////////////////////////////////////////////////////////////////////////

    /*
     *  Creation of the two CList component required for device picker and add required hooks
     */
    const createDevicePicker = () => {
        return new Promise((resolve, reject) => {
            if (!c.iSourceList) {
                c.iSourceList = P.getComponent({
                    type: 'CCommonList',
                    itemsType: 'CListDevicePicker',
                    itemsClassName: "s-row g-win-item",
                    selectedItemStm: c.selectedItemStmSource
                })

                c.notifyStmSource.map((notif) => {
                    switch (notif.name) {
                        case 'act_layer_item_selected':
                            c.selectedItemStmSource(notif.data.id)
                            break
                        case 'act_layer_item_double_clicked':
                            c.selectedItemStmSource(notif.data.id)
                            transferItem()
                            break
                    }
                })
            }

            if (!c.iTargetList) {
                c.iTargetList = P.getComponent({
                    type: 'CCommonList',
                    itemsType: 'CListDevicePicker',
                    itemsClassName: "s-row g-win-item",
                    selectedItemStm: c.selectedItemStmTarget
                })
            }

            resolve()
        })
    }

    const transferItem = () => {
        let deviceId = c.selectedItemStmSource()
        let device = c.inactiveDevices().find((device) => (device.id === deviceId))

        if (device !== undefined) {
            let currentDevices = c.selectedDevices()
            if (!currentDevices) currentDevices = []
            currentDevices.push(Object.assign({}, device))
            c.selectedDevices(currentDevices)
            updateTargetList()
            updateSourceList()
        } else {
            c.message.mode('info')
            c.message.content(i18n.t('Debe seleccionar un terminal pendiente de activación.'))
            c.message.visible(true)
        }

        c.selectedItemStmSource(undefined)
    }

    const updateSourceList = () => {
        let sourceDevices = c.inactiveDevices().filter((device) => {
            return !c.selectedDevices().some((selected) => selected.id === device.id)
        })
        c.iSourceList.createItems(R.map((item) => R.mergeRight(item, {notifyStm: c.notifyStmSource}))(sourceDevices))
    }


    const updateTargetList = () => {
        let targetDevices = c.selectedDevices().map((device) => {
            device.txtClass = 's-col s-col-8 calendar-list-item-txt'
            device.extensionClass = 's-col s-col-2 txt-right'
            device.drawExtension = () => m('div', {
                style: {
                    flexBasis: '20%',
                    justifyContent: 'center'
                },
            }, [
                m('img.icon-btn.dev-act-list.centered', {
                    style: 'width:18px;',
                    src: IconMgr.getIcon('trashClosed'),
                    onclick: (e) => {
                        e.stopImmediatePropagation()
                        e.preventDefault()
                        removeDeviceFromSelection(device.id)
                    }
                })
            ])
            return device
        })

        c.iTargetList.createItems(R.map((item) => R.mergeRight(item, {notifyStm: c.notifyStmTarget}))(targetDevices))
    }

    const removeDeviceFromSelection = (id) => {
        let current = c.selectedDevices()
        let pos = current.findIndex((device) => device.id === id)
        if (pos >= 0) {
            current.splice(pos, 1)
            c.selectedDevices(current)
            updateTargetList()
            updateSourceList()
        }
    }

    const btnAddDevice = (evt) => {
        evt.preventDefault()
        evt.stopImmediatePropagation()

        let serial = c.inputValues.device() || ''

        let isValid = !!(!isNaN(parseInt(serial)) && serial)

        if (isValid) {
            let nuDevice = {
                id: `${serial}`,
                SerialNumber: `${serial}`
            }

            let currentDevices = c.selectedDevices()
            if (!currentDevices) currentDevices = []

            let existInTarget = currentDevices.find((device) => (device.id === nuDevice.id))
            let existInSource = c.inactiveDevices().find((device) => (device.id === nuDevice.id))
            let alreadyExists = (existInTarget || existInSource)
            if (!alreadyExists) {
                currentDevices.push(nuDevice)
                c.selectedDevices(currentDevices)
                updateTargetList()
            } else {
                c.message.mode('error')
                c.message.content(i18n.t('Dispositivo ya existente.'))
                c.message.visible(true)
            }
        } else {
            c.message.mode('info')
            c.message.content(i18n.t('Número de serie incorrecto.'))
            c.message.visible(true)
        }

        c.inputValues.device('')
    }

    const btnBack = (evt) => {
        evt.preventDefault()
        evt.stopImmediatePropagation()
        stepBackward()
    }

    const btnNext = (evt) => {
        evt.preventDefault()
        evt.stopImmediatePropagation()
        stepForward()
    }

    const formEnableDevices = () => {
        return m('.login-form', {id: 'form-devices'}, [
            m('.login-form-header', [
                m('.login-logo', m('img.brand-image', {src: c.srcLogo})),
                m('.login_label.login_title', i18n.t('Activar de Terminales'))
            ]),
            m('.login-form-body', [
                m('.login-group.flex-row', {id: 'device'}, [
                    m('label.login_label', i18n.t('Serial Number')),
                    m('input',  inputAttrs('devices')),
                    m('button.btn-icon', {onclick: btnAddDevice}, m('img', {style: {width: '18px'}, src: IconMgr.getIcon('addItem')}))
                ]),
                m('.login-group.flex-row', [
                    m('.devices-activation', [
                        m(".dev-act-title", i18n.t('Pendientes de activar')),
                        m(c.iSourceList)
                    ]),
                    m('.centered.m_auto', m('button.btn-icon', {onclick: () => transferItem()}, m('img', {src: IconMgr.getIcon('transferButton')}))),
                    m('.devices-activation', [
                        m('.dev-act-title', i18n.t('Seleccionados')),
                        m(c.iTargetList)
                    ])
                ])
            ]),
            m('.login-form-footer', {}, [
                m('button.btn-medium.btn-brand', {onclick: btnBack}, i18n.t('Volver')),
                m('button.btn-medium.btn-brand', {onclick: btnNext}, i18n.t('Continuar'))
            ])
        ])

    }

    /// OFFLINE CODE ///////////////////////////////////////////////////////////////////////////////////////////////////

    const updateActivationSummay = () => {
        let summary = ''
        summary += `${i18n.t('Empresa')}: ${c.inputValues.company()} \n`
        summary += `${i18n.t('CIF')}: ${c.inputValues.companyKey()} \n`
        summary += `${i18n.t('E-mail')}: ${c.inputValues.email()} \n`
        summary += `${i18n.t('Número de licencia')}: ${c.inputValues.license().toUpperCase()} \n`
        // summary += `${i18n.t('Recibir e-mail')}: ${c.inputValues.info() ? i18n.t('Sí') : i18n.t('No')} \n`
        summary += `HardwareID: ${c.inputValues.hardwareId() || ''} \n`

        if (c.selectedDevices().length > 0) {
            let description = c.selectedDevices().reduce((accum, term, indice, vector) => {
                let separator = ((vector.length - 1) === indice) ? '.' : ','
                accum += term.id + separator
                return accum
            }, '')
            summary += `${i18n.t('Terminales')}: ${description} \n`
        }
        c.inputValues.summaryData(summary)
    }


    const codeActivation = () => {

        return m('.login-form', {id: 'form-offline-activation'}, [
            m('.login-form-header', {}, [
                m('.login-logo', m('img.brand-image', {src: c.srcLogo})),
                m('.login_label.login_title', i18n.t('Activación OffLine'))
            ]),
            m('.login-form-body', [
                m('.login-group', [
                    m('label.login_label', i18n.t('Datos de activación telefónica')),
                    m('textarea.area-text', inputAttrs('summaryData'))
                ]),
                m('.login-group', [
                    m('label.login_label', i18n.t('Introduzca la clave de activación')),
                    m('textarea.area-text', inputAttrs('remoteKey'))
                ])
            ]),
            m('.login-form-footer', [
                m('button.btn-medium.btn-brand', {
                    onclick: (evt) => {
                        evt.preventDefault()
                        evt.stopImmediatePropagation()
                        stepBackward()
                    }
                }, i18n.t('Volver')),
                m('button.btn-medium.btn-brand', {
                    onclick: (evt) => {
                        evt.preventDefault()
                        evt.stopImmediatePropagation()
                        offlineActivation()
                    }
                }, i18n.t('Continuar'))
            ])
        ])

    }

    /// DONE ///////////////////////////////////////////////////////////////////////////////////////////////////////////

    const showSpinnerWhileBoot = () => {
        return [
            m('.s-row.login_pos_label', [
                m('.login_label.login_title', {}, i18n.t('Reiniciando servicio'))
            ]),

            // Spinner
            m('.s-row.login_pos_label', {
                style: {
                    position: 'relative',
                    minHeight: '10em',
                    justifyContent: 'center'
                }
            }, m('.loader', {
                style: {
                    position: 'relative',
                    transform: 'none',
                    top: 0,
                    left: 0
                }
            }))]
    }

    const activationFinished = () => {
        return m('.login-form', {id: 'form-activation-done'}, [
            m('.login-form-header', [
                m('img.icon-btn', {style: {display: 'none'},src: IconMgr.getIcon('denegar')}), // Fix in case error to request image
                m('.login-logo', m('img.brand-image', {src: c.srcLogo})),
                m('.login_label.login_title', i18n.t('Proceso finalizado'))
            ]),
            m('.login-form-body', [
                m('.login-group.flex-row', [
                    m('img.icon-btn', {
                        src: (c.message.mode() === 'error') ? IconMgr.getIcon('denegar') : IconMgr.getIcon('aceptar')
                    }),
                    m('label.login_label', {style: {flex: 1}}, c.message.content() || i18n.t('Reiniciando sistema'))
                ]),
                c.rebooting() ? m('.login-group', showSpinnerWhileBoot()) : [
                    m('.login-group.flex-row', [
                        m('img.icon-btn', {src: IconMgr.getIcon('denegar')}),
                        m('label.login_label', {style: {flex: 1}}, i18n.t('Reinicio de servidor, no detectado'))
                    ]),
                    m('.login-group', [
                        m('button.btn-medium.btn-brand', {
                            onclick: (e) => {
                                e.stopImmediatePropagation()
                                e.preventDefault()
                                location.href = 'login.html'
                            }
                        }, i18n.t('Login'))
                    ])

                ]
            ])
        ])
    }

    const reboot = () => {
        CT.isUpdateLicense(true)
        c.rebooting(true)
        license.waitUntilReboot()
            .then(() => {
                setTimeout(() => {
                    CT.isUpdateLicense(false)
                    c.rebooting(false)
                    if (c.sm) {
                        c.sm.action('backToLogin')
                    } else {
                        location.href = '/starting.html'
                    }
                }, 3000)
            })
            .catch((err) => {
                console.error('Rebooting', err)
                c.rebooting(false)
                CT.isUpdateLicense(false)
                m.redraw()
            })
    }

    /*
     *   Main call to license server
     */
    const onlineActivation = () => {
        updateParamsLicenseLib()

        license.license.reboot(true)

        license.onlineActivation({reboot: true})
            .then((result) => {
                if (result.ok) {
                    c.message.mode('info')
                    c.message.content('')
                    if (result.reboot) reboot()
                    stepForward()
                } else {
                    // Modal
                    c.message.content(result.data || 'Error')
                    c.message.mode('error')
                    c.message.visible(true)
                }
                m.redraw()
            })
            .catch((err) => {
                c.message.mode('error')
                c.message.content(err.data)
                c.message.visible(true)
                m.redraw()
            })
    }


    /*
     *   Main call to license server
     */
    const offlineActivation = () => {
        let key = c.inputValues.remoteKey()

        if (key) {
            updateParamsLicenseLib()

            license.offlineActivation(key)
                .then((result) => {
                    c.message.content(result.data)

                    if (result.ok) {
                        // No modal
                        c.message.mode('info')
                        stepForward()
                        if (result.reboot) reboot()
                    } else {
                        // Modal
                        c.message.mode('error')
                        c.message.visible(true)
                    }
                    m.redraw()
                })
                .catch((err) => {
                    c.message.mode('error')
                    c.message.content(err.data)
                    c.message.visible(true)
                    m.redraw()
                })
        } else {
            c.message.mode('error')
            c.message.content(i18n.t('Debe proporcionar una clave de licencia válida para continuar.'))
            c.message.visible(true)
        }
    }

    const summary = () => {
        return m('.login-form', {id: 'form-summary'}, [
            m('.login-form-header', {}, [
                m('.login-logo', m('img.brand-image', {src: c.srcLogo})),
                m('.login_label.login_title', {}, i18n.t('Completar Activación'))
            ]),
            m('.login-form-body', [
                m('.login-group', [
                    m('label.login_label', i18n.t('Datos de activación')),
                    m(`textarea.area-text`, inputAttrs('summaryData'))
                ])
            ]),
            m('.login-form-footer', {}, [
                m('button.btn-medium.btn-brand', {
                    onclick: (evt) => {
                        evt.preventDefault()
                        evt.stopImmediatePropagation()
                        stepBackward()
                    }
                }, i18n.t('Volver')),
                m('button.btn-medium.btn-brand', {
                    onclick: (evt) => {
                        evt.preventDefault()
                        evt.stopImmediatePropagation()
                        onlineActivation()
                    }
                }, i18n.t('Finalizar'))
            ])
        ])
    }


    /// GLOBAL /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const resetData = () => {
        return new Promise((resolve) => {
            c.inputValues.company(undefined)
            c.inputValues.companyKey(undefined)
            c.inputValues.email(undefined)
            c.inputValues.license(undefined)
            c.inputValues.info(undefined)
            c.inputValues.remoteKey('')
            c.inputValues.summaryData('')
            c.inactiveDevices([])
            c.selectedDevices([])
            resolve()
        })
    }

    const loadCurrentLicenseData = () => {
        return new Promise((resolve, reject) => {

            const options = {method: 'GET', url: '/api/mgmt/license'}

            REQ.request(options)
                .then((data) => {
                    let fixedDevices = []
                    if (data.Terms && data.Terms.length > 0) {
                        fixedDevices = data.Terms.map((term) => {
                            term.id = term.SerialNumber
                            return term
                        })
                    }

                    c.inputValues.company(data.Company)
                    c.inputValues.companyKey(data.Cif)
                    c.inputValues.email(data.Email)
                    c.inputValues.license(data.License)
                    c.inputValues.info(data.Mailing)
                    c.inputValues.hardwareId(data.Hwid)
                    c.inputValues.version(data.version)

                    c.inactiveDevices(fixedDevices)
                    c.selectedDevices([])
                    c.activationHost(data.Host)

                    resolve()
                })
                .catch((err) => {
                    console.error('CLicense - loadCurrentLicenseData', err)
                    resetData()
                    resolve()
                })
        })
    }


    const checkLicenseServerIsReachable = () => {
        return new Promise((resolve, reject) => {
            license.serverReachable(c.activationHost() + '?WSDL')
                .then((isServerReachable) => {
                    if (isServerReachable) {
                        c.mode(MODE.ONLINE)
                    } else {
                        c.mode(MODE.OFFLINE)
                    }
                    resolve(isServerReachable)
                })
                .catch((err) => {
                    console.log('CLicense.isServerReachable? false', err)
                    resolve(false)
                })
        })

    }


    const stepForward = () => {
        let nextStep
        let mode = c.mode()
        let step = c.step()

        if (!step) nextStep = STEPS.COMPANY_INFO

        if ((step === STEPS.DEACTIVATION) || (step === STEPS.OFFLINE_CODE)) nextStep = STEPS.DONE

        if (step === STEPS.COMPANY_INFO) {
            c.selectedDevices([])
            updateTargetList()
            nextStep = STEPS.ENABLE_DEVICES
        }

        if (step === STEPS.ENABLE_DEVICES) {
            updateActivationSummay()
            nextStep = (mode === MODE.ONLINE) ? STEPS.DONE : STEPS.OFFLINE_CODE
        }

        if (step === STEPS.DONE || step === STEPS.OFFLINE_CODE) {
            nextStep = STEPS.REBOOT
        }

        c.step(nextStep)
    }


    const stepBackward = () => {
        let nextStep
        let step = c.step()

        if (!step) nextStep = STEPS.COMPANY_INFO

        if (step === STEPS.ENABLE_DEVICES) {
            nextStep = STEPS.COMPANY_INFO
        } else if (step === STEPS.OFFLINE_CODE) {
            nextStep = STEPS.ENABLE_DEVICES
        } else if (step === STEPS.DONE) {
            nextStep = STEPS.ENABLE_DEVICES
        } else {
            nextStep = step
        }

        c.step(nextStep)
    }

    const drawForm = () => {
        switch (c.step()) {
            case STEPS.DEACTIVATION:
                return formDeactivation()
            case STEPS.COMPANY_INFO:
                return formActivation()
            case STEPS.ENABLE_DEVICES:
                return formEnableDevices()
            case STEPS.OFFLINE_CODE:
                return codeActivation()
            case STEPS.DONE:
                return summary()
            case STEPS.REBOOT:
                return activationFinished()
            default:
                return formActivation()
        }
    }


    c.drawDialog = () => {
        if (!c.iDialog) c.iDialog = P.getComponent({
            type: 'CGenericDlg',
            mode: c.message.mode,
            message: c.message.content,
            visible: c.message.visible
        })

        return m(c.iDialog)
    }

    c.oninit = () => {
        resetData()
            .then(loadCurrentLicenseData)
            .then(checkLicenseServerIsReachable)
            .then(createDevicePicker)
            .then(() => {
                updateSourceList()
                if (!c.step()) stepForward()
            })
            .catch((err) => {
                console.error('CLicense - oninit', err)
            })
    }

    c.formHeight = () => {
        let height = 510
        switch (c.step()) {
            case STEPS.DEACTIVATION:
                height = 300
                break
            case STEPS.DONE:
                height = 410
                break
            case STEPS.ENABLE_DEVICES:
            case STEPS.COMPANY_INFO:
                height = 510
                break
            case STEPS.OFFLINE_CODE:
            case STEPS.REBOOT:
            default:
                height = 510
        }

        return `${height}px`
    }

    c.view = () => {
        return [
            c.drawDialog(),
            m('.login-wrapper', [
                m('.login-header'),
                m('.login-body', {style: {maxHeight: c.formHeight()}}, drawForm()),
                m('.login-footer', {style: {height: '25%'}}, m('.copyright-footer', CT.copyright()))
            ])
        ]
    }

    return c

}

