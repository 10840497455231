import m from 'mithril'
import stream from 'mithril/stream'
import * as R from 'ramda'
import CT from '../../src/CT'
import I18N from '../../src/I18N/I18N'
import REQ from '../../src/REQ'

const RETRY_DELAY = 100
const RETRY_TIMES = 250
const identity = foo => foo

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  License data management
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

let template = {ok: true, data: '', reboot: false}

let license = {
    company: stream(),
    companyKey: stream(),
    email: stream(),
    license: stream(),
    info: stream(),
    hardwareId: stream(),
    version: stream(),

    activationHost: stream(),
    remoteKey: stream(),
    deactivationKey: stream(),

    devices: stream([]),
    inactiveDevices: stream([]),

    reboot: stream()
}

/*
 *  Clear license related data
 */
const resetData = () => {
    license.company(undefined)
    license.companyKey(undefined)
    license.email(undefined)
    license.license(undefined)
    license.info(undefined)
    license.hardwareId(undefined)
    license.version(undefined)
    license.activationHost(undefined)
    license.remoteKey(undefined)
    license.deactivationKey(undefined)
    license.devices([])
}

/*
 *  Merges current license data
 */
const updateData = (data) => {
    if (data.hasOwnProperty('company')) license.company(data.company)
    if (data.hasOwnProperty('companyKey')) license.companyKey(data.companyKey)
    if (data.hasOwnProperty('email')) license.email(data.email)
    if (data.hasOwnProperty('license')) license.license(data.license.toUpperCase())
    if (data.hasOwnProperty('info')) license.info(data.info)
    if (data.hasOwnProperty('hardwareId')) license.hardwareId(data.hardwareId)
    if (data.hasOwnProperty('version')) license.version(data.version)
    if (data.hasOwnProperty('activationHost')) license.activationHost(data.activationHost)
    if (data.hasOwnProperty('remoteKey')) license.remoteKey(data.remoteKey)
    if (data.hasOwnProperty('deactivationKey')) license.deactivationKey(data.deactivationKey)
    if (data.hasOwnProperty('devices')) license.devices(data.devices)

    if (data.hasOwnProperty('reboot')) license.reboot(data.reboot)
}

/*
 *  Returns SOAP content for deactivation action with current license data
 */
const deactivationXML = () => {
    let content = ''
    content += `<act:DesactivationKey>${license.deactivationKey()}</act:DesactivationKey>`
    content += `<act:Language>${I18N.getAppLang()}</act:Language>`
    return `<soap:Envelope xmlns:soap="http://www.w3.org/2003/05/soap-envelope" xmlns:act="http://www.specsa.com/activation/"><soap:Header/><soap:Body><act:DesactivarLicencia>${content}</act:DesactivarLicencia></soap:Body></soap:Envelope>`
}

/*
 *  Returns SOAP content for activation action with current license data
 */
const activationXML = () => {
    let content = ''
    content += `<act:NumCD>${license.license()}</act:NumCD>`
    content += `<act:CIF>${license.companyKey()}</act:CIF>`
    content += `<act:Email>${license.email()}</act:Email>`
    if (license.version()) content += `<act:Version>${license.version()}</act:Version>`
    content += `<act:Empresa>${license.company()}</act:Empresa>`
    content += `<act:HWIDClient>${license.hardwareId()}</act:HWIDClient>`
    content += `<act:Mailing>${license.info()}</act:Mailing>`

    let devices = license.devices() || []
    if (devices.length > 0) {
        content += `<act:IdTerminales>`
        devices.forEach((device) => {
            content += `<act:string>${device.id}</act:string>`
        })
        content += `</act:IdTerminales>`
    }

    content += `<act:Language>${I18N.getAppLang()}</act:Language>`

    return `<soap:Envelope xmlns:soap="http://www.w3.org/2003/05/soap-envelope" xmlns:act="http://www.specsa.com/activation/"><soap:Header/><soap:Body><act:ActivarLicencia>${content}</act:ActivarLicencia></soap:Body></soap:Envelope>`
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  License actions
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


///  Deactivation  /////////////////////////////////////////////////////////////////////////////////////////////////////

const parseMessage = (message) => message.replace(/\\n/g, ' ')

const parseXML = (data, searchedNode) => {
    try {
        let parser = new DOMParser();
        let XML = parser.parseFromString(data, 'text/xml')

        // Check for errors
        let errorNode = XML.getElementsByTagName('soap:Fault')[0]
        if (errorNode) {
            let errorMsg = XML.getElementsByTagName('faultstring')[0]
            if (!errorMsg) errorMsg = XML.getElementsByTagName('soap:Text')[0]
            let message = (errorMsg) ? errorMsg.childNodes[0].nodeValue : 'Unknown error'
            return {ok: false, data: parseMessage(message)}
        } else {
            let value
            if (searchedNode) {
                let node = XML.getElementsByTagName(searchedNode)[0]
                let nodeData = node.childNodes[0]
                value = nodeData.nodeValue

                let pos = value.indexOf('|')  // Code error inside a non error response...
                if (pos >= 0) {
                    let code = parseInt(value.substring(0, pos))
                    value = value.substring(pos + 1)
                    if (code < 0) return {ok: false, data: parseMessage(value)}
                }
            }
            return {ok: true, data: parseMessage(value)}
        }
    } catch (err) {
        return {ok: false, data: parseMessage(err.msg)}
    }
}

const deactivation = () => {
    return new Promise((resolve, reject) => {
        getDeactivationKey()
            .then(sendDeactivationKey)
            .then(resolve)
            .catch(reject)
    })
}


const getDeactivationKey = () => {
    return new Promise((resolve, reject) => {

        let result = R.clone(template)

        m.request({method: 'GET', url: '/api/mgmt/deactivate', background: true})
            .then((response) => {
                license.deactivationKey(response.desCode)
                result.ok = response.ok
                result.data = response.desCode
                if (result.ok) {
                    resolve(result)
                } else {
                    reject(result)
                }
            })
            .catch((err) => {
                result.ok = false
                result.data = err.data || err.msg
                reject(result)
            })
    })
}

const sendDeactivationKey = () => {
    return new Promise((resolve, reject) => {
        let result = R.clone(template)

        let options = {
            method: 'POST',
            url: license.activationHost(),
            headers: {'Content-Type': 'text/xml'},
            serialize: identity,
            deserialize: identity,
            data: deactivationXML()
        }

        m.request(options)
            .then((res) => {
                let response = parseXML(res, 'DesactivarLicenciaResult')
                result.ok = response.ok
                result.data = response.data
                if (result.ok) {
                    resolve(result)
                } else {
                    reject(result)
                }
            })
            .catch((err) => {
                let error = parseXML(err.message)
                result.ok = false
                result.data = error.data || err.msg
                reject(result)
            })

    })

}


///  Activation  /////////////////////////////////////////////////////////////////////////////////////////////////////

const onlineActivation = (params) => {
    return new Promise((resolve, reject) => {
        if (params && params.hasOwnProperty('reboot')) license.reboot(params.reboot)
        getActivationKey()
            .then(sendActivationKey)
            .then(resolve)
            .catch(reject)
    })
}

const offlineActivation = (key) => {
    return new Promise((resolve, reject) => {
        if (key) license.remoteKey(key)
        sendActivationKey().then(resolve).catch(reject)
    })
}


const getActivationKey = () => {
    return new Promise((resolve, reject) => {

        let result = R.clone(template)

        let options = {
            method: 'POST',
            url: license.activationHost(),
            headers: {'Content-Type': 'text/xml'},
            serialize: identity,
            deserialize: identity,
            data: activationXML(),
        }

        m.request(options)
            .then((res) => {
                let response = parseXML(res, 'ActivarLicenciaResult')
                if (response.ok) license.remoteKey(response.data)
                result.ok = response.ok
                result.data = response.data
                result.reboot = license.reboot()
                result.ok ? resolve(result) : reject(result)
            })
            .catch((err) => {
                let error = parseXML(err.message)
                result.ok = false
                result.data = error.data || err.data || err.msg
                reject(result)
            })
    })
}


/*
 *   Sends company data to the license server which response with the activation key needed by local server.
 */
const sendActivationKey = () => {
    return new Promise((resolve, reject) => {
        let result = R.clone(template)

        let reboot = !!license.reboot()  // if undefined... will be false

        console.log('Sent REBOOT? --->', reboot, 'license.reboot()', license.reboot())

        let data = {
            License: license.license().toUpperCase(),
            Hwid: license.hardwareId(),
            Cif: license.companyKey(),
            Email: license.email(),
            Company: license.company(),
            Mailing: license.info(),
            Key: license.remoteKey(),

            reboot: reboot
        }

        let options = {method: 'POST', url: '/api/mgmt/validate', data: data, background: true}

        // console.log('sendActivationKey ---> options', options)
        m.request(options)
            .then((response) => {
                result.ok = response.ok
                result.data = response.msg
                result.reboot = response.reboot
                result.ok ? resolve(result) : reject(result)
            })
            .catch((err) => {
                console.log('LIB sendActivation catch', err)
                result.ok = false
                result.data = err.data || err.msg
                reject(result)
            })
    })

}


///  Devices Activation  ///////////////////////////////////////////////////////////////////////////////////////////////


const loadCurrentLicenseData = () => {
    return new Promise((resolve, reject) => {
        const options = {method: 'GET', url: '/api/mgmt/license', background: true}
        m.request(options)
            .then((data) => {
                let fixedDevices = []
                if (data.Terms && data.Terms.length > 0) {
                    fixedDevices = data.Terms.map((term) => {
                        term.id = term.SerialNumber
                        return term
                    })
                }
                license.company(data.Company)
                license.companyKey(data.Cif)
                license.email(data.Email)
                license.license(data.License.toUpperCase())
                license.info(data.Mailing)
                license.hardwareId(data.Hwid)
                license.version(data.version)
                license.activationHost(data.Host)
                license.inactiveDevices(fixedDevices)
                resolve(data)
            })
            .catch((err) => {
                console.error('CDeviceActivation - loadCurrentLicenseData', err)
                reject(err)
            })
    })
}


/*
 *  Loads devices data
 *
 *  @param: terminals {Array} - List of terminals ids to check
 */
const getTerminalsInfo = (terminals) => {
    return new Promise((resolve, reject) => {
        let ids = terminals.join(',')
        let options = {
            method: 'GET',
            url: '/api/container/elements',
            data: {container: 'Terminal', ids: ids},
            background: true
        }

        m.request(options)
            .then((response) => {
                let terminalsData = []
                if (response.items && response.items.length > 0) {
                    terminalsData = response.items.map((term) => {
                        term.id = term.serie  // overrides id to use in XML function due we don't need it and is used on other components as picker ids (due selection)
                        return term
                    })
                }
                license.devices(terminalsData)
                resolve(terminalsData)
            })
            .catch((err) => {
                console.error('LicenseLib - getTerminals', err)
                reject(err)
            })
    })
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Server probe and reboot
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/*
 *   Synchronous requesting of the headers is faster, and just enough.
 */
const serverReachable = (url) => {
    return new Promise((resolve, reject) => {
        if (!url) url = license.activationHost()
        m.request({
            method: 'GET',
            url: url,
            background: true,
            extract: (xhr) => {
                if (!(xhr.status >= 200 && xhr.status < 300 || xhr.status === 304)) {
                    throw new Object({status: xhr.status, message: xhr.statusText})
                }
                return xhr.response
            }
        })
            .then(() => {resolve(true)})
            .catch(() => {resolve(false)})
    })
}


/*
 *  Promise to wait between polling server.
 */
const wait = ms => new Promise(r => setTimeout(r, ms))

/*
 *   Poll function against NT6 server.
 */
const pollServerDown = (url) => {
    return new Promise((resolve, reject) => {
        let address = `${location.protocol}//${location.host}/api/index/` || url

        serverReachable(address)
            .then((reachable) => {
                if (!reachable) {
                    console.info('pollServerDown - netTime server is currently down!')
                    resolve()
                } else {
                    console.info('pollServerDown - netTime server is currently up!')
                    reject()
                }
            })
    })
}

const pollServerUp = (url) => {
    return new Promise((resolve, reject) => {
        let address = `${location.protocol}//${location.host}/api/index/` || url
        serverReachable(address)
            .then((reachable) => {
                if (reachable) {
                    console.info('pollServerUp - netTime server is currently up!')
                    resolve()
                } else {
                    console.info('pollServerUp - netTime server is currently down!')
                    reject()
                }
            })
    })
}

/*
 *  Function that retry the provided promise so many times as requested with a delay if needed.
 */
const retry = (operation, delay = 0, times = RETRY_TIMES) => {
    return new Promise((resolve, reject) => {
        operation()
            .then(resolve)
            .catch((reason) => {
                if (times - 1 > 0) {
                    return wait(delay)
                        .then(retry.bind(null, operation, delay, times - 1))
                        .then(resolve)
                        .catch(reject)
                }
                return reject(reason)
            })
    })
}

/*
 *  Polls the server until it's wake up again.
 *
 */
const waitUntilDown = () => retry(pollServerDown, RETRY_DELAY)

const waitUntilUp = (delay, retries) => retry(pollServerUp, delay || RETRY_DELAY, retries)

const waitUntilReboot = () => {
    return new Promise((resolve, reject) => {
        CT.isRebooting(true)
        waitUntilDown()
            .then(waitUntilUp)
            .then(() => {
                CT.isRebooting(false)
                resolve()
            })
            .catch((err) => {
                console.error('waitUntilReboot', err)
                CT.isRebooting(false)
                reject(err)
            })

    })
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Check license by user network instead of server network
//
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/*
 *  Get current licence data from server
 */
const stealthLicenseUpdateCurrentData = () => {
    return new Promise((resolve, reject) => {

        const options = {method: 'GET', url: '/api/mgmt/license'}

        REQ.request(options)
            .then((data) => {
                let result = {
                    Hwid: data.Hwid,
                    Version: data.Version,
                    License: data.License,
                    Product: data.Product,
                    Terms: data.Terms
                }
                resolve(result)
            })
            .catch((err) => {
                console.error('CLicense - stealthLicenseUpdateCurrentData', err)
                reject()
            })
    })
}

/*
 *  Ask license server about license and return just response status
 */
const stealthLicenseUpdateQueryLicenseHost = (params) => {
    return new Promise((resolve, reject) => {
        let url = 'https://activate.gospec.net/api/version'

        if (params) {
            let options = {
                method: 'POST',
                url: url,
                data: params,
                extract: (xhr) => {
                    return {status: xhr.status}
                }
            }

            m.request(options)
                .then(resolve)
                .catch((err) => {
                    console.error('stealthLicenseUpdateQueryLicenseHost', err)
                    resolve({status: err.code || 'ERR'})
                })
        } else {
            reject('No params or activation HOST provided.', {url, params})
        }
    })
}

/*
 *  Notify NT server about license server response.
 */
const stealthLicenseUpdateNotifyStatus = (status) => {
    return new Promise((resolve, reject) => {
        if (status) {
            console.log('stealthLicenseUpdateNotifyStatus', status)
            m.request({method: 'POST', url: '/api/mgmt/license', data: status})
                .then(resolve)
                .catch((err) => {
                    console.error('stealthLicenseUpdateNotifyStatus', err)
                    reject(err)
                })
        } else {
            reject('No status provided')
        }
    })
}

export default {

    license,
    updateData,
    deactivation,
    onlineActivation,
    offlineActivation,
    waitUntilReboot,
    waitUntilUp,
    serverReachable,

    loadCurrentLicenseData,
    getTerminalsInfo,
    getActivationKey,
    sendActivationKey,

    // Query license data bypassing private network.
    stealthLicenseUpdateCurrentData,
    stealthLicenseUpdateQueryLicenseHost,
    stealthLicenseUpdateNotifyStatus

}
