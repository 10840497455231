// -------------------------------------------------------------------------------------------
// Constants for SDLib
// -------------------------------------------------------------------------------------------


import CT from '../CT'

let logger

const isArray = val => !!val && Array.isArray(val)

const CMD_TYPE = {
    DISCO: "DISCO",
    FORCE_RELEASE: 'FORCE_RELEASE'
}

const ERR_CODE = {
    ERROR_GENERIC: "ERROR_GENERIC", //Some command inside identify process had an error
    DEVICE_TIMEOUT: "DEVICE_TIMEOUT",
    NO_DEVICES_FOUND: 'NO_DEVICES_FOUND',
    NO_CONNECTION: 'ERROR_SD_CONN',
    BUSY: 'ERROR_BUSY'
}

const DELAYCMD = 10
const TIMEOUT_DISCO_COMMAND = 10000 //seconds to wait a response for a <Disco/> command
const TIMEOUT_ENROLL_COMMAND = 90000
const TIMEOUT_READ_CARD_COMMAND = 5000
const TIMEOUT_WRITE_CARD_COMMAND = 15000
const TIMEOUT_READ_DOCUMENT_COMMAND = 15000

// If this substring is found on log message, it must be hidden =>  ... Type='BINARY' ...
const censureSensibleInfo = (msg) => {
    if (typeof msg === 'string') {
        let pos = msg.indexOf('Type=\'BINARY\'')
        if (pos > 0) return msg.substr(0, pos) + "[----- binary data ------]"
    }
    return msg
}

// If a property '__ntMarkLogger__' is found inside window object (ntMark app does it), use this logger instead of console
const log = (rawMsg, isError) => {
    /*if (CT.IS_DEBUG()) {
        let msg = censureSensibleInfo(rawMsg)
        if (!logger && window.hasOwnProperty('__ntMarkLogger__')) {
            logger = window.__ntMarkLogger__('SDMgr')
        }
        if (logger) {
            isError ? logger.error(msg) : logger.log(msg)
        } else {
            if (window.console) console.log(msg) //no logger found, output msg via console
        }
    }
*/
}
const error = (rawMsg) => log(rawMsg, true)

let _settings = {
    protocol: 'http:',
    host: 'localhost',
    port: 8101,
    rotateTemplate: 1,
    cardReadTimeout: 1000
}

const getSettings = () => _settings

const setSettings = (settings) => { _settings = settings }

export default {
    CMD_TYPE,
    ERR_CODE,
    DELAYCMD,
    TIMEOUT_DISCO_COMMAND,
    TIMEOUT_ENROLL_COMMAND,
    TIMEOUT_READ_CARD_COMMAND,
    TIMEOUT_WRITE_CARD_COMMAND,
    TIMEOUT_READ_DOCUMENT_COMMAND,
    isArray,
    log,
    error,
    setSettings,
    getSettings
}
