import m from 'mithril'
import stream from 'mithril/stream'
import P from '../../procurer/procurer_common'
import i18n from 'i18next'
import license from './licenseLib'
import CT from '../../src/CT'
import IconMgr from '../../src/managers/IconMgr'

export default (cfg) => {

    const STATUS = {ERROR: 0, PENDING: 1, BUSY: 2, DONE: 3}

    const defaults = {
        srcLogo: IconMgr.getLogo('netTimeLogoLogIn'),

        terminals: stream(),                      // selected terminals array (ids)
        terminalsData: stream([]),                // selected terminals arrays ({object})
        offlineMode: stream(),                    // offline mode flag
        transactionCompleted: stream(),           // completed transaction flag
        activationDone: stream(false),

        inputValues: {
            licenseCode: stream('')
        },

        walkthrough: {
            merged: undefined,
            connect: stream(),
            removeServer: stream(),
            localServer: stream(),
            result: stream('')
        },

        notifyChannel: stream()

    }

    let c = P.mergeProperties(defaults, cfg)

    const resetSteps = () => {
        c.walkthrough.connect(STATUS.PENDING)
        c.walkthrough.removeServer(STATUS.PENDING)
        c.walkthrough.localServer(STATUS.PENDING)

        if (!c.walkthrough.merged) {
            c.walkthrough.merged = stream.merge([
                c.walkthrough.connect,
                c.walkthrough.removeServer,
                c.walkthrough.localServer,
                c.walkthrough.result
            ]).map(() => {
                CT.forceRedraw('CDeviceActivation - Walkthrough')
                return stream.SKIP
            })
        }

    }

    const connectToLicenseServer = () => {
        return new Promise((resolve, reject) => {
            c.walkthrough.connect(STATUS.BUSY)
            license.loadCurrentLicenseData()
                .then(() => {
                    return license.serverReachable()
                })
                .then((isReachable) => {
                    c.offlineMode(!isReachable)
                    if (isReachable) {
                        c.walkthrough.connect(STATUS.DONE)
                        resolve({ok: true})
                    } else {
                        c.walkthrough.connect(STATUS.ERROR)
                        reject({ok: false, data: i18n.t('Servidor de licencias no operativo o inalcanzable.')})
                    }
                })
        })
    }

    const sendDevicesToEnable = () => {
        return new Promise((resolve, reject) => {
            c.walkthrough.removeServer(STATUS.BUSY)
            license.getTerminalsInfo(c.terminals())
                .then(license.getActivationKey)
                .then((result) => {
                    if (result.ok) {
                        c.walkthrough.removeServer(STATUS.DONE)
                        resolve(result)
                    } else {
                        c.walkthrough.removeServer(STATUS.ERROR)
                        reject(result)
                    }
                })
                .catch((err) => {
                    c.walkthrough.removeServer(STATUS.ERROR)
                    reject(err)
                })
        })
    }

    const sendLicenseKey = () => {
        return new Promise((resolve, reject) => {
            c.walkthrough.localServer(STATUS.BUSY)
            license.license.reboot(false)
            license.sendActivationKey()
                .then((response) => {
                    c.walkthrough.result(response.data)
                    if (response.ok) {
                        c.walkthrough.localServer(STATUS.DONE)
                        resolve(response)
                    } else {
                        c.walkthrough.localServer(STATUS.ERROR)
                        reject(response)
                    }
                })
                .catch((err) => {
                    c.walkthrough.result(err.data || err.msg)
                    c.walkthrough.localServer(STATUS.ERROR)
                    reject(err)
                })
        })
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    //  Main activation functions
    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const offlineActivation = () => {
        return new Promise((resolve, reject) => {
            // Note that first time a online transaction was tried, so license data was updated before this call.
            let code = c.inputValues.licenseCode()
            if (code) {
                license.license.reboot(false)   // Do not reboot on terminals activation

                license.offlineActivation(code)
                    .then((result) => {
                        c.transactionCompleted(true)
                        c.walkthrough.result(result.data || '...')
                        result.ok ? resolve(result) : reject(result)
                    })
                    .catch((err) => {
                        c.transactionCompleted(true)
                        c.walkthrough.result(err.data || err.msg)
                        reject(err)
                    })
            } else {
                c.walkthrough.result(i18n.t('Debe introducir el código de activación.'))
                reject()
            }
        })
    }

    const onlineActivation = () => {
        return new Promise((resolve, reject) => {
            connectToLicenseServer()
                .then(sendDevicesToEnable)
                .then(sendLicenseKey)
                .then(() => {
                    c.transactionCompleted(true)
                    resolve()
                })
                .catch((err) => {
                    c.walkthrough.result(err.data || err.msg)
                    c.transactionCompleted(true)
                    reject(err)
                })
        })
    }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    //  HyperScript functions
    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /*
     *  Returns tags attributes based on currently step and status
     */
    const stepAttrs = (type) => {
        let whichOne

        switch (type) {
            case 'connect':
                whichOne = c.walkthrough.connect()
                break
            case 'remoteServer':
                whichOne = c.walkthrough.removeServer()
                break
            case 'localServer':
                whichOne = c.walkthrough.localServer()
                break
        }

        switch (whichOne) {
            case  STATUS.BUSY:
                return {style: {marginTop: '1em'}, className: 'blink'}
            default:
                return {style: {marginTop: '1em'}}

        }
    }

    /*
     *  Returns image attributes based on currently step and status
     */
    const imageAttrs = (type) => {
        let whichOne

        switch (type) {
            case 'connect':
                whichOne = c.walkthrough.connect()
                break
            case 'remoteServer':
                whichOne = c.walkthrough.removeServer()
                break
            case 'localServer':
                whichOne = c.walkthrough.localServer()
                break
        }

        switch (whichOne) {
            case  STATUS.ERROR:
                return {src: IconMgr.getIcon('denegar'), style: {flexBasis: '25%', maxHeight: '25px' }}
            case  STATUS.PENDING:
                return {style: {flexBasis: '25%', visibility: 'hidden', maxHeight: '25px'}}
            case  STATUS.BUSY:
                return {src: IconMgr.getIcon('activTarget'), style: {flexBasis: '25%', maxHeight: '25px'}}
            case  STATUS.DONE:
                return {src: IconMgr.getIcon('aceptar'), style: {flexBasis: '25%', maxHeight: '25px'}}
            default:
                return {style: {flexBasis: '25%', visibility: 'hidden', maxHeight: '25px'}}
        }

    }

    /*
     *  Returns label attributes based on currently step and status
     */
    const labelAttrs = (type) => {

        let whichOne
        switch (type) {
            case 'connect':
                whichOne = c.walkthrough.connect()
                break
            case 'remoteServer':
                whichOne = c.walkthrough.removeServer()
                break
            case 'localServer':
                whichOne = c.walkthrough.localServer()
                break
        }

        switch (whichOne) {
            case  STATUS.ERROR:
                return {style: {color: '#FF0000', flexBasis: '75%'}}
            case  STATUS.PENDING:
                return {style: {flexBasis: '75%'}}
            case  STATUS.BUSY:
                return {style: {color: '#FF6600', flexBasis: '75%'}}
            case  STATUS.DONE:
                return {style: {flexBasis: '75%'}}
            default:
                return {style: {flexBasis: '75%'}}
        }
    }


    c.drawOfflineForm = () => {
        return [
            m('.login-group', [
                m('.login_label.login_title', i18n.t('Activación offline'))
            ]),

            m('.login-group', [
                m('label.login_label', i18n.t('Introduzca el código de activación:')),
                m('textarea.area-text-wgt', {
                    oninput: m.withAttr('value', c.inputValues.licenseCode),
                    value: c.inputValues.licenseCode()
                })
            ])
        ]
    }

    c.drawResult = () => {
        return [
            m('.login-group', [
                m('.login_label.login_title', i18n.t('Resultado'))
            ]),

            m('.login-group', [
                m('label', {
                    style: {
                        color: 'orange',
                        textAlign: 'center',
                        width: '100%',
                        whiteSpace: 'pre-wrap'
                    }
                }, c.walkthrough.result())

            ])
        ]
    }

    c.drawSerialsTags = () => {
        return c.terminalsData().map((terminal) => m('.tag.bg-grey-1', `SERIAL: ${terminal.serie}`))
    }


    c.drawSteps = () => {
        return [
            m('.login-group.flex-row', stepAttrs('connect'), [
                m('img', imageAttrs('connect')),
                m('label.login_label', labelAttrs('connect'), i18n.t('Conectando con el servidor remoto'))
            ]),
            m('.login-group.flex-row', stepAttrs('remoteServer'), [
                m('img', imageAttrs('remoteServer')),
                m('label.login_label', labelAttrs('remoteServer'), i18n.t('Activando terminales en servidor remoto'))
            ]),
            m('.login-group.flex-row', stepAttrs('localServer'), [
                m('img', imageAttrs('localServer')),
                m('label.login_label', labelAttrs('localServer'), i18n.t('Enviando la clave de activación al servidor local'))
            ])
        ]

    }

    c.drawIt = () => {

        return m('.login-form', {id: c.offlineMode() ? 'form-device-offline-activation' : 'form-device-activation'}, [
            m('.login-form-header', [
                m('.login-logo', m('img.brand-image', {src: c.srcLogo})),
                m('.login_label.login_title', i18n.t('Activar terminales'))
            ]),
            m('.login-form-body', [
                m('.login-group', {
                    style: {maxHeight: '4em', overflow: 'auto'}
                }, [ c.drawSerialsTags() ]),
                c.offlineMode() ? c.drawOfflineForm() : c.drawSteps(),
                c.transactionCompleted() ? c.drawResult() : null
            ]),
            m('.login-form-footer', {}, [
                c.activationDone() ?
                    m('button.btn-medium.btn-brand', {
                        onclick: (e) => {
                            e.preventDefault()
                            e.stopImmediatePropagation()
                            c.notifyChannel({result: 'ok', action: 'close'})
                        }
                    }, i18n.t('Cerrar')) :
                    m('button.btn-medium.btn-brand', {
                        onclick: (e) => {
                            e.preventDefault()
                            e.stopImmediatePropagation()
                            resetSteps()

                            if (!c.offlineMode()) {
                                onlineActivation().then(() => {c.activationDone(true)}).catch(console.error)
                            } else {
                                offlineActivation().then(() => {c.activationDone(true)}).catch(console.error)
                            }
                        }
                    }, i18n.t('Activar'))

            ])
        ])
    }

    c.oninit = () => {

        c.activationDone(false)

        // Required detailed info
        license.getTerminalsInfo(c.terminals())
            .then((data) => {
                console.log('Updated terminals data...', c.terminalsData())
                c.terminalsData(data)
                CT.forceRedraw('CDeviceActivation - terminals')
            })
            .catch((err) => {
                console.error('CDeviceActivation - getTerminalsInfo', err)
                c.terminalsData([])
            })

    }

    c.view = () => {
        return [
            m('.login-wrapper', [
                m('.login-header'),
                m('.login-body', {style: {maxHeight: '470px'}}, c.drawIt()),
                m('.login-footer', {style: {height: '25%'}}, m('.copyright-footer', CT.copyright()))
            ])
        ]
    }

    return c

}

