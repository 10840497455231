// -------------------------------------------------------------------------------------------
// SDCmds
// SpecDriver commands gruped to achieve a whole process, ex: "identifyFinger"
// -------------------------------------------------------------------------------------------
import SM from "../managers/SM";
import SDCT from "./SDCT";
import SDSendReceive from "./SDSendReceive";

const sendRequestDeviceCmd = (devId) => SDSendReceive.sendCmd(`<Request DeviceId="${devId}"/><Request/>`)
const sendRegisterDeviceCmd = (devId) => SDSendReceive.sendCmd(`<Register DeviceId="${devId}"/><Register/>`)
const sendBinaryEncodingCmd = () => SDSendReceive.sendCmd(`<Select BinaryEncoding="HEXA,MIME"/>`)
const sendClearFingersCmd = (devId) => SDSendReceive.sendCmd(`<Invoke DeviceId="${devId}" Action="ClearFinger" ></Invoke>`)
const sendFingerCmd = (devId, fngTemplate) => SDSendReceive.sendCmd(`<Invoke DeviceId="${devId}" Action="SendFinger" RotateTemplate="1" ><Params BinaryEncoding='MIME'><Param Type='BINARY' Name='Template'>${fngTemplate}</Param></Params></Invoke>`)
const sendIdentifyFinger = (devId) => SDSendReceive.sendCmd(`<Invoke DeviceId="${devId}" Action="IdentifyFinger" Imagen="0" PutImageWhenAvailable="0"><Params BinaryEncoding='MIME'></Params></Invoke>`)
const sendReleaseDeviceCmd = (devId) => {SDSendReceive.sendCmd(`<Release DeviceId="${devId}"/><Release/>`)}
//const sendUnregisterDeviceCmd = (devId) => SDSendReceive.sendCmd(`<Unregister DeviceId="${devId}"/><Unregister/>`) //UNREGISTER IS NOT NECESSARY (RELEASE DOES ALL THE WORK)
//const sendAbortCmd = (devId) => SDSendReceive.sendCmd(`<Invoke DeviceId="${devId}" Action="AbortEnroll" ></Invoke>`) //ABORTENROLL IS NOT NECESSARY SINCE IT IS CALLED INTERNALLY IN FingerReader.dll WHEN <RELEASE> IS INVOKED


//Sends a command to read a finger (first part of enroll procedure). RotateTemplate must be "1" or "0" depending on server settings
const sendReadFingerCmd = (devId, rotateTemplate) => SDSendReceive.sendCmd(`<Invoke DeviceId="${devId}" Action="ReadFinger" RotateTemplate="${rotateTemplate}" Imagen="0" ></Invoke>`)

// Sends a command to read a card
const sendReadCardCmd = (devId) => SDSendReceive.sendCmd(`<Invoke DeviceId="${devId}" Action="ReadCard"></Invoke>`)

// Sends a command to write a card
const sendWriteCardCmd = (devId, cardNumber) => SDSendReceive.sendCmd(`<Invoke DeviceId="${devId}" Action="WriteCard" Tarjeta="${cardNumber}" ></Invoke>`)

const sendReadDocumentCmd = (devId) => SDSendReceive.sendCmd(`<Invoke DeviceId="${devId}" Action="ReadDocument" ></Invoke>`) // WITHOUT PHOTO
//const sendReadDocumentCmd = (devId) => SDSendReceive.sendCmd(`<Invoke DeviceId="${devId}" Action="ReadDocument" SendFoto="1"></Invoke>`) // WITH PHOTO

const sendVerifyFingerCmd = (devId, rotateTemplate, base64Tpl) => {
    let encoding = "MIME"
    let sQuery = `<Invoke DeviceId="${devId}" Action="VerifyFinger" RotateTemplate="${rotateTemplate}" Imagen="0" >`
    sQuery += `<Params BinaryEncoding="${encoding}"><Param Type='BINARY' Name='Template'>`
    sQuery += base64Tpl //https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
    sQuery += `</Param></Params></Invoke>`
    sQuery += `</Invoke>`
    SDSendReceive.sendCmd(sQuery)
}

let rotateTemplate = SDCT.getSettings().hasOwnProperty('rotateTemplate') ? SDCT.getSettings().rotateTemplate : "1"

// JDS toggled to global vars
let stateMachine
let enrollTpl = undefined //will contain text-encoded enroll if operation is succsessful
let rejectInfo // when promise rejects, the code and message about the abort reason is anotated here
let commandResult = false //if 'true' after releasing devices, promise must resolve(), if false, promise must reject()

const verifyEnroll = () => { stateMachine.action('action_finger_verify_enroll', enrollTpl) }


const enrollFinger = (devId, genericResponseStm, eventResponseStm, eventStm, externalCmdStm, cmdStm) =>  new Promise((resolve, reject) => {

     stateMachine = SM.getInstance({
        name: 'EmrollSM',
        onStart: () => { //setting initial state
            return 'st_request_device_pending'
        },

        states: {
            'st_request_device_pending': {
                action_response_ok: 'st_register_device_pending',
                action_response_error: 'st_request_device_failed'
            },
            'st_register_device_pending': {
                action_response_ok: 'st_send_binary_encoding',
                action_response_error: 'st_register_device_failed'
            },
            'st_send_binary_encoding': {
                action_response_anonymous_ok: 'st_send_read_finger',
                action_response_anonymous_error: 'st_send_binary_encoding_failed',
                action_response_error: 'st_send_binary_encoding_failed'
            },
            'st_send_read_finger': {
                action_finger_enroll_ok: 'st_ready_for_verify_enroll',
                action_response_error: 'st_release',
                action_response_anonymous_error: 'st_exit',
            },

            // JDS - Middle state to allow user firing action
            'st_ready_for_verify_enroll': {
                action_finger_verify_enroll: 'st_verify_finger',
                action_response_error: 'st_release',
                action_response_anonymous_error: 'st_exit'
            },

            'st_verify_finger': {
                action_finger_verify_ok: 'st_verify_finger_ok',
                action_response_error: 'st_release',
                action_response_anonymous_error: 'st_exit'
            },

            'st_release': {
                action_response_ok: 'st_exit',
                action_response_anonymous_ok: 'st_exit',
                action_response_error: 'st_exit'
            },
            // ----------------every possible state needs to be defined-------------------------
            'st_finger_identified_ok': {},
            'st_request_device_failed': {},
            'st_register_device_failed': {},
            'st_send_binary_encoding_failed': {},
            'st_send_clear_fingers_failed': {},
            'st_send_finger_failed': {},
            'st_exit': {}
        },

        onNewState: {
            'st_request_device_pending': () => setTimeout(()=> {sendRequestDeviceCmd(devId)}, SDCT.DELAYCMD),
            'st_register_device_pending': () => setTimeout(()=> {sendRegisterDeviceCmd(devId)}, SDCT.DELAYCMD),
            'st_send_binary_encoding': () => setTimeout(()=> {
                sendBinaryEncodingCmd()
                // stateMachine.action('action_response_anonymous_ok')
            }, SDCT.DELAYCMD),

            'st_send_read_finger': () => setTimeout(()=> {
                sendReadFingerCmd(devId, rotateTemplate)
            }, SDCT.DELAYCMD),


            'st_ready_for_verify_enroll': (data) => {
                //enroll data candidate (pending of verification)
                enrollTpl = data.template
                eventStm('ENROLL_VERIFY')
            },

            'st_verify_finger': () => setTimeout(()=> {
                sendVerifyFingerCmd(devId, rotateTemplate, enrollTpl)
            }, SDCT.DELAYCMD),

            'st_verify_finger_ok': () => {
                commandResult = true //annotates correct enroll before device unregister / release procedure
                if (stateMachine) stateMachine.setState('st_release') //Exit with correct enroll
            },
            'st_release': () => {setTimeout(()=> {sendReleaseDeviceCmd(devId)}, SDCT.DELAYCMD)}, //Previous step to exit
            'st_exit': () => enrollFingerExit(), //End of enroll. Promise will be resolverd or rejected (result is stored in: "identifyResult")
            //Error states
            'st_request_device_failed': () => enrollFingerAbort(SDCT.ERR_CODE.NO_DEVICES_FOUND, '<request> operation failed'),
            'st_register_device_failed': () => enrollFingerAbort(SDCT.ERR_CODE.NO_DEVICES_FOUND, '<register> operation failed'),
            'st_send_binary_encoding_failed': () => enrollFingerAbort(SDCT.ERR_CODE.ERROR_GENERIC, '<Select BinaryEncoding> operation failed'),
            'st_send_finger_failed': () => enrollFingerAbort(SDCT.ERR_CODE.ERROR_GENERIC, '<Invoke Action="SendFinger"> operation failed'),
        }
    }) //END of  SM.getInstance

    SDCT.log(">> SDCmds : starting SM (Enroll process)")
    stateMachine.start()

    SDCT.log(">> SDCmds : subscribing to events")
    //Listen to 'genericResponseStm' changes, that contain responses of 2 types:
    // 1) Responses related to a specific device id, ex:  {"GenericResponse":{"@attributes":{"DeviceId":"102","Valid":"true","ErrorCode":"0"}}}
    // 2) Responses not related to any device: ex: {"GenericResponse":{"@attributes":{"Valid":"true","ErrorCode":"0"}, ....
    genericResponseStm.map((genResp) => {
        if (genResp['DeviceId'] === devId) {
            if (genResp['Valid'] === 'true') stateMachine.action('action_response_ok')
            else if (genResp['Valid'] === 'false') stateMachine.action('action_response_error')
        } else {
            if (genResp['Valid'] === 'true') {
                stateMachine.action('action_response_anonymous_ok')
            }
            else if (genResp['Valid'] === 'false') stateMachine.action('action_response_anonymous_error')
        }
    })

    //Listens to 'cmdStm' changes, that contain the enrolled template
    cmdStm.map((enrollResponse) => {
        if (enrollResponse.ok && enrollResponse.template && enrollResponse.template.length) {
            SDCT.log("SDCmds ReadFinger OK with template (length): " + enrollResponse.template.length)
            stateMachine.action('action_finger_enroll_ok', enrollResponse)
        }
    })

    eventStm('ENROLL_STARTED') // JDS First message

    //Listens to 'eventResponseStm' changes, that contain events of this type: {"DeviceId":"102","Event":"MATCHOK","Value":"0"}
    //sent by SpecDriver finger device.
    // Some special events: 'MATCHOK' &  'OPSTOP' require a special treatment, because they provoke a positive or negative response
    // Other events, ex: "CORETOLEFT" are directly passed to 'eventStm' to be listened by the application
    eventResponseStm.map((genResp) => {
        if (genResp['DeviceId'] === devId) {
            switch (genResp['Event']) {
                case 'MATCHOK': //Correct user verification
                    stateMachine.action('action_finger_verify_ok')
                    eventStm(genResp['Event'])
                    break
                case 'OPSTOP': //End of procedure without success
                    enrollFingerAbort(SDCT.ERR_CODE.DEVICE_TIMEOUT, 'Enroll process expired')
                    eventStm(genResp['Event'])
                    break
                default:
                    eventStm(genResp['Event'])
            }
        }
    })

    //Listens to 'externalCmdStm' changes, that contain command names
    externalCmdStm.map((cmdName) => {
        switch (cmdName) {
            case SDCT.CMD_TYPE.FORCE_RELEASE:
                SDCT.log(">>SDCmds: received FORCE_RELEASE command via externalCmdStm")
                sendReleaseDeviceCmd(devId) //Because window is closing, the <Release> must be sent immediately
                break
        }
    })

    //Set a timeout for a not successful identification process
    let identifyTimeOut = setTimeout(() => {enrollFingerAbort(SDCT.ERR_CODE.DEVICE_TIMEOUT, 'Enroll operation timeout')}, SDCT.TIMEOUT_ENROLL_COMMAND)

    // ** PRE-EXIT SEQUENCE **
    //Takes note of the aborting reason, and sets the proces result (identifyResult) to false, and sets the st_abort state that will send the "Release" cmd to the device
    const enrollFingerAbort = (code, message) => {
        rejectInfo = {code, message}
        commandResult = false
        if (stateMachine) stateMachine.setState('st_release')
    }

    // ** EXIT SEQUENCE **
    //The unique point of exit for the promise. It can "resolve" or "reject" depending on previously annotated 'identifyResult'
    const enrollFingerExit = () => {
        eventStm('ENROLL_EXIT') // JDS
        stateMachine = undefined //Remove SM
        clearTimeout(identifyTimeOut)
        if (commandResult) resolve(enrollTpl) //SUCCESSFUL enroll, template has been stored in "enrollTpl"
        else reject(rejectInfo) //rejects with previously annotated reason
    }
})


/*
 *  Read card.
 */
const readCard = (devId, genericResponseStm, eventResponseStm, eventStm, externalCmdStm, cmdStm, byEvent = false) => new Promise((resolve, reject) => {

    stateMachine = SM.getInstance({
        name: 'readCardSM',
        onStart: () => {
            return 'st_request_device_pending'
        },

        states: {
            'st_request_device_pending': {
                action_response_ok: 'st_register_device_pending',
                action_response_error: 'st_request_device_failed'
            },
            'st_register_device_pending': {
                action_response_ok: 'st_read_card',
                action_response_error: 'st_register_device_failed'
            },
            'st_read_card': {
                action_card_enroll_ok: 'st_release',
                action_card_enroll_ko: 'st_release',
                action_response_ok: 'st_release',
                action_response_error: 'st_register_device_failed'
            },
            'st_release': {
                action_response_ok: 'st_exit',
                action_response_anonymous_ok: 'st_exit',
                action_response_error: 'st_exit'
            },
            // ----------------every possible state needs to be defined-------------------------
            'st_request_device_failed': {},
            'st_register_device_failed': {},
            'st_exit': {}
        },

        onNewState: {
            'st_request_device_pending': () => setTimeout(() => {
                sendRequestDeviceCmd(devId)
            }, SDCT.DELAYCMD),
            'st_register_device_pending': () => setTimeout(() => {
                sendRegisterDeviceCmd(devId)
            }, SDCT.DELAYCMD),
            'st_read_card': () => setTimeout(() => {
                sendReadCardCmd(devId)
            }, SDCT.DELAYCMD),
            'st_release': () => {
                setTimeout(() => {
                    sendReleaseDeviceCmd(devId)
                }, SDCT.DELAYCMD)
            },
            'st_exit': () => readCardExit(), // End of process. Promise will be resolved or rejected.
            //Error states
            'st_request_device_failed': () => readCardAbort(SDCT.ERR_CODE.NO_DEVICES_FOUND, '<request> operation failed'),
            'st_register_device_failed': () => readCardAbort(SDCT.ERR_CODE.NO_DEVICES_FOUND, '<register> operation failed')
        }
    }) //END of  SM.getInstance

    stateMachine.start()

    // Listen to 'genericResponseStm' changes, that contain responses of 2 types:
    // 1) Responses related to a specific device id, ex:  {"GenericResponse":{"@attributes":{"DeviceId":"102","Valid":"true","ErrorCode":"0"}}}
    // 2) Responses not related to any device: ex: {"GenericResponse":{"@attributes":{"Valid":"true","ErrorCode":"0"}, ....
    genericResponseStm.map((genResp) => {
        if (genResp['DeviceId'] === devId) {
            if (genResp['Valid'] === 'true') stateMachine.action('action_response_ok')
            else if (genResp['Valid'] === 'false') stateMachine.action('action_response_error')
        } else {
            if (genResp['Valid'] === 'true') {
                stateMachine.action('action_response_anonymous_ok')
            }
            else if (genResp['Valid'] === 'false') stateMachine.action('action_response_anonymous_error')
        }
    })

    //Listens to 'cmdStm' changes, that contain the card serial number
    cmdStm.map((readCardResponse) => {
        //console.log('readCardResponse ----> ', readCardResponse)
        if (readCardResponse.ok ) {

            if (byEvent === false) {
                SDCT.log("SDCmds ReadCard OK with number: " + readCardResponse.CardSN)
                commandResult = true
                enrollTpl = {
                    CardSN: readCardResponse.CardSN,
                    CardSize: readCardResponse.CardSize
                }
            } else {
                commandResult = true
                eventStm(readCardResponse) //re-submit the response to the sream where CWgtCardReader will process responses
            }

            //Finaly, release the device, & finish operation...
            stateMachine.action('action_card_enroll_ok', readCardResponse)

        } else {

            //If operation mode is "By Event", ignore incorrect response from driver, as readcard command is not allowed
            if (byEvent === false) {
                commandResult = false
                enrollTpl = {}
                rejectInfo = {code: SDCT.ERR_CODE.DEVICE_TIMEOUT, message: 'Enroll operation timeout'}
                stateMachine.action('action_card_enroll_ko', readCardResponse)
            } else {
                console.log(">>> SDCmds ignoring incorrect enroll response, as read mode is by event")
            }
        }
    })


    // Listens to 'eventResponseStm' changes, that contain events of this type: {"DeviceId":"102","Event":"Tag","Value":"123456789"}
    eventResponseStm.map((genResp) => {
        if (genResp['DeviceId'] === devId) {
            switch (genResp['Event']) {
                case 'OPSTOP': //End of procedure without success
                    eventStm(genResp['Event'])
                    break
                case 'DocumentReaded':
                    if (genResp.response) {
                        enrollTpl = {
                            ok: true,
                            CardSN: genResp.response.DNI_NUMBER,
                            FirstName: genResp.response.DNI_FIRSTNAME,
                            LastName: genResp.response.DNI_SURNAMES,
                        }

                        eventStm(enrollTpl) //re-submit the response to the sream where CWgtCardReader will process responses

                        commandResult = true
                    }
                    stateMachine.action('action_response_ok')
                    break
                default:
                    eventStm(genResp['Event']['Response'])
            }
        }
    })

    //Listens to 'externalCmdStm' changes, that contain command names
    externalCmdStm.map((cmdName) => {
        switch (cmdName) {
            case SDCT.CMD_TYPE.FORCE_RELEASE:
                SDCT.log(">>SDCmds: received FORCE_RELEASE command via externalCmdStm")
                sendReleaseDeviceCmd(devId) //Because window is closing, the <Release> must be sent immediately
                break
        }
    })

    //Set a timeout for a not successful identification process
    let readCardTimeOut = setTimeout(() => {
        readCardAbort(SDCT.ERR_CODE.DEVICE_TIMEOUT, 'Enroll operation timeout')
    }, byEvent ? SDCT.TIMEOUT_READ_CARD_COMMAND * 10 : SDCT.TIMEOUT_READ_CARD_COMMAND)

    // ** PRE-EXIT SEQUENCE **
    //Takes note of the aborting reason, and sets the proces result (identifyResult) to false, and sets the st_abort state that will send the "Release" cmd to the device
    const readCardAbort = (code, message) => {
        rejectInfo = {code, message}
        console.log('rejectInfo', rejectInfo)
        commandResult = false
        if (stateMachine) stateMachine.setState('st_release')
    }

    // ** EXIT SEQUENCE **
    //The unique point of exit for the promise. It can "resolve" or "reject" depending on previously annotated 'identifyResult'
    const readCardExit = () => {
        stateMachine = undefined //Remove SM
        clearTimeout(readCardTimeOut)
        if (commandResult)
            resolve(enrollTpl) // Successful enroll, card number has been stored in "enrollTpl"
        else
            reject(rejectInfo) // rejects with previously annotated reason
    }

})




/*
 *  Read document.
 */
const readDocument = (devId, genericResponseStm, eventResponseStm, eventStm, externalCmdStm) => new Promise((resolve, reject) => {

    let operationSuccess = false //If true, command succeeds and promise will resolve
    let operationResult = undefined

    stateMachine = SM.getInstance({
        name: 'readDocumentSM',
        onStart: () => {
            return 'st_request_device_pending'
        },

        states: {
            'st_request_device_pending': {
                action_response_ok: 'st_register_device_pending',
                action_response_error: 'st_request_device_failed'
            },
            'st_register_device_pending': {
                action_response_ok: 'st_read_document',
                action_response_error: 'st_register_device_failed'
            },
            'st_read_document': {
                action_card_enroll_ok: 'st_release',
                action_card_enroll_ko: 'st_release',
                action_response_ok: 'st_release',
                action_response_error: 'st_register_device_failed'
            },
            'st_release': {
                action_response_ok: 'st_exit',
                action_response_anonymous_ok: 'st_exit',
                action_response_error: 'st_exit'
            },
            // ----------------every possible state needs to be defined-------------------------
            'st_request_device_failed': {},
            'st_register_device_failed': {},
            'st_exit': {}
        },

        onNewState: {
            'st_request_device_pending': () => setTimeout(() => {
                sendRequestDeviceCmd(devId)
            }, SDCT.DELAYCMD),
            'st_register_device_pending': () => setTimeout(() => {
                sendRegisterDeviceCmd(devId)
            }, SDCT.DELAYCMD),
            'st_read_document': () => setTimeout(() => {
                sendReadDocumentCmd(devId)
            }, SDCT.DELAYCMD),
            'st_release': () => {
                setTimeout(() => {
                    sendReleaseDeviceCmd(devId)
                }, SDCT.DELAYCMD)
            },
            'st_exit': () => readDocumentExit(), // End of process. Promise will be resolved or rejected.
            //Error states
            'st_request_device_failed': () => readDocumentAbort(SDCT.ERR_CODE.NO_DEVICES_FOUND, '<request> operation failed'),
            'st_register_device_failed': () => readDocumentAbort(SDCT.ERR_CODE.NO_DEVICES_FOUND, '<register> operation failed')
        }
    }) //END of  SM.getInstance

    stateMachine.start()

    // Listen to 'genericResponseStm' changes, that contain responses of 2 types:
    // 1) Responses related to a specific device id, ex:  {"GenericResponse":{"@attributes":{"DeviceId":"102","Valid":"true","ErrorCode":"0"}}}
    // 2) Responses not related to any device: ex: {"GenericResponse":{"@attributes":{"Valid":"true","ErrorCode":"0"}, ....
    genericResponseStm.map((genResp) => {
        if (genResp['DeviceId'] === devId) {
            if (genResp['Valid'] === 'true') stateMachine.action('action_response_ok')
            else if (genResp['Valid'] === 'false') stateMachine.action('action_response_error')
        } else {
            if (genResp['Valid'] === 'true') {
                stateMachine.action('action_response_anonymous_ok')
            }
            else if (genResp['Valid'] === 'false') stateMachine.action('action_response_anonymous_error')
        }
    })

    // Listens to 'eventResponseStm' changes, that contain events of this type: {"DeviceId":"102","Event":"Tag","Value":"123456789"}
    eventResponseStm.map((evtResponse) => {
        if (evtResponse && evtResponse.ok === true) {
            let genResp = evtResponse.response
            if (genResp['DeviceId'] === devId && genResp['Action'] === "ReadDocument") {
                operationResult = genResp
                operationSuccess = true
                stateMachine.action('action_response_ok')
            }
        } else {
            operationResult = undefined
            operationSuccess = false
            stateMachine.action('action_response_error')
        }

    })

    //Listens to 'externalCmdStm' changes, that contain command names
    externalCmdStm.map((cmdName) => {
        switch (cmdName) {
            case SDCT.CMD_TYPE.FORCE_RELEASE:
                SDCT.log(">>SDCmds: received FORCE_RELEASE command via externalCmdStm")
                sendReleaseDeviceCmd(devId) //Because window is closing, the <Release> must be sent immediately
                break
        }
    })

    //Set a timeout for a not successful identification process
    let readCardTimeOut = setTimeout(() => {
        readDocumentAbort(SDCT.ERR_CODE.DEVICE_TIMEOUT, 'Enroll operation timeout')
    },  SDCT.TIMEOUT_READ_DOCUMENT_COMMAND)

    // ** PRE-EXIT SEQUENCE **
    //Takes note of the aborting reason, and sets the proces result (identifyResult) to false, and sets the st_abort state that will send the "Release" cmd to the device
    const readDocumentAbort = (code, message) => {
        rejectInfo = {code, message}
        console.log('rejectInfo', rejectInfo)
        operationSuccess = false
        if (stateMachine) stateMachine.setState('st_release')
    }

    // ** EXIT SEQUENCE **
    //The unique point of exit for the promise. It can "resolve" or "reject" depending on previously annotated 'identifyResult'
    const readDocumentExit = () => {
        stateMachine = undefined //Remove SM
        clearTimeout(readCardTimeOut)
        if (operationSuccess)
            resolve(operationResult) // Successful readDocument
        else
            reject(rejectInfo) // rejects with previously annotated reason
    }

})




//************************************************************************************************************
//
// WRITING CARD
//
//************************************************************************************************************



/*
 *  Write card.
 */
const writeCard = (devId, cardNumber, genericResponseStm, eventResponseStm, eventStm, externalCmdStm, cmdStm) => new Promise((resolve, reject) => {

    stateMachine = SM.getInstance({
        name: 'writeCardSM',
        onStart: () => {
            return 'st_request_device_pending'
        },

        states: {
            'st_request_device_pending': {
                action_response_ok: 'st_register_device_pending',
                action_response_error: 'st_request_device_failed'
            },
            'st_register_device_pending': {
                action_response_ok: 'st_write_card',
                action_response_error: 'st_register_device_failed'
            },
            'st_write_card': {
                action_card_write_ok: 'st_release',
                action_card_write_ko: 'st_release',
                action_response_ok: 'st_release',
                action_response_error: 'st_register_device_failed'
            },
            'st_release': {
                action_response_ok: 'st_exit',
                action_response_anonymous_ok: 'st_exit',
                action_response_error: 'st_exit'
            },
            // ----------------every possible state needs to be defined-------------------------
            'st_request_device_failed': {},
            'st_register_device_failed': {},
            'st_exit': {}
        },

        onNewState: {
            'st_request_device_pending': () => setTimeout(() => {
                sendRequestDeviceCmd(devId)
            }, SDCT.DELAYCMD),
            'st_register_device_pending': () => setTimeout(() => {
                sendRegisterDeviceCmd(devId)
            }, SDCT.DELAYCMD),
            'st_write_card': () => setTimeout(() => {
                sendWriteCardCmd(devId, cardNumber)
            }, SDCT.DELAYCMD),
            'st_release': () => {
                setTimeout(() => {
                    sendReleaseDeviceCmd(devId)
                }, SDCT.DELAYCMD)
            },
            'st_exit': () => writeCardExit(), // End of process. Promise will be resolved or rejected.
            //Error states
            'st_request_device_failed': () => writeCardAbort(SDCT.ERR_CODE.NO_DEVICES_FOUND, '<request> operation failed'),
            'st_register_device_failed': () => writeCardAbort(SDCT.ERR_CODE.NO_DEVICES_FOUND, '<register> operation failed')
        }
    }) //END of  SM.getInstance

    stateMachine.start()

    // Listen to 'genericResponseStm' changes, that contain responses of 2 types:
    // 1) Responses related to a specific device id, ex:  {"GenericResponse":{"@attributes":{"DeviceId":"102","Valid":"true","ErrorCode":"0"}}}
    // 2) Responses not related to any device: ex: {"GenericResponse":{"@attributes":{"Valid":"true","ErrorCode":"0"}, ....
    genericResponseStm.map((genResp) => {
        if (genResp['DeviceId'] === devId) {
            if (genResp['Valid'] === 'true') stateMachine.action('action_response_ok')
            else if (genResp['Valid'] === 'false') stateMachine.action('action_response_error')
        } else {
            if (genResp['Valid'] === 'true') {
                stateMachine.action('action_response_anonymous_ok')
            }
            else if (genResp['Valid'] === 'false') stateMachine.action('action_response_anonymous_error')
        }
    })

    //Listens to 'cmdStm' changes, that contain the card serial number
    cmdStm.map((response) => {
        console.log('writeCard cmdStm.map response ----> ', response)
        if (response.ok ) {
            SDCT.log("SDCmds WriteCard OK ")
            commandResult = true
            //Finaly, release the device, & finish operation...
            stateMachine.action('action_card_write_ok', response)
        } else {
            commandResult = false
            rejectInfo = {code: SDCT.ERR_CODE.DEVICE_TIMEOUT, message: 'Enroll operation timeout'}
            stateMachine.action('action_card_write_ko', response)
        }
    })



    //Listens to 'externalCmdStm' changes, that contain command names
    externalCmdStm.map((cmdName) => {
        switch (cmdName) {
            case SDCT.CMD_TYPE.FORCE_RELEASE:
                SDCT.log(">>SDCmds: received FORCE_RELEASE command via externalCmdStm")
                sendReleaseDeviceCmd(devId) //Because window is closing, the <Release> must be sent immediately
                break
        }
    })

    //Set a timeout for a not successful identification process
    let writeCardTimeOut = setTimeout(() => {
        writeCardAbort(SDCT.ERR_CODE.DEVICE_TIMEOUT, 'Enroll operation timeout')
    }, SDCT.TIMEOUT_WRITE_CARD_COMMAND)

    // ** PRE-EXIT SEQUENCE **
    //Takes note of the aborting reason, and sets the proces result (identifyResult) to false, and sets the st_abort state that will send the "Release" cmd to the device
    const writeCardAbort = (code, message) => {
        rejectInfo = {code, message}
        console.log('rejectInfo', rejectInfo)
        commandResult = false
        if (stateMachine) stateMachine.setState('st_release')
    }

    // ** EXIT SEQUENCE **
    //The unique point of exit for the promise. It can "resolve" or "reject" depending on previously annotated 'identifyResult'
    const writeCardExit = () => {
        stateMachine = undefined //Remove SM
        clearTimeout(writeCardTimeOut)
        if (commandResult)
            resolve(enrollTpl) // Successful enroll, card number has been stored in "enrollTpl"
        else
            reject(rejectInfo) // rejects with previously annotated reason
    }

})




export default {
    enrollFinger,
    verifyEnroll,
    readCard,
    readDocument,
    writeCard
}
