import m from 'mithril'
import * as R from 'ramda'
import CT from './CT'
import async from './async'
import cache from './cache'

const disabledCache = false

const nonBlockingRoutes = ['/api/notBlocking/route', '/api/shift/manager']  // POST routes that will not block app with a loader spinner.

let submitMap = {                                     // Map to register protected routes calls
    sequence: 0,  // POST id
    counter: 0    // POST unresolved
}

//Debug
let _dbgMonitor

/*
 *  Main entry point for server/mocks request.
 *  @param {Object} params - Request options used by m.request method
 */
const request = (params) => {
    if (window._global__TEST_MODE__) {
        return new Promise((resolve, reject) => {
            import("./test/FakeData").then((FakeData) => {
                resolve(FakeData.doRequest(params))
            })
        })
    } else {
        return doRequest(params)
    }
}

/*
 *   Executes a XHR against server.
 *      POST: Enables loading spinner until be resolved.
 *      GET : Can be applied a cache mechanism (mapped with a KEY generated from request parameters) on:
 *             - Paginated queries
 *             - Full entity requested with static content (enabled with flag params.storeInCache)
 *
 *   @param {Object} params - Request options used by m.request method
 */
const doRequest = (params) => {
    return new Promise((resolve, reject) => {
        if (params.method !== 'GET') {
            let id = registerReq(params)
            wrappedRequest(params)
                .then((res) => {
                    unregisterReq(id)
                    resolve(res)
                })
                .catch((err) => {
                    unregisterReq(id)
                    reject(err)
                })
        } else {
            params.data = params.data || {}
            let data = params.data
            let start = params.data.pageStartIndex
            let size = params.data.pageSize
            let useCache = (start !== undefined && size !== undefined)
                && !disabledCache
                && !(params.data && params.data.container === 'Sessions')

            if (useCache) {
                let key = cache.getKeyForPaginationCache(params, data)

                //if (_dbgMonitor) _dbgMonitor.addNotif({type: "REQ_1", value: key + "_" + start + " n: " + size})

                if (!cache.isValid(params, key)) {
                    //if (_dbgMonitor) _dbgMonitor.addNotif({type: "REQ_2", extraCN: 'missing', value: key + "_" + start})

                    queryDataAndFillCache(params, key)
                        .then(() => {

                            if (cache.isValid(params, key)) {
                                cache.getFromCache(params, key)
                                    .then(resolve)
                                    .catch(reject)
                            } else {
                                //if (_dbgMonitor) _dbgMonitor.addNotif({type: "REQ_2", extraCN: 'error', value: key + "_" + start})
                                reject(new Error("Cannot get data from cache"))
                            }
                        })
                        .catch(reject)
                } else {
                    //if (_dbgMonitor) _dbgMonitor.addNotif({type: "REQ_2", value: key + "_" + start})
                    cache.getFromCache(params, key).then(resolve).catch(reject)
                }
            } else {
                if (params.storeInCache && !disabledCache) {
                    handleExplicitCache(params).then(resolve).catch(reject)   // WITHOUT PAGINATION, FULL CACHED
                } else {
                    wrappedRequest(params).then(resolve).catch(reject)        // NOT CACHED
                }
            }
        }
    })
}

/*
 *  Says if a route will block app white loading spinner.
 */
const protectRouteCall = (url) => !(nonBlockingRoutes.indexOf(url) >= 0)


/*
 *  Registers a protected routes calls and enables global spinner flag if required.
 */
const registerReq = (options) => {
    if (protectRouteCall(options.url)) {
        let id = ++submitMap.sequence
        submitMap[id] = options
        submitMap['counter']++
        if (!CT.isLoading()) CT.isLoading(true)
        return id
    } else {
        return 0
    }
}


/*
 *  Unregisters a protected route call and disables global spinner flag if required.
 */
const unregisterReq = (id) => {
    if (id) {
        if (submitMap[id]) {
            delete submitMap[id]
            let existingQueries = --submitMap['counter']

            if (!existingQueries) CT.isLoading(false)
        } else {
            console.warning('Ended post of unmapped id.', id)
        }
    }
}


/*
 *   Invokes a new query and wraps server response to trigger the proper [a]sync resolution mechanism.
 */
const wrappedRequest = (options) => {

    let _options = {
        config: (xhr) => { xhr._options_ = options },
        extract: defaultExtractionMethod
    }

    let args = R.mergeRight(options, _options)

    // Request wrapper
    return new Promise((resolve, reject) => {
        m.request(args)
            .then(result => {
                if (result.async) {
                    async.startAsyncQuery({
                        options: result._options_,   // just for tracking, store the original request parameters
                        id: result.taskId,
                        location: result.location,
                        promise: [{resolve, reject, completed: false}],  // asynchronous: will be resolved at proper time
                        completed: false,
                        started: false,
                        progress: 0,
                        counter: 0,
                        resolved: false,
                        resolvedAt: undefined
                    })
                } else {
                    resolve(result.data)              // synchronous: direct resolve
                    let sUrl = args.url
                    if (sUrl.length > 50) sUrl = sUrl.substr(0, 50) + '...'
                    if (!options.background) CT.forceRedraw("REQ: " + sUrl) //(invokes m. redraw())
                }
            })
            .catch(reject)
    })
}

/*
 *   Executes a cacheable (with pageStcaartIndex, pageSize) query against a server with extra added offset and
 *   stores it in cache store.
 */
const queryDataAndFillCache = (p, k) => {
    return new Promise((resolve, reject) => {
        let start = p.data.pageStartIndex
        let size = p.data.pageSize
        //New intervals with offset before and after
        let ini = start - cache.CACHE_OFFSET
        if (ini < 0) ini = 0
        let newSize = size + 2 * cache.CACHE_OFFSET
        //Params used to launch the new interval must be cloned from original params
        let clonedPars = CT.cloneDeep(p)
        clonedPars.data.pageStartIndex = ini
        clonedPars.data.pageSize = newSize

        // JDS 29-03-2020 - Added container to clean easy clean data
        let cont = (p && p.data && p.data.container) || 'unknownContainer'

        wrappedRequest(clonedPars)
            .then((res) => {
                if (!cache.store[k]) cache.store[k] = []
                let arr = cache.store[k]
                if (arr.length >= cache.MAX_BLOCKS) arr.splice(0, 1) // FIFO mode! (first block is removed and new block is added on top)

                //Inject "entity-pos" property into every entity in res.items to facilitate e2e testing
                res.items = CT.mapIndexed((item, idx) => {
                    item["entity-pos"] = ini + idx
                    return item
                })(res.items)

                arr.push({
                    ini: ini,
                    end: ini + res.items.length,
                    cacheItems: res.items,
                    cont: cont,
                    total: res.total
                })
                cache.store[k] = arr
                //if (_dbgMonitor) _dbgMonitor.addNotif({type: "REQ_2", extraCN: 'received', value: k})
                resolve()
            })
            .catch(reject)
    })
}


/*
 *   Type of cache used when query all elements of a entity or is a static query. (FILTER, CONTAINER_DEFINITION, ...)
 *   Enabled when request params contains storeInCache property.
 *
 */
const handleExplicitCache = (params) => {
    return new Promise((resolve, reject) => {
        let nameSpace = params.storeInCache // "storeInCache" is used to signal that explicit cache is required, and also it sets witch namespace to use inside the cache store
        let data = params.data || {}
        let key = cache.getKeyForExplicitCache(params, data)

        if (!cache.store[nameSpace]) cache.store[nameSpace] = {}
        let store = cache.store[nameSpace][key]

        //CT.log3("Quering explicit cache: key: " + key + " ns: " + nameSpace)
        if (store) {
            //CT.log4("From explicit cache: key: " + key + " ns: " + nameSpace)
            resolve(store)
        } else {
            wrappedRequest(params)
                .then((res) => {
                    //CT.log2("NOT in explicitcache: storing key: "+ key + " ns: " + nameSpace)
                    cache.store[nameSpace][key] = res
                    resolve(res)
                })
                .catch(reject)
        }
    })
}


/*
 *  Every request response handler
 */
const defaultExtractionMethod = (xhr) => {
    let result = {}
    const location = xhr.getResponseHeader('Location')
    const isJSON = xhr.getResponseHeader('Content-Type') && xhr.getResponseHeader('Content-Type').indexOf('application/json') !== -1

    if (xhr.status >= 400) throw new Object({status: xhr.status, message: xhr.statusText})

    try {
        if (xhr.responseText) result = isJSON ? JSON.parse(xhr.responseText) : xhr.responseText
    } catch (err) {
        console.error('Error on response defaultExtractionMethod:', err)
    }

    let _result = {
        _options_: xhr._options_,
        status: xhr.status,
        data: result,
        taskId: result.taskId,
        location: location,
        async: (!!location)
    }

    // if (CT.IS_DEBUG()) console.log('Wrapped request response: ', _result)

    return _result
}


export default {
    request,

    // cache
    directNotifyAppEventContainersUpdated: cache.directNotifyAppEventContainersUpdated,
    CACHE_NAMES: cache.CACHE_NAMES,
    clearCache: cache.clearCache,
    clearContainerCache: cache.clearContainerCache,

    // asynchronous
    asyncProgress: async.asyncProgress,            // Global progress of all task
    startAsyncQuery: async.startAsyncQuery,
    abortAsyncQuery: async.abortAsyncQuery,
    progressAsyncQuery: async.progressAsyncQuery,  // Progress of one task

    //DEBUG
    setDbgMonitor: (dbgMonitor) => _dbgMonitor = dbgMonitor
}
