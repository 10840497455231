// -------------------------------------------------------------------------------------------
// AppStm.js
// Contains all global (at Application level) streams that allow inter-comunication between
// different app components.
// -------------------------------------------------------------------------------------------
import stream from 'mithril/stream'


let appSizeStm = stream() //Main area stream //IMPORTANT: Do not initialize yet the strem (in order to monitor first correct size later)
let CWinStm = stream() //Stream for components CWin to notify window actions (minimize, maximize, close buttons)
let FAKE_appSizeStm = stream({width: 1600, height: 1000})

const getAppSizeStm = () => {
    if (appSizeStm()) return appSizeStm
    return FAKE_appSizeStm
}

export default {

    CWinStm, //CWin actions

    getAppSizeStm, //App size. or a FAKE (for testing or other modes)

    getAppArea: () => {
        let appSizeStm = getAppSizeStm()
        return window._global__TEST_MODE__ ? FAKE_appSizeStm() : appSizeStm() //Invokes the stream to get the object {w,h}
    }

}
