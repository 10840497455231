import m      from 'mithril'
import stream from 'mithril/stream'
import url    from 'url'
import I18N   from '../src/I18N/I18N'
import P      from '../procurer/procurer_login'
import SM     from '../src/managers/SM'
import CT     from "../src/CT";

let Symbol = require('es6-symbol/implement')

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// Login inner components
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/*
 *  Instantiates all components required by login app.
 */
const buildComponents = () => {
    return new Promise((resolve) => {
        if (!sm.components.iLogin) {
            sm.components.iLogin = P.getComponent({
                type: 'CLogin',
                showSupervisorOnLogin: stream(sm.store.settings.showSupervisorOnLogin),
                sm: sm.instance,
                sso: sm.store.settings.sso || {},
                hideLogin: stream(sm.store.settings.hideLoginForm || false),
                canChangePassword: stream(sm.store.settings.canChangePassword),
                parentSm: sm
            })

            sm.components.currentView = sm.components.iLogin
        }

        if (!sm.components.iRecovery) {
            sm.components.iRecovery = P.getComponent({
                type: 'CRecovery',
                showSupervisorOnLogin: stream(sm.store.settings.showSupervisorOnLogin),
                sm: sm.instance
            })
        }

        if (!sm.components.iChangePassword) {
            sm.components.iChangePassword = P.getComponent({
                type: 'CChangePassword',
                showSupervisorOnLogin: stream(sm.store.settings.showSupervisorOnLogin),
                settings: sm.store.settings,
                sm: sm.instance
            })
        }

        if (!sm.components.iLicense) {
            sm.components.iLicense = P.getComponent({
                type: 'CLicense',
                settings: sm.store.settings,
                sm: sm.instance,
                forceReboot: true
            })
        }

        if (!sm.components.iDialog) sm.components.iDialog = P.getComponent({
            type: 'CGenericDlg',
            message: sm.store.contentMessage,
            visible: sm.store.showMessage,
            onCloseDlgHook: () => {
            }
        })

        if (!sm.components.iError) sm.components.iError = P.getComponent({
            type: 'CError', onCloseDlgHook: () => {
            }
        })

        resolve()
    })
}

/*
 *   Link a view to the main app
 */
const setView = (step) => {
    switch (step) {
        case 'dialog':
            sm.components.currentView = sm.components.iDialog
            break
        case 'login':
            sm.components.currentView = sm.components.iLogin
            break
        case 'recovery':
            sm.components.currentView = sm.components.iRecovery
            break
        case 'password':
            sm.components.currentView = sm.components.iChangePassword
            break
        case 'license':
            sm.components.currentView = sm.components.iLicense
            break
        case 'error':
            sm.components.currentView = sm.components.iError
            break
        default:
            sm.components.currentView = sm.components.iLogin
    }

    m.redraw()
}

/*
 *  Mount main app with selected components
 */
const mountApp = () => {
    return new Promise((resolve) => {
        m.mount(document.getElementById('app'), {
            view: () => m('.cmain_login ', {}, m(sm.components.currentView))
        })
        resolve()
    })

}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// Inner routes / Url parser + re-directions
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/*
 *  Select component shown based on settings and url.
 */
const setInnerRoute = () => {
    return new Promise((resolve) => {
        if (!sm.store.settings.activated) {
            sm.instance.action('setViewLicense')
        } else {
            if (sm.store.route) {
                switch (sm.store.route) {
                    case 'recovery':
                        //if (!sm.components.currentView) onRecoverPassword()
                        sm.instance.action('onRecoverPassword')
                        break
                    case 'license':
                        //if (!sm.components.currentView) onUpdateLicense()
                        sm.instance.action('onUpdateLicense')
                        break
                    case 'changePassword':
                        //if (!sm.components.currentView) onChangePassword()
                        sm.instance.action('setViewPassword')
                        break
                    default:
                        //if (!sm.components.currentView) onLogin()
                        sm.instance.action('setViewLogin')
                }
            } else {
                sm.instance.action('setViewLogin')
            }
        }
        resolve()
    })
}


/*
 *  Hand made extraction of url query params.
 *  Default router do not a proper parse when is sent base64 query params. -which is not a bug-
 */
const getQueryParameters = () => {
    let queryParams = {}
    let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&')

    // Cannot split by "=" due the separator may be included as value...
    hashes.reduce((queryParams, hash) => {
        let pos = hash.indexOf('=')
        if (pos >= 0) queryParams[hash.substr(0, pos)] = hash.substr(pos + 1)
        return queryParams
    }, queryParams)

    return queryParams
}

/*
 * Stores route parameters for later use on main app redirection.
 *
 *       Supported route options:                                         [originalHash]
 *         protocol://server:port/#!/action/module/id/date/isUsr --->  #!/module/id/date/isUsr
 *         protocol://server:port/#!/changePassword?q=...        --->  #!/changePassword?q=
 *
 *  Stored params:
 *      - sm.store.originalHash        -> (Priority on redirections) Stores original full hash route, be concat to server url.
 *
 *      - sm.store.redirection         -> Forced redirection. (it can be filled by task parameters on /login response)
 *      - sm.store.redirectionParams   -> Forced redirection params
 *
 *      - sm.store.route               -> Inner login app route
 *

 */
const checkUrl = () => {
    return new Promise((resolve) => {
        let parser = url.parse(document.URL)

        // console.log('%cStarter URL ', 'color: darkgreen', document.URL)
        // console.log('%cStarter HASH', 'color: darkgreen', parser.hash)

        if (parser.hash) {
            let hashPrefix = '#!/'
            let startPosition = parser.hash.indexOf('#!/')       // Removing hash
            let route = parser.hash.slice(startPosition + hashPrefix.length)
            let queryStringPos = route.indexOf('?')
            let routePaths = ((queryStringPos >= 0) ? route.slice(0, queryStringPos) : route).split('/')

            if (routePaths.length > 0) {
                if (routePaths.length > 1) {
                    sm.store.redirection = true
                    // This should be enough for redirection... (sm.store.originalHash has PRIORITY on redirection)
                    sm.store.originalHash = window.location.hash

                    // ...  but it do not create problems if store info as
                    if (routePaths[0] === 'action' && routePaths.length >= 5) {
                        sm.store.route = routePaths[1]
                        try {
                            sm.store.redirectionParams = {
                                module: routePaths[1],
                                date: routePaths[3],
                                id: parseInt(routePaths[4], 10),
                                isUsr: parseInt(routePaths[5])
                            }
                        } catch (err) {
                            console.error('Unexpected error on route parser', err)
                            sm.store.redirection = false
                            sm.store.redirectionParams = {}
                        }
                    }
                } else {
                    // Inner login app routes likes changePassword, recovery, license, portal ...
                    sm.store.redirection = false
                    sm.store.route = routePaths[0]
                    sm.store.queryParams = getQueryParameters()

                    if (sm.store.route === 'changePassword') {
                        if (sm.store.queryParams.hasOwnProperty('q')) {
                            // Encoded parameters
                            try {
                                sm.store.forcePasswordChangeParams = JSON.parse(atob(sm.store.queryParams.q))
                            } catch (err) {
                                console.error('Error parsing query params', err)
                            }
                        } else {
                            // Plain parameters
                            sm.store.forcePasswordChangeParams = sm.store.queryParams
                        }
                    }

                    // Priority re-direct.
                    if (sm.store.route === 'portal') sm.store.originalHash = window.location.hash

                }

            }

        }

        // console.log('%cStarter STORE', 'color: darkgreen', JSON.stringify({ redirection: sm.store.redirection, originalHash: sm.store.originalHash, route: sm.store.route, queryParams: sm.store.queryParams, redirectionParams: sm.store.redirectionParams}, null, "\t"))

        resolve()
    })
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// State machine - ACTIONS
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const storeSettings = (settings) => {
    return new Promise((resolve) => {
        sm.store.settings = settings
        sm.store.settings.hideLogin = false
        resolve()
    })
}

const onInitialState = () => {
    I18N.init()
        .then((indexData) => {
            CT.storeIndex(indexData)
            return storeSettings(indexData)
        })
        .then(checkUrl)
        .then(buildComponents)
        .then(() => {
            sm.instance.action('initDone')
        })
        .catch((err) => {
            console.error('onInitialState', err)
        })
}

const onReadyState = () => {
    setInnerRoute()
        .then(mountApp)
        .catch((err) => {
            console.error('onReadyState', err)
        })
}

const onLogin = (params) => {
    if (params) {
        if (params.hasOwnProperty('forcePasswordChange')) sm.store.forcePasswordChange = params.forcePasswordChange
        if (params.hasOwnProperty('forcePasswordChangeParams')) sm.store.forcePasswordChangeParams = params.forcePasswordChangeParams
    }

    // For quick dev, available views:  *login*   'recovery' 'password' 'license' 'error'
    setView('login')
}

const onCommands = (params) => {
    sm.store.processedTask = 0
    sm.store.tasks = params.tasks

    sm.store.forcePasswordChange = params.forcePasswordChange
    sm.store.forcePasswordChangeParams = params.forcePasswordChangeParams

    sm.store.redirection = params.redirection
    sm.store.redirectionParams = params.redirectionParams

    // Order matters
    if (sm.store.tasks.length > 0) {
        sm.instance.action('processTasks')
    } else {
        sm.instance.action('redirect')
    }
}

const onRecoverPassword = () => {
    setView('recovery')
}

const onChangePassword = () => {
    // Configure component before mount it
    sm.components.iChangePassword.modeParams = sm.store.forcePasswordChangeParams
    setView('password')
}

const onUpdateLicense = () => {
    setView('license')
}

/*
 *  This function will process task array received on a successful response about "/login".
 *  It will show messages and updates redirection params when it enters main app to open the related entities.
 */
const onBeforeEnterApp = () => {
    // Process tasks
    if (sm.store.tasks.length > 0 && (sm.store.processedTask < sm.store.tasks.length)) {
        if (sm.store.tasks[sm.store.processedTask].type && sm.store.tasks[sm.store.processedTask].type === 'alert') {
            // Show notifications
            sm.store.contentMessage(sm.store.tasks[sm.store.processedTask].message)
            sm.store.processedTask++
            sm.components.iDialog.onCloseDlgHook = () => {
                // Keep state to shown n messages
                sm.instance.action('retry')
            }
            sm.store.showMessage(true)
            setView('dialog')
            return
        } else if (sm.store.tasks[sm.store.processedTask].action) {
            // Set redirection if required
            sm.store.redirection = true
            sm.store.redirectionParams = {
                module: sm.store.tasks[sm.store.processedTask].action,
                date: sm.store.tasks[sm.store.processedTask].date,
                id: sm.store.tasks[sm.store.processedTask].id,
                isUsr: sm.store.tasks[sm.store.processedTask].idEmp
            }
            console.log('%conBeforeEnterApp task redirection:', 'color: orangered', JSON.stringify(sm.store.redirectionParams))
            sm.store.processedTask++
            sm.instance.action('retry')
            return
        }
    }

    // When task were managed, now only could force password change or redirect to main app.
    if (sm.store.forcePasswordChange) {
        sm.instance.action('setViewChangePassword')
    } else {
        sm.instance.action('redirect')
    }
}

const onError = () => {
    setView('error')
}

const onEnterApp = () => {
    let targetUrl = '/'

    if (sm.store.originalHash && !sm.store.originalHash.includes('error')) {
        console.log('%conEnterApp - Redirection to original hash ', 'color: crimson', sm.store.originalHash)
        targetUrl += sm.store.originalHash
    } else if (sm.store.redirection) {
        let params = sm.store.redirectionParams

        if (params.portal) {
            // console.log('%conEnterApp - Portal redirection', 'color: crimson')
            targetUrl = '/portal'
        } else {
            // console.log('%conEnterApp - Forced redirection ', 'color: crimson', sm.store.redirectionParams)

            if (params.customUrl) {
                targetUrl = params.customUrl
            } else {
                targetUrl = `/#!/action/${params.module}/${params.id || 0}/${params.date || '20000101'}/${params.isUsr || 1}`
            }

        }
    }

    location.href = targetUrl
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// State machine - INSTANCE
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

let sm = {
    instance: undefined,
    components: {
        iDialog: undefined,
        iLayout: undefined,
        iLogin: undefined,
        iRecovery: undefined,
        iChangePassword: undefined,
        iLicense: undefined,
        currentView: undefined,  // This will point the above ones
    },
    store: {
        settings: {},
        route: '',
        queryParams: '',
        tasks: [],
        processedTask: 0,
        showMessage: stream(false),
        contentMessage: stream(''),
        forcePasswordChange: false,
        forcePasswordChangeParams: {},
        redirection: false,
        redirectionParams: {}
    },
    cfg: {
        name: 'Login',
        states: {
            initial: {
                initDone: 'ready'
            },
            ready: {
                setViewLicense: 'onUpdateLicense',
                setViewPassword: 'onChangePassword',
                setViewLogin: 'onLogin',

                //NEW
                onRecoverPassword: 'onRecoverPassword',
                onUpdateLicense: 'onUpdateLicense',
                onChangePassword: 'onChangePassword'
            },
            onLogin: {
                processCommands: 'onCommands',
                setViewRecovery: 'onRecoverPassword',
                redirect: 'onBeforeEnterApp',
                taskError: 'onLogin',
                error: 'onError'
            },
            onCommands: {
                setViewChangePassword: 'onChangePassword',
                processTasks: 'onBeforeEnterApp',
                redirect: 'onEnterApp'
            },
            onRecoverPassword: {
                backToLogin: 'onLogin',
                error: 'onError'
            },
            onChangePassword: {
                backToLogin: 'onLogin',  // If there is messages, or redirection, will does proper actions
                error: 'onError'
            },
            onUpdateLicense: {
                backToLogin: 'onLogin'
            },
            onBeforeEnterApp: {
                setViewChangePassword: 'onChangePassword',
                redirect: 'onEnterApp',
                retry: 'onBeforeEnterApp'
            },
            onError: {},
            onEnterApp: {}
        },

        onStart: () => 'initial',

        onNewState: {
            initial: onInitialState,
            ready: onReadyState,
            onLogin: onLogin,
            onCommands: onCommands,
            onRecoverPassword: onRecoverPassword,
            onChangePassword: onChangePassword,
            onUpdateLicense: onUpdateLicense,
            onBeforeEnterApp: onBeforeEnterApp,
            onError: onError,
            onEnterApp: onEnterApp
        }
    }
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// Boot LOGIN app
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const bootUp = () => {
    sm.instance = SM.getInstance(sm.cfg)
    sm.instance.start()
}

bootUp()

