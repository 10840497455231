// -------------------------------------------------------------------------------------------
// AppEvents.js
// Module to receive events from server via EventSource. Handles subscription to certain events
// published by the server
// Possible events:
// QueryEvent, ExceptionEvent, NormalLoginEvent, LogOutEvent, NewTerminalConnectionEvent, ContainersUpdateEvent, TerminalListeningEvent, TerminalPortInUseEvent, UnknownTerminalEvent, NotLicenseedTerminalEvent,
// NotActiveTerminalEvent, ClockingData, DayChangeEventData, AccessEvent, VisitaEvent, QueryEvent, MessageEvent, AlarmOnEvent, AlarmAckEvent, AlarmOffEvent, CommentEvent, PlanificationEvent,
// AnomaliaEvent, ClockingDemandEvent, ShiftDemandEvent, ClockingOnPlanif, ErrorEvent, DirtyEvent, MassiveDirtyEvent, DbFatalError, DbRecovery, FingerIEVOModifiedEvent, ProgressRequestMethodEvent, RequestMethodCancelledEvent, RequestMethodCompleteEvent, UpdateVersionFatalError,
// AlertOutOfGatewayTokens, TerminalUnknownEventData, TerminalConnectEventData, TerminalConsultaEventData, TerminalDesconectadoEventData, TerminalGenericClockEventData, TerminalMarcajeEventData,
// TerminalLostConnectionEventData, TerminalQueryEnrollEventData, TerminalEnrollEventData, TerminalStatusChangeEventData, TerminalEnrollCardEventData, TerminalAlarmEventData, TerminalExceptionEventData,
// TerminalConfigurationErrorEventData, TerminalInfoEventData, TerminalSeguimientoPuertaEventData, TerminalEndTelecargaEventData, TerminalErrorConnectingEventData, NotificationEvent, TicketControlEvent, DocumentEvent,
// -------------------------------------------------------------------------------------------

import m from "mithril";
import stream from 'mithril/stream'
import REQ from "../../REQ";
import CT from "../../CT";
import * as R from 'ramda'
import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill'

const EventSource = NativeEventSource || EventSourcePolyfill


let eventReceived = stream() //stream from witch every received event from server is notified
let _source
let currentContainerVersions //Copy of last 'ids' received from server informing each version of every container

const eventsMap = {
    '1': 'ExceptionEvent',
    '2': 'QueryEvent',
    '3': 'LoginEvent',
    '4': 'LoginEvent',
    '5': 'LoginEvent',
    '6': 'NewTerminalConnectionEvent',
    '7': 'ContainersUpdateEvent',
    '10': 'TerminalListeningEvent',
    '11': 'TerminalPortInUseEvent',
    '12': 'UnknownTerminalEvent',
    '13':'NotLicenseedTerminalEvent',
    '14':'NotActiveTerminalEvent',
    '40':'ClockingData',
    '51':'DayChangeEventData',
    '52':'AccessEvent',
    '53':'VisitaEvent',
    '54':'QueryEvent',
    '55':'MessageEvent',
    '56':'AlarmOnEvent',
    '57':'AlarmAckEvent',
    '58':'AlarmOffEvent',
    '59':'CommentEvent',
    '66':'PlanificationEvent',
    '67':'AnomaliaEvent',
    '68':'ClockingDemandEvent',
    '69':'ShiftDemandEvent',
    '70':'ClockingOnPlanif',
    '71': 'ErrorEvent',
    '73': 'DirtyEvent',
    '74':'MassiveDirtyEvent',
    '75':'DbFatalError',
    '76':'DbRecovery',
    '77':'FingerIEVOModifiedEvent',
    '78':'ProgressRequestMethodEvent',
    '79':'RequestMethodCancelledEvent',
    '80':'RequestMethodCompleteEvent',
    '81': 'UpdateVersionFatalError',
    '82':'AlertOutOfGatewayTokens',
    '100':'TerminalUnknownEventData',
    '101':'TerminalConnectEventData',
    '102':'TerminalConsultaEventData',
    '103': 'TerminalDesconectadoEventData',
    '104':'TerminalGenericClockEventData',
    '105': 'TerminalMarcajeEventData',
    '106': 'TerminalLostConnectionEventData',
    '107': 'TerminalQueryEnrollEventData',
    '108': 'TerminalEnrollEventData',
    '109':'TerminalStatusChangeEventData',
    '110':'TerminalEnrollCardEventData',
    '111': 'TerminalAlarmEventData',
    '112': 'TerminalExceptionEventData',
    '113': 'TerminalConfigurationErrorEventData',
    '114': 'TerminalInfoEventData',
    '115': 'TerminalSeguimientoPuertaEventData',
    '116':'TerminalEndTelecargaEventData',
    '117':'TerminalErrorConnectingEventData',
    '120':'NotificationEvent',
    '121':'TicketControlEvent',
    '122':'DocumentEvent',
    '123':'ServerStatusEvent'
}

//Initialization of EventSource system
const init = (withBytesQS = false) => {
    return new Promise((resolve, reject) => {
        console.log("AppEvents: init(): sending connect...")

        let timeoutSSESetting = CT.getSettingValue('sse.ConnectTimeout') || 5000

        if (isNaN(parseInt(timeoutSSESetting))) timeoutSSESetting = 5000

        console.log('SSE Timeout:', timeoutSSESetting)

        let connectionNotAvailableCheckout = setTimeout(() => {
            console.log('SSE Rejected!')
            reject('NoSSE')
        } , timeoutSSESetting)

        let url = withBytesQS ? '/sse/connect?bytes=800000' : '/sse/connect'

        _source = new EventSource(url)
        _source.onopen = (event) => {
            clearTimeout(connectionNotAvailableCheckout)

            console.log("AppEvents: Connection Opened")
            _source.onerror = function (event) {
                if (event.eventPhase === EventSource.CLOSED) {
                    //TODO: remove event listeners
                    console.log("AppEvents: Connection Closed")
                }
            }
            //Subscription to events globally inside the app lifecycle
            // subscribeToEvent('NormalLoginEvent')
            subscribeToEvent('LogOutEvent')
            subscribeToEvent('ContainersUpdateEvent') //Required for "REQ" to manage internal cache
            subscribeToEvent('DirtyEvent') //Required for "Movs" module, it is better to subscribe once here
            subscribeToEvent('MassiveDirtyEvent')
            subscribeToEvent('AccessEvent') //Required for fullContainer showing zones tree

            // Asynchronous request events
            subscribeToEvent('RequestMethodCompleteEvent')
            subscribeToEvent('ProgressRequestMethodEvent')
            subscribeToEvent('RequestMethodCancelledEvent')

            resolve()
        }
        _source.onerror = () => {
            console.log("** WARNING  ** : AppEvents: EventSource failed")
            clearTimeout(connectionNotAvailableCheckout)
            reject('NoSSE')
        }
    })
}


//List of containers for whose "containerUpdatedStm" will be updated if the container revision has changed
//If some consumer waits for a container updated notification via "containerUpdatedStm", the container must be listed here
//or added via "AppEvents.subscribeContainerUpdated(idContainer)"
let subscribedContainers = [
    CT.CONTAINER_ID.CICLO,
    CT.CONTAINER_ID.LECTOR,
    CT.CONTAINER_ID.TARJETA,
    CT.CONTAINER_ID.TERMINAL,
    CT.CONTAINER_ID.PERSONA,
    CT.CONTAINER_ID.PLANTILLAFILTRO
]
// A wgt (i.e. CWgtGrid) can invoke this method to ensure that "idContainer" is inside "subscribedContainers"
// to receive the corresponding containerUpdatedStm() update for that particular container
const subscribeContainerUpdated = (idContainer) => {
    let exists = R.findIndex((idCont) => idCont === idContainer)(subscribedContainers) >= 0
    if (!exists) subscribedContainers.push(idContainer)
}

// subscribedContainers should be a map to do this properly
const unsubscribeContainerUpdated = (idContainer) => {
    let exists = R.findIndex((idCont) => idCont === idContainer)(subscribedContainers) >= 0
    if (exists) subscribedContainers = subscribedContainers.filter(x => x !== idContainer)
}

const containerUpdatedStm = stream()
const containerRevisionStm = stream()


//Ids is an array where the
const notifyContainerUpdated = (ids) => {
    if (currentContainerVersions) {
        R.forEach((subscibedId) => {
            if (currentContainerVersions[subscibedId] !== undefined && ids[subscibedId] !== currentContainerVersions[subscibedId]) {
                console.log("AppEvents: updated container id: " + subscibedId + " new revision: " + ids[subscibedId])
                containerUpdatedStm(subscibedId)
                containerRevisionStm({id: subscibedId, rev: ids[subscibedId]})
            }
        })(subscribedContainers)
    } else {
        // JDS 12/05/2020- Edge case, start without mapped revisions
        R.forEach((subscibedId) => {
            if (ids[subscibedId] !== undefined) {
                console.log("AppEvents: updated container id: " + subscibedId + " new revision: " + ids[subscibedId])
                containerUpdatedStm(subscibedId)
                containerRevisionStm({id: subscibedId, rev: ids[subscibedId]})
            }
        })(subscribedContainers)
    }

    // Updated container versions from the values sent by the server
    currentContainerVersions = ids
}


// JDS:  19/02/2020 - ... if we wanna remove listeners (we do!)... we need to encapsulate the listener to remove it.
const listener = (event) => {
    //console.log("AppEvents: received ", event)
    let evtData = event.data
    try {
        if (typeof evtData === 'string') evtData = JSON.parse(evtData)
        let notifEvt = {type: event.type, data: evtData.data, message: evtData.message} //OJO! the "data" coming from the event "data" is usually in "evtData.data"
        eventReceived(notifEvt)

        //Special procedure to handle type of event 'ContainersUpdateEvent'
        if (notifEvt.type === 'ContainersUpdateEvent') {
            // 1) direct notification to "REQ" (because REQ.cannot import this module because of  cyclic dependency)
            if (REQ.directNotifyAppEventContainersUpdated) REQ.directNotifyAppEventContainersUpdated(notifEvt)
            // 2) determination & possible updating of every container whose version changed
            if (evtData.data && evtData.data.ids) notifyContainerUpdated(evtData.data.ids)
        }
    } catch (e) {
        CT.log3("AppEvents: ERROR parsing evtData: ", evtData)
        console.error("AppEvents: ERROR parsing evtData ERROR: ", e)
    }
}

//Subscribes to 'eventName'
// const subscribeToEvent_OLD = (eventName) => {
//     if (eventName.indexOf(',') > 0) {
//         eventName.split(',').forEach(subscribeToEvent)
//     } else {
//         if (!_source) {
//             console.log("AppEvents ERROR: AppEvents is not yet initialized!")
//             return
//         }
//         //if (!isNaN(parseInt(eventName))) eventName = eventsMap[eventName]
//         if (!eventName) return
//         console.log("AppEvents: >>> Subscribing to event: " + eventName)
//         REQ.request({method: 'GET', url: '/sse/subscribe?channels=' + eventName}).then((result) => {
//             if (_source.addEventListener) {                           // W3C method
//                 _source.addEventListener(eventName, listener, false)  // Modern browsers
//             }
//         })
//     }
// }

// JDS 13/10/2020 - Changed subscription to avoid iteration on array of events to subscribe.
//                  Subscription of all batch
const subscribeToEvent = (eventName) => {

    if (Array.isArray(eventName)) eventName = eventName.toString()  // By default, comma separated list

    if (_source && eventName) {
        console.log('AppEvents: >>> Subscribing to events: ' + eventName)

        REQ.request({method: 'GET', url: '/sse/subscribe?channels=' + eventName})
            .then(() => {
                if (_source.addEventListener) {
                    let subscribedEvents = (eventName.indexOf(',') >= 0) ? eventName.split(',') : [eventName]
                    subscribedEvents.forEach((eventId) => {
                        _source.addEventListener(eventId, listener, false)
                    })
                } else {
                    console.error('AppEvents: >>> Can not add event listeners.')
                }
            })
            .catch((err) => {
                console.error('AppEvents: >>> Error: ', err)
            })
    } else {
        console.log('AppEvents ERROR: AppEvents is not yet initialized or null eventName!', {eventName})
    }
}

//UnSubscription to 'event:Name'
// const unsubscribeToEvent_OLD = (eventName) => {
//
//     if (eventName === 'ContainersUpdateEvent') {
//         CT.warning("Warning: unsubscribeToEvent 'ContainersUpdateEvent0' forbidden as it is required globally")
//         return
//     }
//
//     if (eventName.indexOf(',') > 0) {
//         eventName.split(',').forEach(unsubscribeToEvent)
//     } else {
//         if (!_source) {
//             console.log("AppEvents ERROR: AppEvents is not yet initialized!")
//             return
//         }
//         //if (!isNaN(parseInt(eventName))) eventName = eventsMap[eventName]
//         if (!eventName) return
//         console.log("AppEvents: >>> Un-subscribing to event: " + eventName)
//         REQ.request({method: 'GET', url: '/sse/unsubscribe?channels=' + eventName}).then((result) => {
//
//             // JDS: 19/02/2020 ... Remove it for real! Because if we establish again the same listener...
//             //                     it will be enabled with the previous ones! Cuz they're still alive.
//             _source.removeEventListener(eventName, listener, false)
//
//             console.log("AppEvents: unsubscribe result: " + JSON.stringify(result))
//         })
//     }
// }

// JDS 13/10/2020 - Changed subscription to avoid iteration on array of events to subscribe.
//                  Subscription of all batch
const unsubscribeToEvent = (eventName) => {

    if (Array.isArray(eventName)) eventName = eventName.toString()  // By default, comma separated list

    let _eventName = ''

    // Before unsubscribe, check about ContainersUpdateEvent, and remove it if exists. It's a global requirement.
    let position = eventName.indexOf('ContainersUpdateEvent')
    if (position >= 0) {
        _eventName = eventName.replace(/ContainersUpdateEvent/g,'')
        _eventName = _eventName.replace(/,,/g,',')

        // Remove starter and final unnecessary separators
        if (_eventName) {
            if (_eventName[0] === ',') _eventName = _eventName.substr(1)
            if (_eventName[_eventName.length - 1] === ',') _eventName = _eventName.substr(0, _eventName.length - 1)
        }

        CT.warning("Warning: unsubscribeToEvent 'ContainersUpdateEvent' forbidden as it is required globally", eventName, ' <= replaced by => ', _eventName)
        return
    } else {
        _eventName = eventName
    }

    if (_source && _eventName) {
        console.log('AppEvents: >>> Un-subscribing to event: ' + _eventName)

        REQ.request({method: 'GET', url: '/sse/unsubscribe?channels=' + _eventName})
            .then(() => {
                if (_source.removeEventListener) {
                    let subscribedEvents = (_eventName.indexOf(',') >= 0) ? _eventName.split(',') : [_eventName]
                    subscribedEvents.forEach((eventId) => {
                        _source.removeEventListener(eventId, listener, false)
                    })
                } else {
                    console.error('AppEvents: >>> Can not add event listeners.')
                }
            })
            .catch((err) => {
                console.error('AppEvents: >>> Error: ', err)
            })
    } else {
        console.log('AppEvents ERROR: AppEvents is not yet initialized or null eventName!', {_eventName})
    }
}


export default {
    init,
    eventReceived, //stream to notify received events
    subscribeToEvent,
    unsubscribeToEvent,
    subscribeContainerUpdated,
    unsubscribeContainerUpdated,
    containerUpdatedStm,
    containerRevisionStm
}
