// -------------------------------------------------------------------------------------------
// Component: "CCommonList"  (a copy of "CList.js" but required to be in "procurer_common", isolated from "procurer")
// Author: lpineda
// Date: 10/6/2020
// Desc: Default component that renders a list of elements of type "childrenType"
//      The reason to have this duplication of the same component "CList" is that the property
//      "itemsType" defines the type of component for children to be injected.
//      These possible components need to be under the same procurer instance.
//      For example, inside procurer_common instance,  "CLicense" requires a "CCommonList"
//      that requires "CListDevicePicker". All these 3 components need to be under "procurer_common"
//      On the other hand, inside "procurer" instance (main app)
//          - there is a "CSubcalPanel" that requires a 'CList' that requires 'CSubcalItem'
//          - there is a "CLayerListPanel" that requires a "CList" that requires "CLayerListItem"
//      CONCLUSION:
//          - "CList" must be in the same pool that "CSubcalPanel" 'CSubcalItem' "CLayerListPanel" "CLayerListItem"
//          - "CCommonList" is a copy of "CList" but in another pool with "CListDevicePicker"
// -------------------------------------------------------------------------------------------

import m from 'mithril'
import P from '../../procurer/procurer_common'
import stream from "mithril/stream";
import * as R from "ramda";

export default (cfg) => {

    const defaults = {
        itemsClassName: "g-win-item",
        itemsType: 'CListDevicePicker', //Default type for all children
        itemsDefaultProps: () => {}, //The returned object by 'itemsDefaultProps()' has a bunch of properties that every child will replicate
        items: [],//[{id: 0, name: 'Primer item'}, {id: 1, name: 'segon item'}, {id: 2, name: 'tercer item'}, {id: 3, name: 't4'}, {id: 4, name: 'item 5'}],
        selectedItemStm: stream()
    }
    let c = P.mergeProperties(defaults, cfg)

    const getItemDef = (item) => {
        let defaultItem = R.mergeRight(item, c.itemsDefaultProps()) //Transmission of default properties for every item
        return R.mergeRight(defaultItem, {
                type: c.itemsType || item.type,
                className: item.className || c.itemsClassName,
                selectedItemStm: c.selectedItemStm,
                disabled: stream()
            }
        )
    }

    c.createItems = (newItems) => {
        if (newItems) c.items = newItems
        c.listItems = R.map(R.compose(P.getComponent, getItemDef))(c.items)
    }

    c.drawItems = () => R.map(m)(c.listItems)

    c.drawFilteredItems = (filterFunc) => R.map(m)(R.filter(filterFunc)(c.listItems))

    // Updates every existent data in "c.items" if, with "newItem" data (compared by id) and
    // recreates the radio buttons list
    c.updateItems = (newItems) => {
        R.forEach((candidate) => {
            let curItem = R.find((itm) => itm.id === candidate.id)(c.items)
            if (curItem) Object.assign(curItem, candidate)
        })(newItems)
        c.createItems()
    }

    //Enabling /disabling ALREADY CREATED items (c.listItems)
    c.enableItems = (operToEnable) => {
        //Traverse all created items and set 'disabled()' stream to the result of finding the item inside 'operToEnable' list of items
        R.forEach((currentOp) => {
            let mustBeDisabled = R.find((op) => currentOp.id === op.id)(operToEnable) === undefined
            currentOp.disabled(mustBeDisabled)
        })(c.listItems)
    }

    //Initialization
    c.createItems()

    c.view = () => m("div.generic-list", c.drawItems())

    return c
}

